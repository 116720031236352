/**
 * ExtData is used to handle Extension Types that are not registered to ExtensionCodec.
 */
var ExtData = /** @class */function () {
  function ExtData(type, data) {
    this.type = type;
    this.data = data;
  }
  return ExtData;
}();
export { ExtData };
