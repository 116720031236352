var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var DecodeError = /** @class */function (_super) {
  __extends(DecodeError, _super);
  function DecodeError(message) {
    var _this = _super.call(this, message) || this;
    // fix the prototype chain in a cross-platform way
    var proto = Object.create(DecodeError.prototype);
    Object.setPrototypeOf(_this, proto);
    Object.defineProperty(_this, "name", {
      configurable: true,
      enumerable: false,
      value: DecodeError.name
    });
    return _this;
  }
  return DecodeError;
}(Error);
export { DecodeError };
