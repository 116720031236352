// angular
import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Renderer2, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

// rxjs
import { catchError, concatMap, defer, delay, filter, fromEvent, map, merge, mergeMap, of, skip, Subject, switchMap, take, takeUntil, tap, timer } from 'rxjs';

// services
import { BaseService } from '@core/base/base.service';

// misc
import { ENV } from '@env/environment';
import {  UtilityService } from '@core/utility/utility.service';

// wml-components
import { HttpClient } from '@angular/common/http';
import { WMLNotifyOneBarType, WMLNotifyOneService } from '@windmillcode/angular-wml-notify';
import { NavService } from '@shared/services/nav/nav.service';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { generateClassPrefix } from '@windmillcode/wml-components-base';
import { SocketioService } from '@shared/services/socketio/socketio.service';
import { JobsService } from '@shared/services/jobs/jobs.service';
import { changeAllButtonTypeAttributesToButton, documentQuerySelector, documentQuerySelectorAll, loadScript } from '@core/utility/common-utils';
import { SharedModule } from '@shared/shared.module';
import localforage from 'localforage';
import { ListUsersVideoDataAPIResponseBody, listUsersVideoDataSuccess } from '@shared/services/platforms/listUsersVideoData';
import { WebRTCService } from '@shared/services/webrtc/webrtc.service';
import { SITE_OFFLINE_ENUM } from '@core/utility/constants';
import { SpecificService } from './core/specific/specific.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone:true,
  imports: [
    RouterOutlet,
    SharedModule
  ],
  providers:[]
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit {
  constructor(
    public baseService: BaseService,
    public utilService: UtilityService,
    public cdref: ChangeDetectorRef,
    public vcf: ViewContainerRef,
    public router:Router,
    public http:HttpClient,
    public WMLNotifyOneService:WMLNotifyOneService,
    public navService:NavService,
    public accountsService:AccountsService,
    public socketioService:SocketioService,
    public jobsService:JobsService,
    public renderer2:Renderer2,
    public webRTCService:WebRTCService,
    public specificService:SpecificService
  ) {
    this.listenForChangesOutSideChangeDetection().subscribe()
  }

  classPrefix = generateClassPrefix(ENV.classPrefix.app)
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  ngUnsub = new Subject<void>()


  listenForChangesOutSideChangeDetection = ()=>{
    return merge(
      this.baseService.popupProps.togglePopupSubj,
      this.baseService.toggleOverlayLoadingSubj,
    )
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.cdref.detectChanges()
      })
    )

  }

  removeAngularIdentifiers() {
    if (!["DEV","TEST"].includes(ENV.type)) {
      this.vcf.element.nativeElement.removeAttribute('ng-version');
    }
  }

  handleSiteNavigation = ()=>{
    if (ENV.app.siteOffline === SITE_OFFLINE_ENUM.TRUE) {

      this.router.navigateByUrl(ENV.nav.urls.siteOffline);
      return false
    } else if (this.utilService.getWindow().location.pathname === ENV.nav.urls.siteOffline) {
      this.router.navigateByUrl(ENV.nav.urls.home);
      return true
    }
    return true
  }

  checkIfBackendIsWorking() {
    return this.http
      .get(ENV.app.backendHealthCheck())
      .pipe(
        takeUntil(this.ngUnsub),
        catchError((err) =>{return of(null)}
          // throwError(() => {
          //   // this.router.navigateByUrl(ENV.nav.urls.siteOffline);
          //   // return new CantReachBackendError(err);
          // })
        )
      );
  }

  triggerMapSocketIOSessionIdToUserEvent = ()=>{
    return this.accountsService.listenForUser({})
    .pipe(
      take(1),
      switchMap(() => this.listenForSocketioConnectionEvent()),
      tap(()=>{
        this.accountsService.onMapSocketIOSessionIdToUserEvent.next()
      }),
      take(1),
      switchMap(() => this.listenForSocketioConnectionEvent(1)),
    )

  }

  listenForSocketioConnectionEvent = (skipAmount=0) => {
    return this.socketioService.connectEvent
    .pipe(
      skip(skipAmount),
      filter(()=>Boolean(this.accountsService.currentUser)),
      concatMap((socketIoSessionId) => {
        return this.accountsService.mapSocketIOSessionIdToUser({
          socketIoSessionId,
          accessToken: this.accountsService.currentUser.accessToken
        });
      }),
      takeUntil(this.ngUnsub)
    );
  };

  listenForUser = ()=>{
    return this.accountsService
    .onMapSocketIOSessionIdToUserEvent
    .pipe(
      concatMap(()=>{
        return this.accountsService.listFriends()

      })
    )
  }

  protectSiteViaPassword = ()=>defer(async()=>{
    if(this.utilService.getWindow().navigator.userAgent ==="Puppeteer" ){
      return
    }
    if(["PREVIEW"].includes(ENV.type)){
      let window = this.utilService.getWindow()

      let password:string = await localforage.getItem(ENV.localForage.SitePassword)
      let expectedPass = "&cMb]svT?I[Lyz}"
      if([null,undefined,""].includes(password) || password !== expectedPass){
        password = prompt("Please enter the password to view this page");
      }
      if(password !== expectedPass){
        window.history.back()
      }
      else {
        await localforage.setItem(ENV.localForage.SitePassword,password)
      }
    }
  })

  doMiscConfigs() {
    this.removeAngularIdentifiers();
    let myContinue = this.handleSiteNavigation()
    if(myContinue){
      this.baseService.appCdRef = this.cdref;
      ENV.nav.urls.initialURL = window.location.pathname;
      this.accountsService.manageUsersLoginInfo();
      if(!['DEV','TEST','DOCKER_DEV'].includes(ENV.type)){
        this.checkIfBackendIsWorking().subscribe();
      }
    }
    return this.protectSiteViaPassword()
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap(()=>{
        return this.setupOneSignal()
      }),
      tap(()=>{
        this.listenForUser().subscribe()
        this.triggerMapSocketIOSessionIdToUserEvent().subscribe()
        this.listenForAddVideosJob().subscribe()
        this.listenForOrderPaidEvent().subscribe()
        this.listenForSubscriptionBilledEvent().subscribe()

        this.listenToSetupTawkToChat().subscribe()
        this.automate()
      }),
      mergeMap(()=>{
        return merge(
          this.webRTCService.listenForIncomingFileTransferRequest(),
          this.webRTCService.listenForFileTransferError(),
          this.webRTCService.listenForFileTransferResponse(),
        )
      }),

    )


  }

  listenForAddVideosJob = ()=>{
    return this.socketioService.addVideosEvent
    .pipe(
      takeUntil(this.ngUnsub),
      map(this.socketioService.validateSocketIOResponse),
      catchError((err)=>{
        // @ts-ignore
        return of(new ListUsersVideoDataAPIResponseBody({data:"error"}))
      }),
      filter((res)=> res.data !== "error"),
      map(listUsersVideoDataSuccess),
      concatMap((res)=>{
        return defer(async()=>{
          await localforage.setItem(ENV.localForage.UserVideoData,res)
          this.utilService.getWindow().location.reload()
        })
      }),
    );
  }

  listenForOrderPaidEvent = ()=>{
    return this.socketioService.orderPaidEvent
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        if(res.data.result.msg ==="SUCCESS"){
          this.utilService.getWindow().location.reload()
        }
        else if(res.data.result.msg ==="FAILED"){
          this.baseService.createWMLNote("BillingZeroPage.WMLNotifyOne.errorProcessingOrder",WMLNotifyOneBarType.Error)
        }
      })
    )
  }

  listenForSubscriptionBilledEvent = ()=>{
    return this.socketioService.subscriptionBilledEvent
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        if(res.data.result.msg ==="SUCCESS"){
          this.baseService.createWMLNote("BillingZeroPage.WMLNotifyOne.subscriptionBillSuccess",WMLNotifyOneBarType.Info)
        }
        else if(res.data.result.msg ==="FAILED"){
          this.baseService.createWMLNote("BillingZeroPage.WMLNotifyOne.subscriptionBillFailed",WMLNotifyOneBarType.Error)
        }
      })
    )
  }

  setupOneSignal = () => {

    this.specificService.getOneSignalDeferredGlobalVar().push(async (OneSignal)=> {
      // OneSignal.Debug.setLogLevel(6)
      await OneSignal.init({
        appId: ENV.oneSignal.appId,
        safari_web_id: ENV.oneSignal.safariWebId,
        notifyButton: {
          enable: false,
        },
      });

    });
    if(this.utilService.getWindow().navigator.userAgent !=="Puppeteer"  ){
      return loadScript({src:"https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"})
    }
    else{
      return of("")
    }
  }

  listenToSetupTawkToChat = () => {
    return this.accountsService.onUserEvent.pipe(
      takeUntil(this.ngUnsub),
      tap((res) => {
        const scriptId = 'tawkToScript';
        let document = this.utilService.getWindow().document
        if (res === "userLoggedIn") {
          // Check if the script is already there to avoid multiple injections
          if (!document.getElementById(scriptId)) {
            const script = this.renderer2.createElement('script');
            this.renderer2.setAttribute(script, 'id', scriptId);
            this.renderer2.setAttribute(script, 'src', ENV.tawkTo.scriptUrl);
            this.renderer2.setAttribute(script, 'async', 'true');
            this.renderer2.setAttribute(script, 'charset', 'UTF-8');
            this.renderer2.setAttribute(script, 'crossorigin', '*');
            this.renderer2.appendChild(document.body, script);
          }
        } else {
          // Remove the script when the user logs out or any other event occurs
          const existingScript = document.getElementById(scriptId);
          if (existingScript) {
            this.renderer2.removeChild(document.body, existingScript);

          }
        }
      }),
      delay(500),
      tap((res)=>{
        try {
          if(res === "userLoggedIn"){
            this.renderer2.setStyle(document.querySelector(".widget-visible"),"display","block")
          }
          else{
            documentQuerySelector(".widget-visible").style.setProperty('display', 'none', 'important')
          }
        } catch (error) {
        }
      })
    );
  }

  automate =()=>{
    return
    // this.navService.mobileNavOne.open()
    if(["DOCKER_DEV","DEV"].includes(ENV.type)){
    fromEvent(window,"load")
    .pipe(
      takeUntil(this.ngUnsub),
      delay(2000),
      tap(()=>{
        let title = documentQuerySelector("#VideoDataEditorPage > div > div > section.VideoDataEditorPagePod1 > div > div.VideoDataEditorPagePod1Item1 > div:nth-child(1) > h2")
        title.click()

      }),

    )
    .subscribe()
    }
  }

  ngOnInit() {
    changeAllButtonTypeAttributesToButton()
    this.doMiscConfigs().subscribe()
  }

  ngAfterViewInit (){

    if(this.utilService.getWindow().navigator.userAgent !=="Puppeteer"  ){


      timer(1000)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(()=>{
          let appRoots = documentQuerySelectorAll("app-root");
          let appRootToRemove = documentQuerySelector("app-root:not(.AppSSGFrame0)")
          let appRootToUpdateClass = documentQuerySelector("app-root:nth-of-type(1)")
          if (appRootToRemove && appRoots.length > 1 && ["PREVIEW","PROD"].includes(ENV.type)) {
            this.cdref.detach()
            appRootToRemove.remove();
            this.cdref.reattach()
          }
          appRootToUpdateClass.classList.add("AppSSGInit")
          this.cdref.detectChanges()
        })
      )
      .subscribe()

    }
  }


  ngOnDestroy() {
    this.ngUnsub.next()
    this.ngUnsub.complete()
  }

}
