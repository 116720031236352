import { Inject, inject, Injectable } from '@angular/core';
import { BaseService } from '@core/base/base.service';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { SentryErrorHandler,ErrorHandlerOptions, captureException } from '@sentry/angular-ivy';
import { ENV } from '@env/environment';
import { UtilityService } from '@core/utility/utility.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EMAIL_REQUIRED, NEED_MORE_CREDITS } from '@core/utility/constants';


export class SentryErrorHandlerOptions implements ErrorHandlerOptions{
  showDialog =false
}
@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {
  constructor(
    @Inject(SentryErrorHandlerOptions) public configs:ErrorHandlerOptions,
  ) {
    super(configs)
    this.baseService =  inject(BaseService);
    this.utilService = inject(UtilityService)
  }
  public baseService
  public utilService
  override handleError(error:Error) {
    console.error(error)
    if([
      "SquareTokenizeError",
      "AuthInvalidPasswordResetCode",
      "UserCanceledAuthError",
      "CantReachBackendError"
    ].find((err)=>err === error.name) ){
      return
    }

    if(error instanceof HttpErrorResponse){
      if([NEED_MORE_CREDITS].includes(error.error?.description) ){
        return
      }
      if([EMAIL_REQUIRED].includes(error.error?.description) ){
        this.baseService.createWMLNote('AccountsService.WMLNotifyOne.emailRequiredToCreateAnAccount',WMLNotifyOneBarType.Error);
        return;
      }
    }

    if([
      "ServiceUnavailableError"
    ].find((err)=>err === error.name) ){
      this.baseService.createWMLNote('global.serviceUnavailableError',WMLNotifyOneBarType.Error);
      return
    }

    if([
      "AuthEmailAlreadyInUse"
    ].find((err)=>err === error.name) ){
      this.baseService.createWMLNote('SignUpPage.WMLNotifyOne.emailAlreadyInUse',WMLNotifyOneBarType.Error);
      return
    }

    if([
      "AuthWrongPassword","AuthUserNotFound"
    ].find((err)=>err === error.name) ){
      this.baseService.createWMLNote('SignInPage.WMLNotifyOne.incorrectCredentialsOrUserNotFound',WMLNotifyOneBarType.Error);
      return
    }

    if([
      "AuthProviderExists"
    ].find((err)=>err === error.name) ){
      this.baseService.createWMLNote('global.WMLNotifyOne.providerExists',WMLNotifyOneBarType.Error);
      return
    }

    if([
      "AuthAccountDisabled"
    ].find((err)=>err === error.name) ){
      captureException(error)
      this.baseService.createWMLNote("global.WMLNotifyOne.disabledAccount",WMLNotifyOneBarType.Error)
      return
    }


    else if(error.message.includes("ChunkLoadError: Loading chunk")){

      this.utilService.getWindow().location.reload()
      return
    }
    else{
      this.baseService.openSystemError()

      if(!["DEV","TEST"].includes(ENV.type)){
        console.error(error)
        // super.handleError(error);
      }
      else{
        console.error(error)
      }
    }
  }


}
