import { utf8DecodeJs } from "./utils/utf8.mjs";
var DEFAULT_MAX_KEY_LENGTH = 16;
var DEFAULT_MAX_LENGTH_PER_KEY = 16;
var CachedKeyDecoder = /** @class */function () {
  function CachedKeyDecoder(maxKeyLength, maxLengthPerKey) {
    if (maxKeyLength === void 0) {
      maxKeyLength = DEFAULT_MAX_KEY_LENGTH;
    }
    if (maxLengthPerKey === void 0) {
      maxLengthPerKey = DEFAULT_MAX_LENGTH_PER_KEY;
    }
    this.maxKeyLength = maxKeyLength;
    this.maxLengthPerKey = maxLengthPerKey;
    this.hit = 0;
    this.miss = 0;
    // avoid `new Array(N)`, which makes a sparse array,
    // because a sparse array is typically slower than a non-sparse array.
    this.caches = [];
    for (var i = 0; i < this.maxKeyLength; i++) {
      this.caches.push([]);
    }
  }
  CachedKeyDecoder.prototype.canBeCached = function (byteLength) {
    return byteLength > 0 && byteLength <= this.maxKeyLength;
  };
  CachedKeyDecoder.prototype.find = function (bytes, inputOffset, byteLength) {
    var records = this.caches[byteLength - 1];
    FIND_CHUNK: for (var _i = 0, records_1 = records; _i < records_1.length; _i++) {
      var record = records_1[_i];
      var recordBytes = record.bytes;
      for (var j = 0; j < byteLength; j++) {
        if (recordBytes[j] !== bytes[inputOffset + j]) {
          continue FIND_CHUNK;
        }
      }
      return record.str;
    }
    return null;
  };
  CachedKeyDecoder.prototype.store = function (bytes, value) {
    var records = this.caches[bytes.length - 1];
    var record = {
      bytes: bytes,
      str: value
    };
    if (records.length >= this.maxLengthPerKey) {
      // `records` are full!
      // Set `record` to an arbitrary position.
      records[Math.random() * records.length | 0] = record;
    } else {
      records.push(record);
    }
  };
  CachedKeyDecoder.prototype.decode = function (bytes, inputOffset, byteLength) {
    var cachedValue = this.find(bytes, inputOffset, byteLength);
    if (cachedValue != null) {
      this.hit++;
      return cachedValue;
    }
    this.miss++;
    var str = utf8DecodeJs(bytes, inputOffset, byteLength);
    // Ensure to copy a slice of bytes because the byte may be NodeJS Buffer and Buffer#slice() returns a reference to its internal ArrayBuffer.
    var slicedCopyOfBytes = Uint8Array.prototype.slice.call(bytes, inputOffset, inputOffset + byteLength);
    this.store(slicedCopyOfBytes, str);
    return str;
  };
  return CachedKeyDecoder;
}();
export { CachedKeyDecoder };
