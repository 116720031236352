var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __asyncValues = this && this.__asyncValues || function (o) {
  if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
  var m = o[Symbol.asyncIterator],
    i;
  return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () {
    return this;
  }, i);
  function verb(n) {
    i[n] = o[n] && function (v) {
      return new Promise(function (resolve, reject) {
        v = o[n](v), settle(resolve, reject, v.done, v.value);
      });
    };
  }
  function settle(resolve, reject, d, v) {
    Promise.resolve(v).then(function (v) {
      resolve({
        value: v,
        done: d
      });
    }, reject);
  }
};
var __await = this && this.__await || function (v) {
  return this instanceof __await ? (this.v = v, this) : new __await(v);
};
var __asyncGenerator = this && this.__asyncGenerator || function (thisArg, _arguments, generator) {
  if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
  var g = generator.apply(thisArg, _arguments || []),
    i,
    q = [];
  return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () {
    return this;
  }, i;
  function verb(n) {
    if (g[n]) i[n] = function (v) {
      return new Promise(function (a, b) {
        q.push([n, v, a, b]) > 1 || resume(n, v);
      });
    };
  }
  function resume(n, v) {
    try {
      step(g[n](v));
    } catch (e) {
      settle(q[0][3], e);
    }
  }
  function step(r) {
    r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r);
  }
  function fulfill(value) {
    resume("next", value);
  }
  function reject(value) {
    resume("throw", value);
  }
  function settle(f, v) {
    if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]);
  }
};
import { prettyByte } from "./utils/prettyByte.mjs";
import { ExtensionCodec } from "./ExtensionCodec.mjs";
import { getInt64, getUint64, UINT32_MAX } from "./utils/int.mjs";
import { utf8DecodeJs, TEXT_DECODER_THRESHOLD, utf8DecodeTD } from "./utils/utf8.mjs";
import { createDataView, ensureUint8Array } from "./utils/typedArrays.mjs";
import { CachedKeyDecoder } from "./CachedKeyDecoder.mjs";
import { DecodeError } from "./DecodeError.mjs";
var isValidMapKeyType = function (key) {
  var keyType = typeof key;
  return keyType === "string" || keyType === "number";
};
var HEAD_BYTE_REQUIRED = -1;
var EMPTY_VIEW = new DataView(new ArrayBuffer(0));
var EMPTY_BYTES = new Uint8Array(EMPTY_VIEW.buffer);
// IE11: Hack to support IE11.
// IE11: Drop this hack and just use RangeError when IE11 is obsolete.
export var DataViewIndexOutOfBoundsError = function () {
  try {
    // IE11: The spec says it should throw RangeError,
    // IE11: but in IE11 it throws TypeError.
    EMPTY_VIEW.getInt8(0);
  } catch (e) {
    return e.constructor;
  }
  throw new Error("never reached");
}();
var MORE_DATA = new DataViewIndexOutOfBoundsError("Insufficient data");
var sharedCachedKeyDecoder = new CachedKeyDecoder();
var Decoder = /** @class */function () {
  function Decoder(extensionCodec, context, maxStrLength, maxBinLength, maxArrayLength, maxMapLength, maxExtLength, keyDecoder) {
    if (extensionCodec === void 0) {
      extensionCodec = ExtensionCodec.defaultCodec;
    }
    if (context === void 0) {
      context = undefined;
    }
    if (maxStrLength === void 0) {
      maxStrLength = UINT32_MAX;
    }
    if (maxBinLength === void 0) {
      maxBinLength = UINT32_MAX;
    }
    if (maxArrayLength === void 0) {
      maxArrayLength = UINT32_MAX;
    }
    if (maxMapLength === void 0) {
      maxMapLength = UINT32_MAX;
    }
    if (maxExtLength === void 0) {
      maxExtLength = UINT32_MAX;
    }
    if (keyDecoder === void 0) {
      keyDecoder = sharedCachedKeyDecoder;
    }
    this.extensionCodec = extensionCodec;
    this.context = context;
    this.maxStrLength = maxStrLength;
    this.maxBinLength = maxBinLength;
    this.maxArrayLength = maxArrayLength;
    this.maxMapLength = maxMapLength;
    this.maxExtLength = maxExtLength;
    this.keyDecoder = keyDecoder;
    this.totalPos = 0;
    this.pos = 0;
    this.view = EMPTY_VIEW;
    this.bytes = EMPTY_BYTES;
    this.headByte = HEAD_BYTE_REQUIRED;
    this.stack = [];
  }
  Decoder.prototype.reinitializeState = function () {
    this.totalPos = 0;
    this.headByte = HEAD_BYTE_REQUIRED;
    this.stack.length = 0;
    // view, bytes, and pos will be re-initialized in setBuffer()
  };
  Decoder.prototype.setBuffer = function (buffer) {
    this.bytes = ensureUint8Array(buffer);
    this.view = createDataView(this.bytes);
    this.pos = 0;
  };
  Decoder.prototype.appendBuffer = function (buffer) {
    if (this.headByte === HEAD_BYTE_REQUIRED && !this.hasRemaining(1)) {
      this.setBuffer(buffer);
    } else {
      var remainingData = this.bytes.subarray(this.pos);
      var newData = ensureUint8Array(buffer);
      // concat remainingData + newData
      var newBuffer = new Uint8Array(remainingData.length + newData.length);
      newBuffer.set(remainingData);
      newBuffer.set(newData, remainingData.length);
      this.setBuffer(newBuffer);
    }
  };
  Decoder.prototype.hasRemaining = function (size) {
    return this.view.byteLength - this.pos >= size;
  };
  Decoder.prototype.createExtraByteError = function (posToShow) {
    var _a = this,
      view = _a.view,
      pos = _a.pos;
    return new RangeError("Extra ".concat(view.byteLength - pos, " of ").concat(view.byteLength, " byte(s) found at buffer[").concat(posToShow, "]"));
  };
  /**
   * @throws {@link DecodeError}
   * @throws {@link RangeError}
   */
  Decoder.prototype.decode = function (buffer) {
    this.reinitializeState();
    this.setBuffer(buffer);
    var object = this.doDecodeSync();
    if (this.hasRemaining(1)) {
      throw this.createExtraByteError(this.pos);
    }
    return object;
  };
  Decoder.prototype.decodeMulti = function (buffer) {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          this.reinitializeState();
          this.setBuffer(buffer);
          _a.label = 1;
        case 1:
          if (!this.hasRemaining(1)) return [3 /*break*/, 3];
          return [4 /*yield*/, this.doDecodeSync()];
        case 2:
          _a.sent();
          return [3 /*break*/, 1];
        case 3:
          return [2 /*return*/];
      }
    });
  };
  Decoder.prototype.decodeAsync = function (stream) {
    var stream_1, stream_1_1;
    var e_1, _a;
    return __awaiter(this, void 0, void 0, function () {
      var decoded, object, buffer, e_1_1, _b, headByte, pos, totalPos;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            decoded = false;
            _c.label = 1;
          case 1:
            _c.trys.push([1, 6, 7, 12]);
            stream_1 = __asyncValues(stream);
            _c.label = 2;
          case 2:
            return [4 /*yield*/, stream_1.next()];
          case 3:
            if (!(stream_1_1 = _c.sent(), !stream_1_1.done)) return [3 /*break*/, 5];
            buffer = stream_1_1.value;
            if (decoded) {
              throw this.createExtraByteError(this.totalPos);
            }
            this.appendBuffer(buffer);
            try {
              object = this.doDecodeSync();
              decoded = true;
            } catch (e) {
              if (!(e instanceof DataViewIndexOutOfBoundsError)) {
                throw e; // rethrow
              }
              // fallthrough
            }
            this.totalPos += this.pos;
            _c.label = 4;
          case 4:
            return [3 /*break*/, 2];
          case 5:
            return [3 /*break*/, 12];
          case 6:
            e_1_1 = _c.sent();
            e_1 = {
              error: e_1_1
            };
            return [3 /*break*/, 12];
          case 7:
            _c.trys.push([7,, 10, 11]);
            if (!(stream_1_1 && !stream_1_1.done && (_a = stream_1.return))) return [3 /*break*/, 9];
            return [4 /*yield*/, _a.call(stream_1)];
          case 8:
            _c.sent();
            _c.label = 9;
          case 9:
            return [3 /*break*/, 11];
          case 10:
            if (e_1) throw e_1.error;
            return [7 /*endfinally*/];
          case 11:
            return [7 /*endfinally*/];
          case 12:
            if (decoded) {
              if (this.hasRemaining(1)) {
                throw this.createExtraByteError(this.totalPos);
              }
              return [2 /*return*/, object];
            }
            _b = this, headByte = _b.headByte, pos = _b.pos, totalPos = _b.totalPos;
            throw new RangeError("Insufficient data in parsing ".concat(prettyByte(headByte), " at ").concat(totalPos, " (").concat(pos, " in the current buffer)"));
        }
      });
    });
  };
  Decoder.prototype.decodeArrayStream = function (stream) {
    return this.decodeMultiAsync(stream, true);
  };
  Decoder.prototype.decodeStream = function (stream) {
    return this.decodeMultiAsync(stream, false);
  };
  Decoder.prototype.decodeMultiAsync = function (stream, isArray) {
    return __asyncGenerator(this, arguments, function decodeMultiAsync_1() {
      var isArrayHeaderRequired, arrayItemsLeft, stream_2, stream_2_1, buffer, e_2, e_3_1;
      var e_3, _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            isArrayHeaderRequired = isArray;
            arrayItemsLeft = -1;
            _b.label = 1;
          case 1:
            _b.trys.push([1, 13, 14, 19]);
            stream_2 = __asyncValues(stream);
            _b.label = 2;
          case 2:
            return [4 /*yield*/, __await(stream_2.next())];
          case 3:
            if (!(stream_2_1 = _b.sent(), !stream_2_1.done)) return [3 /*break*/, 12];
            buffer = stream_2_1.value;
            if (isArray && arrayItemsLeft === 0) {
              throw this.createExtraByteError(this.totalPos);
            }
            this.appendBuffer(buffer);
            if (isArrayHeaderRequired) {
              arrayItemsLeft = this.readArraySize();
              isArrayHeaderRequired = false;
              this.complete();
            }
            _b.label = 4;
          case 4:
            _b.trys.push([4, 9,, 10]);
            _b.label = 5;
          case 5:
            if (!true) return [3 /*break*/, 8];
            return [4 /*yield*/, __await(this.doDecodeSync())];
          case 6:
            return [4 /*yield*/, _b.sent()];
          case 7:
            _b.sent();
            if (--arrayItemsLeft === 0) {
              return [3 /*break*/, 8];
            }
            return [3 /*break*/, 5];
          case 8:
            return [3 /*break*/, 10];
          case 9:
            e_2 = _b.sent();
            if (!(e_2 instanceof DataViewIndexOutOfBoundsError)) {
              throw e_2; // rethrow
            }
            return [3 /*break*/, 10];
          case 10:
            this.totalPos += this.pos;
            _b.label = 11;
          case 11:
            return [3 /*break*/, 2];
          case 12:
            return [3 /*break*/, 19];
          case 13:
            e_3_1 = _b.sent();
            e_3 = {
              error: e_3_1
            };
            return [3 /*break*/, 19];
          case 14:
            _b.trys.push([14,, 17, 18]);
            if (!(stream_2_1 && !stream_2_1.done && (_a = stream_2.return))) return [3 /*break*/, 16];
            return [4 /*yield*/, __await(_a.call(stream_2))];
          case 15:
            _b.sent();
            _b.label = 16;
          case 16:
            return [3 /*break*/, 18];
          case 17:
            if (e_3) throw e_3.error;
            return [7 /*endfinally*/];
          case 18:
            return [7 /*endfinally*/];
          case 19:
            return [2 /*return*/];
        }
      });
    });
  };
  Decoder.prototype.doDecodeSync = function () {
    DECODE: while (true) {
      var headByte = this.readHeadByte();
      var object = void 0;
      if (headByte >= 0xe0) {
        // negative fixint (111x xxxx) 0xe0 - 0xff
        object = headByte - 0x100;
      } else if (headByte < 0xc0) {
        if (headByte < 0x80) {
          // positive fixint (0xxx xxxx) 0x00 - 0x7f
          object = headByte;
        } else if (headByte < 0x90) {
          // fixmap (1000 xxxx) 0x80 - 0x8f
          var size = headByte - 0x80;
          if (size !== 0) {
            this.pushMapState(size);
            this.complete();
            continue DECODE;
          } else {
            object = {};
          }
        } else if (headByte < 0xa0) {
          // fixarray (1001 xxxx) 0x90 - 0x9f
          var size = headByte - 0x90;
          if (size !== 0) {
            this.pushArrayState(size);
            this.complete();
            continue DECODE;
          } else {
            object = [];
          }
        } else {
          // fixstr (101x xxxx) 0xa0 - 0xbf
          var byteLength = headByte - 0xa0;
          object = this.decodeUtf8String(byteLength, 0);
        }
      } else if (headByte === 0xc0) {
        // nil
        object = null;
      } else if (headByte === 0xc2) {
        // false
        object = false;
      } else if (headByte === 0xc3) {
        // true
        object = true;
      } else if (headByte === 0xca) {
        // float 32
        object = this.readF32();
      } else if (headByte === 0xcb) {
        // float 64
        object = this.readF64();
      } else if (headByte === 0xcc) {
        // uint 8
        object = this.readU8();
      } else if (headByte === 0xcd) {
        // uint 16
        object = this.readU16();
      } else if (headByte === 0xce) {
        // uint 32
        object = this.readU32();
      } else if (headByte === 0xcf) {
        // uint 64
        object = this.readU64();
      } else if (headByte === 0xd0) {
        // int 8
        object = this.readI8();
      } else if (headByte === 0xd1) {
        // int 16
        object = this.readI16();
      } else if (headByte === 0xd2) {
        // int 32
        object = this.readI32();
      } else if (headByte === 0xd3) {
        // int 64
        object = this.readI64();
      } else if (headByte === 0xd9) {
        // str 8
        var byteLength = this.lookU8();
        object = this.decodeUtf8String(byteLength, 1);
      } else if (headByte === 0xda) {
        // str 16
        var byteLength = this.lookU16();
        object = this.decodeUtf8String(byteLength, 2);
      } else if (headByte === 0xdb) {
        // str 32
        var byteLength = this.lookU32();
        object = this.decodeUtf8String(byteLength, 4);
      } else if (headByte === 0xdc) {
        // array 16
        var size = this.readU16();
        if (size !== 0) {
          this.pushArrayState(size);
          this.complete();
          continue DECODE;
        } else {
          object = [];
        }
      } else if (headByte === 0xdd) {
        // array 32
        var size = this.readU32();
        if (size !== 0) {
          this.pushArrayState(size);
          this.complete();
          continue DECODE;
        } else {
          object = [];
        }
      } else if (headByte === 0xde) {
        // map 16
        var size = this.readU16();
        if (size !== 0) {
          this.pushMapState(size);
          this.complete();
          continue DECODE;
        } else {
          object = {};
        }
      } else if (headByte === 0xdf) {
        // map 32
        var size = this.readU32();
        if (size !== 0) {
          this.pushMapState(size);
          this.complete();
          continue DECODE;
        } else {
          object = {};
        }
      } else if (headByte === 0xc4) {
        // bin 8
        var size = this.lookU8();
        object = this.decodeBinary(size, 1);
      } else if (headByte === 0xc5) {
        // bin 16
        var size = this.lookU16();
        object = this.decodeBinary(size, 2);
      } else if (headByte === 0xc6) {
        // bin 32
        var size = this.lookU32();
        object = this.decodeBinary(size, 4);
      } else if (headByte === 0xd4) {
        // fixext 1
        object = this.decodeExtension(1, 0);
      } else if (headByte === 0xd5) {
        // fixext 2
        object = this.decodeExtension(2, 0);
      } else if (headByte === 0xd6) {
        // fixext 4
        object = this.decodeExtension(4, 0);
      } else if (headByte === 0xd7) {
        // fixext 8
        object = this.decodeExtension(8, 0);
      } else if (headByte === 0xd8) {
        // fixext 16
        object = this.decodeExtension(16, 0);
      } else if (headByte === 0xc7) {
        // ext 8
        var size = this.lookU8();
        object = this.decodeExtension(size, 1);
      } else if (headByte === 0xc8) {
        // ext 16
        var size = this.lookU16();
        object = this.decodeExtension(size, 2);
      } else if (headByte === 0xc9) {
        // ext 32
        var size = this.lookU32();
        object = this.decodeExtension(size, 4);
      } else {
        throw new DecodeError("Unrecognized type byte: ".concat(prettyByte(headByte)));
      }
      this.complete();
      var stack = this.stack;
      while (stack.length > 0) {
        // arrays and maps
        var state = stack[stack.length - 1];
        if (state.type === 0 /* State.ARRAY */) {
          state.array[state.position] = object;
          state.position++;
          if (state.position === state.size) {
            stack.pop();
            object = state.array;
          } else {
            continue DECODE;
          }
        } else if (state.type === 1 /* State.MAP_KEY */) {
          if (!isValidMapKeyType(object)) {
            throw new DecodeError("The type of key must be string or number but " + typeof object);
          }
          if (object === "__proto__") {
            throw new DecodeError("The key __proto__ is not allowed");
          }
          state.key = object;
          state.type = 2 /* State.MAP_VALUE */;
          continue DECODE;
        } else {
          // it must be `state.type === State.MAP_VALUE` here
          state.map[state.key] = object;
          state.readCount++;
          if (state.readCount === state.size) {
            stack.pop();
            object = state.map;
          } else {
            state.key = null;
            state.type = 1 /* State.MAP_KEY */;
            continue DECODE;
          }
        }
      }
      return object;
    }
  };
  Decoder.prototype.readHeadByte = function () {
    if (this.headByte === HEAD_BYTE_REQUIRED) {
      this.headByte = this.readU8();
      // console.log("headByte", prettyByte(this.headByte));
    }
    return this.headByte;
  };
  Decoder.prototype.complete = function () {
    this.headByte = HEAD_BYTE_REQUIRED;
  };
  Decoder.prototype.readArraySize = function () {
    var headByte = this.readHeadByte();
    switch (headByte) {
      case 0xdc:
        return this.readU16();
      case 0xdd:
        return this.readU32();
      default:
        {
          if (headByte < 0xa0) {
            return headByte - 0x90;
          } else {
            throw new DecodeError("Unrecognized array type byte: ".concat(prettyByte(headByte)));
          }
        }
    }
  };
  Decoder.prototype.pushMapState = function (size) {
    if (size > this.maxMapLength) {
      throw new DecodeError("Max length exceeded: map length (".concat(size, ") > maxMapLengthLength (").concat(this.maxMapLength, ")"));
    }
    this.stack.push({
      type: 1 /* State.MAP_KEY */,
      size: size,
      key: null,
      readCount: 0,
      map: {}
    });
  };
  Decoder.prototype.pushArrayState = function (size) {
    if (size > this.maxArrayLength) {
      throw new DecodeError("Max length exceeded: array length (".concat(size, ") > maxArrayLength (").concat(this.maxArrayLength, ")"));
    }
    this.stack.push({
      type: 0 /* State.ARRAY */,
      size: size,
      array: new Array(size),
      position: 0
    });
  };
  Decoder.prototype.decodeUtf8String = function (byteLength, headerOffset) {
    var _a;
    if (byteLength > this.maxStrLength) {
      throw new DecodeError("Max length exceeded: UTF-8 byte length (".concat(byteLength, ") > maxStrLength (").concat(this.maxStrLength, ")"));
    }
    if (this.bytes.byteLength < this.pos + headerOffset + byteLength) {
      throw MORE_DATA;
    }
    var offset = this.pos + headerOffset;
    var object;
    if (this.stateIsMapKey() && ((_a = this.keyDecoder) === null || _a === void 0 ? void 0 : _a.canBeCached(byteLength))) {
      object = this.keyDecoder.decode(this.bytes, offset, byteLength);
    } else if (byteLength > TEXT_DECODER_THRESHOLD) {
      object = utf8DecodeTD(this.bytes, offset, byteLength);
    } else {
      object = utf8DecodeJs(this.bytes, offset, byteLength);
    }
    this.pos += headerOffset + byteLength;
    return object;
  };
  Decoder.prototype.stateIsMapKey = function () {
    if (this.stack.length > 0) {
      var state = this.stack[this.stack.length - 1];
      return state.type === 1 /* State.MAP_KEY */;
    }
    return false;
  };
  Decoder.prototype.decodeBinary = function (byteLength, headOffset) {
    if (byteLength > this.maxBinLength) {
      throw new DecodeError("Max length exceeded: bin length (".concat(byteLength, ") > maxBinLength (").concat(this.maxBinLength, ")"));
    }
    if (!this.hasRemaining(byteLength + headOffset)) {
      throw MORE_DATA;
    }
    var offset = this.pos + headOffset;
    var object = this.bytes.subarray(offset, offset + byteLength);
    this.pos += headOffset + byteLength;
    return object;
  };
  Decoder.prototype.decodeExtension = function (size, headOffset) {
    if (size > this.maxExtLength) {
      throw new DecodeError("Max length exceeded: ext length (".concat(size, ") > maxExtLength (").concat(this.maxExtLength, ")"));
    }
    var extType = this.view.getInt8(this.pos + headOffset);
    var data = this.decodeBinary(size, headOffset + 1 /* extType */);
    return this.extensionCodec.decode(data, extType, this.context);
  };
  Decoder.prototype.lookU8 = function () {
    return this.view.getUint8(this.pos);
  };
  Decoder.prototype.lookU16 = function () {
    return this.view.getUint16(this.pos);
  };
  Decoder.prototype.lookU32 = function () {
    return this.view.getUint32(this.pos);
  };
  Decoder.prototype.readU8 = function () {
    var value = this.view.getUint8(this.pos);
    this.pos++;
    return value;
  };
  Decoder.prototype.readI8 = function () {
    var value = this.view.getInt8(this.pos);
    this.pos++;
    return value;
  };
  Decoder.prototype.readU16 = function () {
    var value = this.view.getUint16(this.pos);
    this.pos += 2;
    return value;
  };
  Decoder.prototype.readI16 = function () {
    var value = this.view.getInt16(this.pos);
    this.pos += 2;
    return value;
  };
  Decoder.prototype.readU32 = function () {
    var value = this.view.getUint32(this.pos);
    this.pos += 4;
    return value;
  };
  Decoder.prototype.readI32 = function () {
    var value = this.view.getInt32(this.pos);
    this.pos += 4;
    return value;
  };
  Decoder.prototype.readU64 = function () {
    var value = getUint64(this.view, this.pos);
    this.pos += 8;
    return value;
  };
  Decoder.prototype.readI64 = function () {
    var value = getInt64(this.view, this.pos);
    this.pos += 8;
    return value;
  };
  Decoder.prototype.readF32 = function () {
    var value = this.view.getFloat32(this.pos);
    this.pos += 4;
    return value;
  };
  Decoder.prototype.readF64 = function () {
    var value = this.view.getFloat64(this.pos);
    this.pos += 8;
    return value;
  };
  return Decoder;
}();
export { Decoder };
