
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, concatMap, Observable, of, tap, throwError } from 'rxjs';

// services
import { WMLNotifyOneService } from '@windmillcode/angular-wml-notify';
import { BaseService } from '@core/base/base.service';

@Injectable({ providedIn: 'root' })
export class NewAzureAccessTokenInterceptor implements HttpInterceptor {

  baseService:BaseService
  constructor(
    public injector:Injector,
    public WMLNotifyOneService:WMLNotifyOneService,
  ) {
    setTimeout(()=>{
      this.baseService = this.injector.get(BaseService)
    })
  }

  addAccessTokenToRequest = (request: HttpRequest<unknown>,next:HttpHandler)=>{
    request = request.clone({body:{
      // @ts-ignore
      data:request.body.data,
      access_token:this.getAzureAccessToken(),
    }})
    return next.handle(request)
  }

  getAzureAccessToken =()=>{
    return"access_token"
  }
  getNewAzureAccessToken = ()=>{
    return of({})
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(

      catchError((err:HttpErrorResponse) => {
        if(err.status === 403){

          return this.getNewAzureAccessToken()
          .pipe(
            concatMap(()=>{
              return this.addAccessTokenToRequest(request,next)
            }),
            tap({
              error:()=>{
                this.baseService.createWMLNote("global.loginAgain")

              }
            })
          )
        }
        else{
          return throwError(()=>err)
        }
      })
    );
  }
}
