import * as e from "Quill";
var o = {
    d: (e, r) => {
      for (var n in r) o.o(r, n) && !o.o(e, n) && Object.defineProperty(e, n, {
        enumerable: !0,
        get: r[n]
      });
    },
    o: (e, o) => Object.prototype.hasOwnProperty.call(e, o)
  },
  r = {};
o.d(r, {
  If: () => b,
  OC: () => le,
  LK: () => Ne,
  y$: () => Le
});
const n = [{
  name: "100",
  unicode: "💯",
  category: "s",
  emoji_order: "2119"
}, {
  name: "1234",
  unicode: "🔢",
  category: "s",
  emoji_order: "2122"
}, {
  name: "grinning",
  unicode: "😀",
  category: "p",
  emoji_order: "1"
}, {
  name: "grin",
  unicode: "😁",
  category: "p",
  emoji_order: "2"
}, {
  name: "joy",
  unicode: "😂",
  category: "p",
  emoji_order: "3"
}, {
  name: "rolling_on_the_floor_laughing",
  unicode: "🤣",
  category: "p",
  emoji_order: "4"
}, {
  name: "smiley",
  unicode: "😃",
  category: "p",
  emoji_order: "5"
}, {
  name: "smile",
  unicode: "😄",
  category: "p",
  emoji_order: "6"
}, {
  name: "sweat_smile",
  unicode: "😅",
  category: "p",
  emoji_order: "7"
}, {
  name: "laughing",
  unicode: "😆",
  category: "p",
  emoji_order: "8"
}, {
  name: "wink",
  unicode: "😉",
  category: "p",
  emoji_order: "9"
}, {
  name: "blush",
  unicode: "😊",
  category: "p",
  emoji_order: "10"
}, {
  name: "yum",
  unicode: "😋",
  category: "p",
  emoji_order: "11"
}, {
  name: "sunglasses",
  unicode: "😎",
  category: "p",
  emoji_order: "12"
}, {
  name: "heart_eyes",
  unicode: "😍",
  category: "p",
  emoji_order: "13"
}, {
  name: "kissing_heart",
  unicode: "😘",
  category: "p",
  emoji_order: "14"
}, {
  name: "kissing",
  unicode: "😗",
  category: "p",
  emoji_order: "15"
}, {
  name: "kissing_smiling_eyes",
  unicode: "😙",
  category: "p",
  emoji_order: "16"
}, {
  name: "kissing_closed_eyes",
  unicode: "😚",
  category: "p",
  emoji_order: "17"
}, {
  name: "slightly_smiling_face",
  unicode: "🙂",
  category: "p",
  emoji_order: "19"
}, {
  name: "hugging_face",
  unicode: "🤗",
  category: "p",
  emoji_order: "20"
}, {
  name: "thinking_face",
  unicode: "🤔",
  category: "p",
  emoji_order: "21"
}, {
  name: "neutral_face",
  unicode: "😐",
  category: "p",
  emoji_order: "22"
}, {
  name: "expressionless",
  unicode: "😑",
  category: "p",
  emoji_order: "23"
}, {
  name: "no_mouth",
  unicode: "😶",
  category: "p",
  emoji_order: "24"
}, {
  name: "face_with_rolling_eyes",
  unicode: "🙄",
  category: "p",
  emoji_order: "25"
}, {
  name: "smirk",
  unicode: "😏",
  category: "p",
  emoji_order: "26"
}, {
  name: "persevere",
  unicode: "😣",
  category: "p",
  emoji_order: "27"
}, {
  name: "disappointed_relieved",
  unicode: "😥",
  category: "p",
  emoji_order: "28"
}, {
  name: "open_mouth",
  unicode: "😮",
  category: "p",
  emoji_order: "29"
}, {
  name: "zipper_mouth_face",
  unicode: "🤐",
  category: "p",
  emoji_order: "30"
}, {
  name: "hushed",
  unicode: "😯",
  category: "p",
  emoji_order: "31"
}, {
  name: "sleepy",
  unicode: "😪",
  category: "p",
  emoji_order: "32"
}, {
  name: "tired_face",
  unicode: "😫",
  category: "p",
  emoji_order: "33"
}, {
  name: "sleeping",
  unicode: "😴",
  category: "p",
  emoji_order: "34"
}, {
  name: "relieved",
  unicode: "😌",
  category: "p",
  emoji_order: "35"
}, {
  name: "nerd_face",
  unicode: "🤓",
  category: "p",
  emoji_order: "36"
}, {
  name: "stuck_out_tongue",
  unicode: "😛",
  category: "p",
  emoji_order: "37"
}, {
  name: "stuck_out_tongue_winking_eye",
  unicode: "😜",
  category: "p",
  emoji_order: "38"
}, {
  name: "stuck_out_tongue_closed_eyes",
  unicode: "😝",
  category: "p",
  emoji_order: "39"
}, {
  name: "drooling_face",
  unicode: "🤤",
  category: "p",
  emoji_order: "40"
}, {
  name: "unamused",
  unicode: "😒",
  category: "p",
  emoji_order: "41"
}, {
  name: "sweat",
  unicode: "😓",
  category: "p",
  emoji_order: "42"
}, {
  name: "pensive",
  unicode: "😔",
  category: "p",
  emoji_order: "43"
}, {
  name: "confused",
  unicode: "😕",
  category: "p",
  emoji_order: "44"
}, {
  name: "upside_down_face",
  unicode: "🙃",
  category: "p",
  emoji_order: "45"
}, {
  name: "money_mouth_face",
  unicode: "🤑",
  category: "p",
  emoji_order: "46"
}, {
  name: "astonished",
  unicode: "😲",
  category: "p",
  emoji_order: "47"
}, {
  name: "slightly_frowning_face",
  unicode: "🙁",
  category: "p",
  emoji_order: "49"
}, {
  name: "confounded",
  unicode: "😖",
  category: "p",
  emoji_order: "50"
}, {
  name: "disappointed",
  unicode: "😞",
  category: "p",
  emoji_order: "51"
}, {
  name: "worried",
  unicode: "😟",
  category: "p",
  emoji_order: "52"
}, {
  name: "triumph",
  unicode: "😤",
  category: "p",
  emoji_order: "53"
}, {
  name: "cry",
  unicode: "😢",
  category: "p",
  emoji_order: "54"
}, {
  name: "sob",
  unicode: "😭",
  category: "p",
  emoji_order: "55"
}, {
  name: "frowning",
  unicode: "😦",
  category: "p",
  emoji_order: "56"
}, {
  name: "anguished",
  unicode: "😧",
  category: "p",
  emoji_order: "57"
}, {
  name: "fearful",
  unicode: "😨",
  category: "p",
  emoji_order: "58"
}, {
  name: "weary",
  unicode: "😩",
  category: "p",
  emoji_order: "59"
}, {
  name: "grimacing",
  unicode: "😬",
  category: "p",
  emoji_order: "60"
}, {
  name: "cold_sweat",
  unicode: "😰",
  category: "p",
  emoji_order: "61"
}, {
  name: "scream",
  unicode: "😱",
  category: "p",
  emoji_order: "62"
}, {
  name: "flushed",
  unicode: "😳",
  category: "p",
  emoji_order: "63"
}, {
  name: "dizzy_face",
  unicode: "😵",
  category: "p",
  emoji_order: "64"
}, {
  name: "rage",
  unicode: "😡",
  category: "p",
  emoji_order: "65"
}, {
  name: "angry",
  unicode: "😠",
  category: "p",
  emoji_order: "66"
}, {
  name: "innocent",
  unicode: "😇",
  category: "p",
  emoji_order: "67"
}, {
  name: "face_with_cowboy_hat",
  unicode: "🤠",
  category: "p",
  emoji_order: "68"
}, {
  name: "clown_face",
  unicode: "🤡",
  category: "p",
  emoji_order: "69"
}, {
  name: "lying_face",
  unicode: "🤥",
  category: "p",
  emoji_order: "70"
}, {
  name: "mask",
  unicode: "😷",
  category: "p",
  emoji_order: "71"
}, {
  name: "face_with_thermometer",
  unicode: "🤒",
  category: "p",
  emoji_order: "72"
}, {
  name: "face_with_head_bandage",
  unicode: "🤕",
  category: "p",
  emoji_order: "73"
}, {
  name: "nauseated_face",
  unicode: "🤢",
  category: "p",
  emoji_order: "74"
}, {
  name: "sneezing_face",
  unicode: "🤧",
  category: "p",
  emoji_order: "75"
}, {
  name: "smiling_imp",
  unicode: "😈",
  category: "p",
  emoji_order: "76"
}, {
  name: "imp",
  unicode: "👿",
  category: "p",
  emoji_order: "77"
}, {
  name: "japanese_ogre",
  unicode: "👹",
  category: "p",
  emoji_order: "78"
}, {
  name: "japanese_goblin",
  unicode: "👺",
  category: "p",
  emoji_order: "79"
}, {
  name: "skull",
  unicode: "💀",
  category: "p",
  emoji_order: "80"
}, {
  name: "skull_and_crossbones",
  unicode: "☠",
  category: "o",
  emoji_order: "81"
}, {
  name: "ghost",
  unicode: "👻",
  category: "p",
  emoji_order: "82"
}, {
  name: "alien",
  unicode: "👽",
  category: "p",
  emoji_order: "83"
}, {
  name: "space_invader",
  unicode: "👾",
  category: "a",
  emoji_order: "84"
}, {
  name: "robot_face",
  unicode: "🤖",
  category: "p",
  emoji_order: "85"
}, {
  name: "hankey",
  unicode: "💩",
  category: "p",
  emoji_order: "86"
}, {
  name: "smiley_cat",
  unicode: "😺",
  category: "p",
  emoji_order: "87"
}, {
  name: "smile_cat",
  unicode: "😸",
  category: "p",
  emoji_order: "88"
}, {
  name: "joy_cat",
  unicode: "😹",
  category: "p",
  emoji_order: "89"
}, {
  name: "heart_eyes_cat",
  unicode: "😻",
  category: "p",
  emoji_order: "90"
}, {
  name: "smirk_cat",
  unicode: "😼",
  category: "p",
  emoji_order: "91"
}, {
  name: "kissing_cat",
  unicode: "😽",
  category: "p",
  emoji_order: "92"
}, {
  name: "scream_cat",
  unicode: "🙀",
  category: "p",
  emoji_order: "93"
}, {
  name: "crying_cat_face",
  unicode: "😿",
  category: "p",
  emoji_order: "94"
}, {
  name: "pouting_cat",
  unicode: "😾",
  category: "p",
  emoji_order: "95"
}, {
  name: "see_no_evil",
  unicode: "🙈",
  category: "n",
  emoji_order: "96"
}, {
  name: "hear_no_evil",
  unicode: "🙉",
  category: "n",
  emoji_order: "97"
}, {
  name: "speak_no_evil",
  unicode: "🙊",
  category: "n",
  emoji_order: "98"
}, {
  name: "boy",
  unicode: "👦",
  category: "p",
  emoji_order: "99"
}, {
  name: "girl",
  unicode: "👧",
  category: "p",
  emoji_order: "105"
}, {
  name: "man",
  unicode: "👨",
  category: "p",
  emoji_order: "111"
}, {
  name: "woman",
  unicode: "👩",
  category: "p",
  emoji_order: "117"
}, {
  name: "older_man",
  unicode: "👴",
  category: "p",
  emoji_order: "123"
}, {
  name: "older_woman",
  unicode: "👵",
  category: "p",
  emoji_order: "129"
}, {
  name: "baby",
  unicode: "👶",
  category: "p",
  emoji_order: "135"
}, {
  name: "angel",
  unicode: "👼",
  category: "p",
  emoji_order: "141"
}, {
  name: "cop",
  unicode: "👮",
  category: "p",
  emoji_order: "339"
}, {
  name: "sleuth_or_spy",
  unicode: "🕵",
  category: "p",
  emoji_order: "357"
}, {
  name: "guardsman",
  unicode: "💂",
  category: "p",
  emoji_order: "375"
}, {
  name: "construction_worker",
  unicode: "👷",
  category: "p",
  emoji_order: "393"
}, {
  name: "man_with_turban",
  unicode: "👳",
  category: "p",
  emoji_order: "411"
}, {
  name: "person_with_blond_hair",
  unicode: "👱",
  category: "p",
  emoji_order: "429"
}, {
  name: "santa",
  unicode: "🎅",
  category: "p",
  emoji_order: "447"
}, {
  name: "mrs_claus",
  unicode: "🤶",
  category: "p",
  emoji_order: "453"
}, {
  name: "princess",
  unicode: "👸",
  category: "p",
  emoji_order: "459"
}, {
  name: "prince",
  unicode: "🤴",
  category: "p",
  emoji_order: "465"
}, {
  name: "bride_with_veil",
  unicode: "👰",
  category: "p",
  emoji_order: "471"
}, {
  name: "man_in_tuxedo",
  unicode: "🤵",
  category: "p",
  emoji_order: "477"
}, {
  name: "pregnant_woman",
  unicode: "🤰",
  category: "p",
  emoji_order: "483"
}, {
  name: "man_with_gua_pi_mao",
  unicode: "👲",
  category: "p",
  emoji_order: "489"
}, {
  name: "person_frowning",
  unicode: "🙍",
  category: "p",
  emoji_order: "495"
}, {
  name: "person_with_pouting_face",
  unicode: "🙎",
  category: "p",
  emoji_order: "513"
}, {
  name: "no_good",
  unicode: "🙅",
  category: "p",
  emoji_order: "531"
}, {
  name: "ok_woman",
  unicode: "🙆",
  category: "p",
  emoji_order: "549"
}, {
  name: "information_desk_person",
  unicode: "💁",
  category: "p",
  emoji_order: "567"
}, {
  name: "raising_hand",
  unicode: "🙋",
  category: "p",
  emoji_order: "585"
}, {
  name: "bow",
  unicode: "🙇",
  category: "p",
  emoji_order: "603"
}, {
  name: "face_palm",
  unicode: "🤦",
  category: "p",
  emoji_order: "621"
}, {
  name: "shrug",
  unicode: "🤷",
  category: "p",
  emoji_order: "639"
}, {
  name: "massage",
  unicode: "💆",
  category: "p",
  emoji_order: "657"
}, {
  name: "haircut",
  unicode: "💇",
  category: "p",
  emoji_order: "675"
}, {
  name: "walking",
  unicode: "🚶",
  category: "p",
  emoji_order: "693"
}, {
  name: "runner",
  unicode: "🏃",
  category: "p",
  emoji_order: "711"
}, {
  name: "dancer",
  unicode: "💃",
  category: "p",
  emoji_order: "729"
}, {
  name: "man_dancing",
  unicode: "🕺",
  category: "p",
  emoji_order: "735"
}, {
  name: "dancers",
  unicode: "👯",
  category: "p",
  emoji_order: "741"
}, {
  name: "man_in_business_suit_levitating",
  unicode: "🕴",
  category: "a",
  emoji_order: "759"
}, {
  name: "speaking_head_in_silhouette",
  unicode: "🗣",
  category: "p",
  emoji_order: "765"
}, {
  name: "bust_in_silhouette",
  unicode: "👤",
  category: "p",
  emoji_order: "766"
}, {
  name: "busts_in_silhouette",
  unicode: "👥",
  category: "p",
  emoji_order: "767"
}, {
  name: "fencer",
  unicode: "🤺",
  category: "a",
  emoji_order: "768"
}, {
  name: "horse_racing",
  unicode: "🏇",
  category: "a",
  emoji_order: "769"
}, {
  name: "skier",
  unicode: "⛷",
  category: "a",
  emoji_order: "775"
}, {
  name: "snowboarder",
  unicode: "🏂",
  category: "a",
  emoji_order: "776"
}, {
  name: "golfer",
  unicode: "🏌",
  category: "a",
  emoji_order: "782"
}, {
  name: "surfer",
  unicode: "🏄",
  category: "a",
  emoji_order: "800"
}, {
  name: "rowboat",
  unicode: "🚣",
  category: "a",
  emoji_order: "818"
}, {
  name: "swimmer",
  unicode: "🏊",
  category: "a",
  emoji_order: "836"
}, {
  name: "person_with_ball",
  unicode: "⛹",
  category: "a",
  emoji_order: "854"
}, {
  name: "weight_lifter",
  unicode: "🏋",
  category: "a",
  emoji_order: "872"
}, {
  name: "bicyclist",
  unicode: "🚴",
  category: "a",
  emoji_order: "890"
}, {
  name: "mountain_bicyclist",
  unicode: "🚵",
  category: "a",
  emoji_order: "908"
}, {
  name: "racing_car",
  unicode: "🏎",
  category: "t",
  emoji_order: "926"
}, {
  name: "racing_motorcycle",
  unicode: "🏍",
  category: "t",
  emoji_order: "927"
}, {
  name: "cartwheel",
  unicode: "🤸",
  category: "a",
  emoji_order: "928"
}, {
  name: "wrestlers",
  unicode: "🤼",
  category: "a",
  emoji_order: "946"
}, {
  name: "water_polo",
  unicode: "🤽",
  category: "a",
  emoji_order: "964"
}, {
  name: "handball",
  unicode: "🤾",
  category: "a",
  emoji_order: "982"
}, {
  name: "juggling",
  unicode: "🤹",
  category: "a",
  emoji_order: "1000"
}, {
  name: "couple",
  unicode: "👫",
  category: "p",
  emoji_order: "1018"
}, {
  name: "two_men_holding_hands",
  unicode: "👬",
  category: "p",
  emoji_order: "1024"
}, {
  name: "two_women_holding_hands",
  unicode: "👭",
  category: "p",
  emoji_order: "1030"
}, {
  name: "couplekiss",
  unicode: "💏",
  category: "p",
  emoji_order: "1036"
}, {
  name: "couple_with_heart",
  unicode: "💑",
  category: "p",
  emoji_order: "1040"
}, {
  name: "family",
  unicode: "👪",
  category: "p",
  emoji_order: "1044"
}, {
  name: "muscle",
  unicode: "💪",
  category: "p",
  emoji_order: "1080"
}, {
  name: "selfie",
  unicode: "🤳",
  category: "p",
  emoji_order: "1086"
}, {
  name: "point_left",
  unicode: "👈",
  category: "p",
  emoji_order: "1092"
}, {
  name: "point_right",
  unicode: "👉",
  category: "p",
  emoji_order: "1098"
}, {
  name: "point_up",
  unicode: "☝",
  category: "p",
  emoji_order: "1104"
}, {
  name: "point_up_2",
  unicode: "👆",
  category: "p",
  emoji_order: "1110"
}, {
  name: "middle_finger",
  unicode: "🖕",
  category: "p",
  emoji_order: "1116"
}, {
  name: "point_down",
  unicode: "👇",
  category: "p",
  emoji_order: "1122"
}, {
  name: "v",
  unicode: "✌",
  category: "p",
  emoji_order: "1128"
}, {
  name: "fingers_crossed",
  unicode: "🤞",
  category: "p",
  emoji_order: "1134"
}, {
  name: "vulcan",
  unicode: "🖖",
  category: "p",
  emoji_order: "1140"
}, {
  name: "the_horns",
  unicode: "🤘",
  category: "p",
  emoji_order: "1146"
}, {
  name: "call_me",
  unicode: "🤙",
  category: "p",
  emoji_order: "1152"
}, {
  name: "raised_hand_with_fingers_splayed",
  unicode: "🖐",
  category: "p",
  emoji_order: "1158"
}, {
  name: "raised_hand",
  unicode: "✋",
  category: "p",
  emoji_order: "1164"
}, {
  name: "ok_hand",
  unicode: "👌",
  category: "p",
  emoji_order: "1170"
}, {
  name: "thumbsup",
  unicode: "👍",
  category: "p",
  emoji_order: "1176"
}, {
  name: "thumbsdown",
  unicode: "👎",
  category: "p",
  emoji_order: "1182"
}, {
  name: "fist",
  unicode: "✊",
  category: "p",
  emoji_order: "1188"
}, {
  name: "facepunch",
  unicode: "👊",
  category: "p",
  emoji_order: "1194"
}, {
  name: "left_facing_fist",
  unicode: "🤛",
  category: "p",
  emoji_order: "1200"
}, {
  name: "right_facing_fist",
  unicode: "🤜",
  category: "p",
  emoji_order: "1206"
}, {
  name: "raised_back_of_hand",
  unicode: "🤚",
  category: "p",
  emoji_order: "1212"
}, {
  name: "wave",
  unicode: "👋",
  category: "p",
  emoji_order: "1218"
}, {
  name: "clap",
  unicode: "👏",
  category: "p",
  emoji_order: "1224"
}, {
  name: "writing_hand",
  unicode: "✍",
  category: "p",
  emoji_order: "1230"
}, {
  name: "open_hands",
  unicode: "👐",
  category: "p",
  emoji_order: "1236"
}, {
  name: "raised_hands",
  unicode: "🙌",
  category: "p",
  emoji_order: "1242"
}, {
  name: "pray",
  unicode: "🙏",
  category: "p",
  emoji_order: "1248"
}, {
  name: "handshake",
  unicode: "🤝",
  category: "p",
  emoji_order: "1254"
}, {
  name: "nail_care",
  unicode: "💅",
  category: "p",
  emoji_order: "1260"
}, {
  name: "ear",
  unicode: "👂",
  category: "p",
  emoji_order: "1266"
}, {
  name: "nose",
  unicode: "👃",
  category: "p",
  emoji_order: "1272"
}, {
  name: "footprints",
  unicode: "👣",
  category: "p",
  emoji_order: "1278"
}, {
  name: "eyes",
  unicode: "👀",
  category: "p",
  emoji_order: "1279"
}, {
  name: "eye",
  unicode: "👁",
  category: "p",
  emoji_order: "1280"
}, {
  name: "tongue",
  unicode: "👅",
  category: "p",
  emoji_order: "1282"
}, {
  name: "lips",
  unicode: "👄",
  category: "p",
  emoji_order: "1283"
}, {
  name: "kiss",
  unicode: "💋",
  category: "p",
  emoji_order: "1284"
}, {
  name: "cupid",
  unicode: "💘",
  category: "s",
  emoji_order: "1285"
}, {
  name: "heart",
  unicode: "❤",
  category: "s",
  emoji_order: "1286"
}, {
  name: "heartbeat",
  unicode: "💓",
  category: "s",
  emoji_order: "1287"
}, {
  name: "broken_heart",
  unicode: "💔",
  category: "s",
  emoji_order: "1288"
}, {
  name: "two_hearts",
  unicode: "💕",
  category: "s",
  emoji_order: "1289"
}, {
  name: "sparkling_heart",
  unicode: "💖",
  category: "s",
  emoji_order: "1290"
}, {
  name: "heartpulse",
  unicode: "💗",
  category: "s",
  emoji_order: "1291"
}, {
  name: "blue_heart",
  unicode: "💙",
  category: "s",
  emoji_order: "1292"
}, {
  name: "green_heart",
  unicode: "💚",
  category: "s",
  emoji_order: "1293"
}, {
  name: "yellow_heart",
  unicode: "💛",
  category: "s",
  emoji_order: "1294"
}, {
  name: "purple_heart",
  unicode: "💜",
  category: "s",
  emoji_order: "1295"
}, {
  name: "black_heart",
  unicode: "🖤",
  category: "s",
  emoji_order: "1296"
}, {
  name: "gift_heart",
  unicode: "💝",
  category: "s",
  emoji_order: "1297"
}, {
  name: "revolving_hearts",
  unicode: "💞",
  category: "s",
  emoji_order: "1298"
}, {
  name: "heart_decoration",
  unicode: "💟",
  category: "s",
  emoji_order: "1299"
}, {
  name: "heart_exclamation",
  unicode: "❣",
  category: "s",
  emoji_order: "1300"
}, {
  name: "love_letter",
  unicode: "💌",
  category: "o",
  emoji_order: "1301"
}, {
  name: "zzz",
  unicode: "💤",
  category: "p",
  emoji_order: "1302"
}, {
  name: "anger",
  unicode: "💢",
  category: "s",
  emoji_order: "1303"
}, {
  name: "bomb",
  unicode: "💣",
  category: "o",
  emoji_order: "1304"
}, {
  name: "boom",
  unicode: "💥",
  category: "s",
  emoji_order: "1305"
}, {
  name: "sweat_drops",
  unicode: "💦",
  category: "n",
  emoji_order: "1306"
}, {
  name: "dash",
  unicode: "💨",
  category: "n",
  emoji_order: "1307"
}, {
  name: "dizzy",
  unicode: "💫",
  category: "s",
  emoji_order: "1308"
}, {
  name: "speech_balloon",
  unicode: "💬",
  category: "s",
  emoji_order: "1309"
}, {
  name: "left_speech_bubble",
  unicode: "🗨",
  category: "s",
  emoji_order: "1310"
}, {
  name: "right_anger_bubble",
  unicode: "🗯",
  category: "s",
  emoji_order: "1311"
}, {
  name: "thought_balloon",
  unicode: "💭",
  category: "s",
  emoji_order: "1312"
}, {
  name: "hole",
  unicode: "🕳",
  category: "o",
  emoji_order: "1313"
}, {
  name: "eyeglasses",
  unicode: "👓",
  category: "p",
  emoji_order: "1314"
}, {
  name: "dark_sunglasses",
  unicode: "🕶",
  category: "p",
  emoji_order: "1315"
}, {
  name: "necktie",
  unicode: "👔",
  category: "p",
  emoji_order: "1316"
}, {
  name: "shirt",
  unicode: "👕",
  category: "p",
  emoji_order: "1317"
}, {
  name: "jeans",
  unicode: "👖",
  category: "p",
  emoji_order: "1318"
}, {
  name: "dress",
  unicode: "👗",
  category: "p",
  emoji_order: "1319"
}, {
  name: "kimono",
  unicode: "👘",
  category: "p",
  emoji_order: "1320"
}, {
  name: "bikini",
  unicode: "👙",
  category: "p",
  emoji_order: "1321"
}, {
  name: "womans_clothes",
  unicode: "👚",
  category: "p",
  emoji_order: "1322"
}, {
  name: "purse",
  unicode: "👛",
  category: "p",
  emoji_order: "1323"
}, {
  name: "handbag",
  unicode: "👜",
  category: "p",
  emoji_order: "1324"
}, {
  name: "pouch",
  unicode: "👝",
  category: "p",
  emoji_order: "1325"
}, {
  name: "shopping_bags",
  unicode: "🛍",
  category: "o",
  emoji_order: "1326"
}, {
  name: "school_satchel",
  unicode: "🎒",
  category: "p",
  emoji_order: "1327"
}, {
  name: "mans_shoe",
  unicode: "👞",
  category: "p",
  emoji_order: "1328"
}, {
  name: "athletic_shoe",
  unicode: "👟",
  category: "p",
  emoji_order: "1329"
}, {
  name: "high_heel",
  unicode: "👠",
  category: "p",
  emoji_order: "1330"
}, {
  name: "sandal",
  unicode: "👡",
  category: "p",
  emoji_order: "1331"
}, {
  name: "boot",
  unicode: "👢",
  category: "p",
  emoji_order: "1332"
}, {
  name: "crown",
  unicode: "👑",
  category: "p",
  emoji_order: "1333"
}, {
  name: "womans_hat",
  unicode: "👒",
  category: "p",
  emoji_order: "1334"
}, {
  name: "tophat",
  unicode: "🎩",
  category: "p",
  emoji_order: "1335"
}, {
  name: "mortar_board",
  unicode: "🎓",
  category: "p",
  emoji_order: "1336"
}, {
  name: "helmet_with_white_cross",
  unicode: "⛑",
  category: "p",
  emoji_order: "1337"
}, {
  name: "prayer_beads",
  unicode: "📿",
  category: "o",
  emoji_order: "1338"
}, {
  name: "lipstick",
  unicode: "💄",
  category: "p",
  emoji_order: "1339"
}, {
  name: "ring",
  unicode: "💍",
  category: "p",
  emoji_order: "1340"
}, {
  name: "gem",
  unicode: "💎",
  category: "o",
  emoji_order: "1341"
}, {
  name: "monkey_face",
  unicode: "🐵",
  category: "n",
  emoji_order: "1342"
}, {
  name: "monkey",
  unicode: "🐒",
  category: "n",
  emoji_order: "1343"
}, {
  name: "gorilla",
  unicode: "🦍",
  category: "n",
  emoji_order: "1344"
}, {
  name: "dog",
  unicode: "🐶",
  category: "n",
  emoji_order: "1345"
}, {
  name: "dog2",
  unicode: "🐕",
  category: "n",
  emoji_order: "1346"
}, {
  name: "poodle",
  unicode: "🐩",
  category: "n",
  emoji_order: "1347"
}, {
  name: "wolf",
  unicode: "🐺",
  category: "n",
  emoji_order: "1348"
}, {
  name: "fox",
  unicode: "🦊",
  category: "n",
  emoji_order: "1349"
}, {
  name: "cat",
  unicode: "🐱",
  category: "n",
  emoji_order: "1350"
}, {
  name: "cat2",
  unicode: "🐈",
  category: "n",
  emoji_order: "1351"
}, {
  name: "lion_face",
  unicode: "🦁",
  category: "n",
  emoji_order: "1352"
}, {
  name: "tiger",
  unicode: "🐯",
  category: "n",
  emoji_order: "1353"
}, {
  name: "tiger2",
  unicode: "🐅",
  category: "n",
  emoji_order: "1354"
}, {
  name: "leopard",
  unicode: "🐆",
  category: "n",
  emoji_order: "1355"
}, {
  name: "horse",
  unicode: "🐴",
  category: "n",
  emoji_order: "1356"
}, {
  name: "racehorse",
  unicode: "🐎",
  category: "n",
  emoji_order: "1357"
}, {
  name: "deer",
  unicode: "🦌",
  category: "n",
  emoji_order: "1358"
}, {
  name: "unicorn_face",
  unicode: "🦄",
  category: "n",
  emoji_order: "1359"
}, {
  name: "cow",
  unicode: "🐮",
  category: "n",
  emoji_order: "1360"
}, {
  name: "ox",
  unicode: "🐂",
  category: "n",
  emoji_order: "1361"
}, {
  name: "water_buffalo",
  unicode: "🐃",
  category: "n",
  emoji_order: "1362"
}, {
  name: "cow2",
  unicode: "🐄",
  category: "n",
  emoji_order: "1363"
}, {
  name: "pig",
  unicode: "🐷",
  category: "n",
  emoji_order: "1364"
}, {
  name: "pig2",
  unicode: "🐖",
  category: "n",
  emoji_order: "1365"
}, {
  name: "boar",
  unicode: "🐗",
  category: "n",
  emoji_order: "1366"
}, {
  name: "pig_nose",
  unicode: "🐽",
  category: "n",
  emoji_order: "1367"
}, {
  name: "ram",
  unicode: "🐏",
  category: "n",
  emoji_order: "1368"
}, {
  name: "sheep",
  unicode: "🐑",
  category: "n",
  emoji_order: "1369"
}, {
  name: "goat",
  unicode: "🐐",
  category: "n",
  emoji_order: "1370"
}, {
  name: "dromedary_camel",
  unicode: "🐪",
  category: "n",
  emoji_order: "1371"
}, {
  name: "camel",
  unicode: "🐫",
  category: "n",
  emoji_order: "1372"
}, {
  name: "elephant",
  unicode: "🐘",
  category: "n",
  emoji_order: "1373"
}, {
  name: "rhino",
  unicode: "🦏",
  category: "n",
  emoji_order: "1374"
}, {
  name: "mouse",
  unicode: "🐭",
  category: "n",
  emoji_order: "1375"
}, {
  name: "mouse2",
  unicode: "🐁",
  category: "n",
  emoji_order: "1376"
}, {
  name: "rat",
  unicode: "🐀",
  category: "n",
  emoji_order: "1377"
}, {
  name: "hamster",
  unicode: "🐹",
  category: "n",
  emoji_order: "1378"
}, {
  name: "rabbit",
  unicode: "🐰",
  category: "n",
  emoji_order: "1379"
}, {
  name: "rabbit2",
  unicode: "🐇",
  category: "n",
  emoji_order: "1380"
}, {
  name: "chipmunk",
  unicode: "🐿",
  category: "n",
  emoji_order: "1381"
}, {
  name: "bat",
  unicode: "🦇",
  category: "n",
  emoji_order: "1382"
}, {
  name: "bear",
  unicode: "🐻",
  category: "n",
  emoji_order: "1383"
}, {
  name: "koala",
  unicode: "🐨",
  category: "n",
  emoji_order: "1384"
}, {
  name: "panda_face",
  unicode: "🐼",
  category: "n",
  emoji_order: "1385"
}, {
  name: "feet",
  unicode: "🐾",
  category: "n",
  emoji_order: "1386"
}, {
  name: "turkey",
  unicode: "🦃",
  category: "n",
  emoji_order: "1387"
}, {
  name: "chicken",
  unicode: "🐔",
  category: "n",
  emoji_order: "1388"
}, {
  name: "rooster",
  unicode: "🐓",
  category: "n",
  emoji_order: "1389"
}, {
  name: "hatching_chick",
  unicode: "🐣",
  category: "n",
  emoji_order: "1390"
}, {
  name: "baby_chick",
  unicode: "🐤",
  category: "n",
  emoji_order: "1391"
}, {
  name: "hatched_chick",
  unicode: "🐥",
  category: "n",
  emoji_order: "1392"
}, {
  name: "bird",
  unicode: "🐦",
  category: "n",
  emoji_order: "1393"
}, {
  name: "penguin",
  unicode: "🐧",
  category: "n",
  emoji_order: "1394"
}, {
  name: "dove_of_peace",
  unicode: "🕊",
  category: "n",
  emoji_order: "1395"
}, {
  name: "eagle",
  unicode: "🦅",
  category: "n",
  emoji_order: "1396"
}, {
  name: "duck",
  unicode: "🦆",
  category: "n",
  emoji_order: "1397"
}, {
  name: "owl",
  unicode: "🦉",
  category: "n",
  emoji_order: "1398"
}, {
  name: "frog",
  unicode: "🐸",
  category: "n",
  emoji_order: "1399"
}, {
  name: "crocodile",
  unicode: "🐊",
  category: "n",
  emoji_order: "1400"
}, {
  name: "turtle",
  unicode: "🐢",
  category: "n",
  emoji_order: "1401"
}, {
  name: "lizard",
  unicode: "🦎",
  category: "n",
  emoji_order: "1402"
}, {
  name: "snake",
  unicode: "🐍",
  category: "n",
  emoji_order: "1403"
}, {
  name: "dragon_face",
  unicode: "🐲",
  category: "n",
  emoji_order: "1404"
}, {
  name: "dragon",
  unicode: "🐉",
  category: "n",
  emoji_order: "1405"
}, {
  name: "whale",
  unicode: "🐳",
  category: "n",
  emoji_order: "1406"
}, {
  name: "whale2",
  unicode: "🐋",
  category: "n",
  emoji_order: "1407"
}, {
  name: "dolphin",
  unicode: "🐬",
  category: "n",
  emoji_order: "1408"
}, {
  name: "fish",
  unicode: "🐟",
  category: "n",
  emoji_order: "1409"
}, {
  name: "tropical_fish",
  unicode: "🐠",
  category: "n",
  emoji_order: "1410"
}, {
  name: "blowfish",
  unicode: "🐡",
  category: "n",
  emoji_order: "1411"
}, {
  name: "shark",
  unicode: "🦈",
  category: "n",
  emoji_order: "1412"
}, {
  name: "octopus",
  unicode: "🐙",
  category: "n",
  emoji_order: "1413"
}, {
  name: "shell",
  unicode: "🐚",
  category: "n",
  emoji_order: "1414"
}, {
  name: "crab",
  unicode: "🦀",
  category: "n",
  emoji_order: "1415"
}, {
  name: "shrimp",
  unicode: "🦐",
  category: "n",
  emoji_order: "1416"
}, {
  name: "squid",
  unicode: "🦑",
  category: "n",
  emoji_order: "1417"
}, {
  name: "butterfly",
  unicode: "🦋",
  category: "n",
  emoji_order: "1418"
}, {
  name: "snail",
  unicode: "🐌",
  category: "n",
  emoji_order: "1419"
}, {
  name: "bug",
  unicode: "🐛",
  category: "n",
  emoji_order: "1420"
}, {
  name: "ant",
  unicode: "🐜",
  category: "n",
  emoji_order: "1421"
}, {
  name: "bee",
  unicode: "🐝",
  category: "n",
  emoji_order: "1422"
}, {
  name: "beetle",
  unicode: "🐞",
  category: "n",
  emoji_order: "1423"
}, {
  name: "spider",
  unicode: "🕷",
  category: "n",
  emoji_order: "1424"
}, {
  name: "spider_web",
  unicode: "🕸",
  category: "n",
  emoji_order: "1425"
}, {
  name: "scorpion",
  unicode: "🦂",
  category: "n",
  emoji_order: "1426"
}, {
  name: "bouquet",
  unicode: "💐",
  category: "n",
  emoji_order: "1427"
}, {
  name: "cherry_blossom",
  unicode: "🌸",
  category: "n",
  emoji_order: "1428"
}, {
  name: "white_flower",
  unicode: "💮",
  category: "s",
  emoji_order: "1429"
}, {
  name: "rosette",
  unicode: "🏵",
  category: "n",
  emoji_order: "1430"
}, {
  name: "rose",
  unicode: "🌹",
  category: "n",
  emoji_order: "1431"
}, {
  name: "wilted_rose",
  unicode: "🥀",
  category: "n",
  emoji_order: "1432"
}, {
  name: "hibiscus",
  unicode: "🌺",
  category: "n",
  emoji_order: "1433"
}, {
  name: "sunflower",
  unicode: "🌻",
  category: "n",
  emoji_order: "1434"
}, {
  name: "blossom",
  unicode: "🌼",
  category: "n",
  emoji_order: "1435"
}, {
  name: "tulip",
  unicode: "🌷",
  category: "n",
  emoji_order: "1436"
}, {
  name: "seedling",
  unicode: "🌱",
  category: "n",
  emoji_order: "1437"
}, {
  name: "evergreen_tree",
  unicode: "🌲",
  category: "n",
  emoji_order: "1438"
}, {
  name: "deciduous_tree",
  unicode: "🌳",
  category: "n",
  emoji_order: "1439"
}, {
  name: "palm_tree",
  unicode: "🌴",
  category: "n",
  emoji_order: "1440"
}, {
  name: "cactus",
  unicode: "🌵",
  category: "n",
  emoji_order: "1441"
}, {
  name: "ear_of_rice",
  unicode: "🌾",
  category: "n",
  emoji_order: "1442"
}, {
  name: "herb",
  unicode: "🌿",
  category: "n",
  emoji_order: "1443"
}, {
  name: "shamrock",
  unicode: "☘",
  category: "n",
  emoji_order: "1444"
}, {
  name: "four_leaf_clover",
  unicode: "🍀",
  category: "n",
  emoji_order: "1445"
}, {
  name: "maple_leaf",
  unicode: "🍁",
  category: "n",
  emoji_order: "1446"
}, {
  name: "fallen_leaf",
  unicode: "🍂",
  category: "n",
  emoji_order: "1447"
}, {
  name: "leaves",
  unicode: "🍃",
  category: "n",
  emoji_order: "1448"
}, {
  name: "grapes",
  unicode: "🍇",
  category: "d",
  emoji_order: "1449"
}, {
  name: "melon",
  unicode: "🍈",
  category: "d",
  emoji_order: "1450"
}, {
  name: "watermelon",
  unicode: "🍉",
  category: "d",
  emoji_order: "1451"
}, {
  name: "tangerine",
  unicode: "🍊",
  category: "d",
  emoji_order: "1452"
}, {
  name: "lemon",
  unicode: "🍋",
  category: "d",
  emoji_order: "1453"
}, {
  name: "banana",
  unicode: "🍌",
  category: "d",
  emoji_order: "1454"
}, {
  name: "pineapple",
  unicode: "🍍",
  category: "d",
  emoji_order: "1455"
}, {
  name: "apple",
  unicode: "🍎",
  category: "d",
  emoji_order: "1456"
}, {
  name: "green_apple",
  unicode: "🍏",
  category: "d",
  emoji_order: "1457"
}, {
  name: "pear",
  unicode: "🍐",
  category: "d",
  emoji_order: "1458"
}, {
  name: "peach",
  unicode: "🍑",
  category: "d",
  emoji_order: "1459"
}, {
  name: "cherries",
  unicode: "🍒",
  category: "d",
  emoji_order: "1460"
}, {
  name: "strawberry",
  unicode: "🍓",
  category: "d",
  emoji_order: "1461"
}, {
  name: "kiwi",
  unicode: "🥝",
  category: "d",
  emoji_order: "1462"
}, {
  name: "tomato",
  unicode: "🍅",
  category: "d",
  emoji_order: "1463"
}, {
  name: "avocado",
  unicode: "🥑",
  category: "d",
  emoji_order: "1464"
}, {
  name: "eggplant",
  unicode: "🍆",
  category: "d",
  emoji_order: "1465"
}, {
  name: "potato",
  unicode: "🥔",
  category: "d",
  emoji_order: "1466"
}, {
  name: "carrot",
  unicode: "🥕",
  category: "d",
  emoji_order: "1467"
}, {
  name: "corn",
  unicode: "🌽",
  category: "d",
  emoji_order: "1468"
}, {
  name: "hot_pepper",
  unicode: "🌶",
  category: "d",
  emoji_order: "1469"
}, {
  name: "cucumber",
  unicode: "🥒",
  category: "d",
  emoji_order: "1470"
}, {
  name: "mushroom",
  unicode: "🍄",
  category: "n",
  emoji_order: "1471"
}, {
  name: "peanuts",
  unicode: "🥜",
  category: "d",
  emoji_order: "1472"
}, {
  name: "chestnut",
  unicode: "🌰",
  category: "n",
  emoji_order: "1473"
}, {
  name: "bread",
  unicode: "🍞",
  category: "d",
  emoji_order: "1474"
}, {
  name: "croissant",
  unicode: "🥐",
  category: "d",
  emoji_order: "1475"
}, {
  name: "french_bread",
  unicode: "🥖",
  category: "d",
  emoji_order: "1476"
}, {
  name: "pancakes",
  unicode: "🥞",
  category: "d",
  emoji_order: "1477"
}, {
  name: "cheese_wedge",
  unicode: "🧀",
  category: "d",
  emoji_order: "1478"
}, {
  name: "meat_on_bone",
  unicode: "🍖",
  category: "d",
  emoji_order: "1479"
}, {
  name: "poultry_leg",
  unicode: "🍗",
  category: "d",
  emoji_order: "1480"
}, {
  name: "bacon",
  unicode: "🥓",
  category: "d",
  emoji_order: "1481"
}, {
  name: "hamburger",
  unicode: "🍔",
  category: "d",
  emoji_order: "1482"
}, {
  name: "fries",
  unicode: "🍟",
  category: "d",
  emoji_order: "1483"
}, {
  name: "pizza",
  unicode: "🍕",
  category: "d",
  emoji_order: "1484"
}, {
  name: "hotdog",
  unicode: "🌭",
  category: "d",
  emoji_order: "1485"
}, {
  name: "taco",
  unicode: "🌮",
  category: "d",
  emoji_order: "1486"
}, {
  name: "burrito",
  unicode: "🌯",
  category: "d",
  emoji_order: "1487"
}, {
  name: "stuffed_flatbread",
  unicode: "🥙",
  category: "d",
  emoji_order: "1488"
}, {
  name: "egg",
  unicode: "🥚",
  category: "d",
  emoji_order: "1489"
}, {
  name: "cooking",
  unicode: "🍳",
  category: "d",
  emoji_order: "1490"
}, {
  name: "shallow_pan_of_f",
  unicode: "🥘",
  category: "d",
  emoji_order: "1491"
}, {
  name: "stew",
  unicode: "🍲",
  category: "d",
  emoji_order: "1492"
}, {
  name: "salad",
  unicode: "🥗",
  category: "d",
  emoji_order: "1493"
}, {
  name: "popcorn",
  unicode: "🍿",
  category: "d",
  emoji_order: "1494"
}, {
  name: "bento",
  unicode: "🍱",
  category: "d",
  emoji_order: "1495"
}, {
  name: "rice_cracker",
  unicode: "🍘",
  category: "d",
  emoji_order: "1496"
}, {
  name: "rice_ball",
  unicode: "🍙",
  category: "d",
  emoji_order: "1497"
}, {
  name: "rice",
  unicode: "🍚",
  category: "d",
  emoji_order: "1498"
}, {
  name: "curry",
  unicode: "🍛",
  category: "d",
  emoji_order: "1499"
}, {
  name: "ramen",
  unicode: "🍜",
  category: "d",
  emoji_order: "1500"
}, {
  name: "spaghetti",
  unicode: "🍝",
  category: "d",
  emoji_order: "1501"
}, {
  name: "sweet_potato",
  unicode: "🍠",
  category: "d",
  emoji_order: "1502"
}, {
  name: "oden",
  unicode: "🍢",
  category: "d",
  emoji_order: "1503"
}, {
  name: "sushi",
  unicode: "🍣",
  category: "d",
  emoji_order: "1504"
}, {
  name: "fried_shrimp",
  unicode: "🍤",
  category: "d",
  emoji_order: "1505"
}, {
  name: "fish_cake",
  unicode: "🍥",
  category: "d",
  emoji_order: "1506"
}, {
  name: "dango",
  unicode: "🍡",
  category: "d",
  emoji_order: "1507"
}, {
  name: "icecream",
  unicode: "🍦",
  category: "d",
  emoji_order: "1508"
}, {
  name: "shaved_ice",
  unicode: "🍧",
  category: "d",
  emoji_order: "1509"
}, {
  name: "ice_cream",
  unicode: "🍨",
  category: "d",
  emoji_order: "1510"
}, {
  name: "doughnut",
  unicode: "🍩",
  category: "d",
  emoji_order: "1511"
}, {
  name: "cookie",
  unicode: "🍪",
  category: "d",
  emoji_order: "1512"
}, {
  name: "birthday",
  unicode: "🎂",
  category: "d",
  emoji_order: "1513"
}, {
  name: "cake",
  unicode: "🍰",
  category: "d",
  emoji_order: "1514"
}, {
  name: "chocolate_bar",
  unicode: "🍫",
  category: "d",
  emoji_order: "1515"
}, {
  name: "candy",
  unicode: "🍬",
  category: "d",
  emoji_order: "1516"
}, {
  name: "lollipop",
  unicode: "🍭",
  category: "d",
  emoji_order: "1517"
}, {
  name: "custard",
  unicode: "🍮",
  category: "d",
  emoji_order: "1518"
}, {
  name: "honey_pot",
  unicode: "🍯",
  category: "d",
  emoji_order: "1519"
}, {
  name: "baby_bottle",
  unicode: "🍼",
  category: "d",
  emoji_order: "1520"
}, {
  name: "milk",
  unicode: "🥛",
  category: "d",
  emoji_order: "1521"
}, {
  name: "coffee",
  unicode: "☕",
  category: "d",
  emoji_order: "1522"
}, {
  name: "tea",
  unicode: "🍵",
  category: "d",
  emoji_order: "1523"
}, {
  name: "sake",
  unicode: "🍶",
  category: "d",
  emoji_order: "1524"
}, {
  name: "champagne",
  unicode: "🍾",
  category: "d",
  emoji_order: "1525"
}, {
  name: "wine_glass",
  unicode: "🍷",
  category: "d",
  emoji_order: "1526"
}, {
  name: "cocktail",
  unicode: "🍸",
  category: "d",
  emoji_order: "1527"
}, {
  name: "tropical_drink",
  unicode: "🍹",
  category: "d",
  emoji_order: "1528"
}, {
  name: "beer",
  unicode: "🍺",
  category: "d",
  emoji_order: "1529"
}, {
  name: "beers",
  unicode: "🍻",
  category: "d",
  emoji_order: "1530"
}, {
  name: "champagne_glass",
  unicode: "🥂",
  category: "d",
  emoji_order: "1531"
}, {
  name: "tumbler_glass",
  unicode: "🥃",
  category: "d",
  emoji_order: "1532"
}, {
  name: "knife_fork_plate",
  unicode: "🍽",
  category: "d",
  emoji_order: "1533"
}, {
  name: "fork_and_knife",
  unicode: "🍴",
  category: "d",
  emoji_order: "1534"
}, {
  name: "spoon",
  unicode: "🥄",
  category: "d",
  emoji_order: "1535"
}, {
  name: "knife",
  unicode: "🔪",
  category: "o",
  emoji_order: "1536"
}, {
  name: "amphora",
  unicode: "🏺",
  category: "o",
  emoji_order: "1537"
}, {
  name: "earth_africa",
  unicode: "🌍",
  category: "n",
  emoji_order: "1538"
}, {
  name: "earth_americas",
  unicode: "🌎",
  category: "n",
  emoji_order: "1539"
}, {
  name: "earth_asia",
  unicode: "🌏",
  category: "n",
  emoji_order: "1540"
}, {
  name: "globe_with_meridians",
  unicode: "🌐",
  category: "s",
  emoji_order: "1541"
}, {
  name: "world_map",
  unicode: "🗺",
  category: "o",
  emoji_order: "1542"
}, {
  name: "japan",
  unicode: "🗾",
  category: "t",
  emoji_order: "1543"
}, {
  name: "snow_capped_mountain",
  unicode: "🏔",
  category: "t",
  emoji_order: "1544"
}, {
  name: "mountain",
  unicode: "⛰",
  category: "t",
  emoji_order: "1545"
}, {
  name: "volcano",
  unicode: "🌋",
  category: "t",
  emoji_order: "1546"
}, {
  name: "mount_fuji",
  unicode: "🗻",
  category: "t",
  emoji_order: "1547"
}, {
  name: "camping",
  unicode: "🏕",
  category: "t",
  emoji_order: "1548"
}, {
  name: "beach_with_umbrella",
  unicode: "🏖",
  category: "t",
  emoji_order: "1549"
}, {
  name: "desert",
  unicode: "🏜",
  category: "t",
  emoji_order: "1550"
}, {
  name: "desert_island",
  unicode: "🏝",
  category: "t",
  emoji_order: "1551"
}, {
  name: "national_park",
  unicode: "🏞",
  category: "t",
  emoji_order: "1552"
}, {
  name: "stadium",
  unicode: "🏟",
  category: "t",
  emoji_order: "1553"
}, {
  name: "classical_building",
  unicode: "🏛",
  category: "t",
  emoji_order: "1554"
}, {
  name: "building_construction",
  unicode: "🏗",
  category: "t",
  emoji_order: "1555"
}, {
  name: "house_buildings",
  unicode: "🏘",
  category: "t",
  emoji_order: "1556"
}, {
  name: "cityscape",
  unicode: "🏙",
  category: "t",
  emoji_order: "1557"
}, {
  name: "derelict_house_building",
  unicode: "🏚",
  category: "t",
  emoji_order: "1558"
}, {
  name: "house",
  unicode: "🏠",
  category: "t",
  emoji_order: "1559"
}, {
  name: "house_with_garden",
  unicode: "🏡",
  category: "t",
  emoji_order: "1560"
}, {
  name: "office",
  unicode: "🏢",
  category: "t",
  emoji_order: "1561"
}, {
  name: "post_office",
  unicode: "🏣",
  category: "t",
  emoji_order: "1562"
}, {
  name: "european_post_office",
  unicode: "🏤",
  category: "t",
  emoji_order: "1563"
}, {
  name: "hospital",
  unicode: "🏥",
  category: "t",
  emoji_order: "1564"
}, {
  name: "bank",
  unicode: "🏦",
  category: "t",
  emoji_order: "1565"
}, {
  name: "hotel",
  unicode: "🏨",
  category: "t",
  emoji_order: "1566"
}, {
  name: "love_hotel",
  unicode: "🏩",
  category: "t",
  emoji_order: "1567"
}, {
  name: "convenience_store",
  unicode: "🏪",
  category: "t",
  emoji_order: "1568"
}, {
  name: "school",
  unicode: "🏫",
  category: "t",
  emoji_order: "1569"
}, {
  name: "department_store",
  unicode: "🏬",
  category: "t",
  emoji_order: "1570"
}, {
  name: "factory",
  unicode: "🏭",
  category: "t",
  emoji_order: "1571"
}, {
  name: "japanese_castle",
  unicode: "🏯",
  category: "t",
  emoji_order: "1572"
}, {
  name: "european_castle",
  unicode: "🏰",
  category: "t",
  emoji_order: "1573"
}, {
  name: "wedding",
  unicode: "💒",
  category: "t",
  emoji_order: "1574"
}, {
  name: "tokyo_tower",
  unicode: "🗼",
  category: "t",
  emoji_order: "1575"
}, {
  name: "statue_of_liberty",
  unicode: "🗽",
  category: "t",
  emoji_order: "1576"
}, {
  name: "church",
  unicode: "⛪",
  category: "t",
  emoji_order: "1577"
}, {
  name: "mosque",
  unicode: "🕌",
  category: "t",
  emoji_order: "1578"
}, {
  name: "synagogue",
  unicode: "🕍",
  category: "t",
  emoji_order: "1579"
}, {
  name: "shinto_shrine",
  unicode: "⛩",
  category: "t",
  emoji_order: "1580"
}, {
  name: "kaaba",
  unicode: "🕋",
  category: "t",
  emoji_order: "1581"
}, {
  name: "fountain",
  unicode: "⛲",
  category: "t",
  emoji_order: "1582"
}, {
  name: "tent",
  unicode: "⛺",
  category: "t",
  emoji_order: "1583"
}, {
  name: "foggy",
  unicode: "🌁",
  category: "t",
  emoji_order: "1584"
}, {
  name: "night_with_stars",
  unicode: "🌃",
  category: "t",
  emoji_order: "1585"
}, {
  name: "sunrise_over_mountains",
  unicode: "🌄",
  category: "t",
  emoji_order: "1586"
}, {
  name: "sunrise",
  unicode: "🌅",
  category: "t",
  emoji_order: "1587"
}, {
  name: "city_dusk",
  unicode: "🌆",
  category: "t",
  emoji_order: "1588"
}, {
  name: "city_sunset",
  unicode: "🌇",
  category: "t",
  emoji_order: "1589"
}, {
  name: "bridge_at_night",
  unicode: "🌉",
  category: "t",
  emoji_order: "1590"
}, {
  name: "hotsprings",
  unicode: "♨",
  category: "s",
  emoji_order: "1591"
}, {
  name: "milky_way",
  unicode: "🌌",
  category: "t",
  emoji_order: "1592"
}, {
  name: "carousel_horse",
  unicode: "🎠",
  category: "t",
  emoji_order: "1593"
}, {
  name: "ferris_wheel",
  unicode: "🎡",
  category: "t",
  emoji_order: "1594"
}, {
  name: "roller_coaster",
  unicode: "🎢",
  category: "t",
  emoji_order: "1595"
}, {
  name: "barber",
  unicode: "💈",
  category: "o",
  emoji_order: "1596"
}, {
  name: "circus_tent",
  unicode: "🎪",
  category: "a",
  emoji_order: "1597"
}, {
  name: "performing_arts",
  unicode: "🎭",
  category: "a",
  emoji_order: "1598"
}, {
  name: "frame_with_picture",
  unicode: "🖼",
  category: "o",
  emoji_order: "1599"
}, {
  name: "art",
  unicode: "🎨",
  category: "a",
  emoji_order: "1600"
}, {
  name: "slot_machine",
  unicode: "🎰",
  category: "a",
  emoji_order: "1601"
}, {
  name: "steam_locomotive",
  unicode: "🚂",
  category: "t",
  emoji_order: "1602"
}, {
  name: "railway_car",
  unicode: "🚃",
  category: "t",
  emoji_order: "1603"
}, {
  name: "bullettrain_side",
  unicode: "🚄",
  category: "t",
  emoji_order: "1604"
}, {
  name: "bullettrain_front",
  unicode: "🚅",
  category: "t",
  emoji_order: "1605"
}, {
  name: "train2",
  unicode: "🚆",
  category: "t",
  emoji_order: "1606"
}, {
  name: "metro",
  unicode: "🚇",
  category: "t",
  emoji_order: "1607"
}, {
  name: "light_rail",
  unicode: "🚈",
  category: "t",
  emoji_order: "1608"
}, {
  name: "station",
  unicode: "🚉",
  category: "t",
  emoji_order: "1609"
}, {
  name: "tram",
  unicode: "🚊",
  category: "t",
  emoji_order: "1610"
}, {
  name: "monorail",
  unicode: "🚝",
  category: "t",
  emoji_order: "1611"
}, {
  name: "mountain_railway",
  unicode: "🚞",
  category: "t",
  emoji_order: "1612"
}, {
  name: "train",
  unicode: "🚋",
  category: "t",
  emoji_order: "1613"
}, {
  name: "bus",
  unicode: "🚌",
  category: "t",
  emoji_order: "1614"
}, {
  name: "oncoming_bus",
  unicode: "🚍",
  category: "t",
  emoji_order: "1615"
}, {
  name: "trolleybus",
  unicode: "🚎",
  category: "t",
  emoji_order: "1616"
}, {
  name: "minibus",
  unicode: "🚐",
  category: "t",
  emoji_order: "1617"
}, {
  name: "ambulance",
  unicode: "🚑",
  category: "t",
  emoji_order: "1618"
}, {
  name: "fire_engine",
  unicode: "🚒",
  category: "t",
  emoji_order: "1619"
}, {
  name: "police_car",
  unicode: "🚓",
  category: "t",
  emoji_order: "1620"
}, {
  name: "oncoming_police_car",
  unicode: "🚔",
  category: "t",
  emoji_order: "1621"
}, {
  name: "taxi",
  unicode: "🚕",
  category: "t",
  emoji_order: "1622"
}, {
  name: "oncoming_taxi",
  unicode: "🚖",
  category: "t",
  emoji_order: "1623"
}, {
  name: "car",
  unicode: "🚗",
  category: "t",
  emoji_order: "1624"
}, {
  name: "oncoming_automobile",
  unicode: "🚘",
  category: "t",
  emoji_order: "1625"
}, {
  name: "blue_car",
  unicode: "🚙",
  category: "t",
  emoji_order: "1626"
}, {
  name: "truck",
  unicode: "🚚",
  category: "t",
  emoji_order: "1627"
}, {
  name: "articulated_lorry",
  unicode: "🚛",
  category: "t",
  emoji_order: "1628"
}, {
  name: "tractor",
  unicode: "🚜",
  category: "t",
  emoji_order: "1629"
}, {
  name: "bike",
  unicode: "🚲",
  category: "t",
  emoji_order: "1630"
}, {
  name: "scooter",
  unicode: "🛴",
  category: "t",
  emoji_order: "1631"
}, {
  name: "motor_scooter",
  unicode: "🛵",
  category: "t",
  emoji_order: "1632"
}, {
  name: "busstop",
  unicode: "🚏",
  category: "t",
  emoji_order: "1633"
}, {
  name: "motorway",
  unicode: "🛣",
  category: "t",
  emoji_order: "1634"
}, {
  name: "railway_track",
  unicode: "🛤",
  category: "t",
  emoji_order: "1635"
}, {
  name: "fuelpump",
  unicode: "⛽",
  category: "t",
  emoji_order: "1636"
}, {
  name: "rotating_light",
  unicode: "🚨",
  category: "t",
  emoji_order: "1637"
}, {
  name: "traffic_light",
  unicode: "🚥",
  category: "t",
  emoji_order: "1638"
}, {
  name: "vertical_traffic_light",
  unicode: "🚦",
  category: "t",
  emoji_order: "1639"
}, {
  name: "construction",
  unicode: "🚧",
  category: "t",
  emoji_order: "1640"
}, {
  name: "octagonal_sign",
  unicode: "🛑",
  category: "s",
  emoji_order: "1641"
}, {
  name: "anchor",
  unicode: "⚓",
  category: "t",
  emoji_order: "1642"
}, {
  name: "boat",
  unicode: "⛵",
  category: "t",
  emoji_order: "1643"
}, {
  name: "canoe",
  unicode: "🛶",
  category: "t",
  emoji_order: "1644"
}, {
  name: "speedboat",
  unicode: "🚤",
  category: "t",
  emoji_order: "1645"
}, {
  name: "passenger_ship",
  unicode: "🛳",
  category: "t",
  emoji_order: "1646"
}, {
  name: "ferry",
  unicode: "⛴",
  category: "t",
  emoji_order: "1647"
}, {
  name: "motor_boat",
  unicode: "🛥",
  category: "t",
  emoji_order: "1648"
}, {
  name: "ship",
  unicode: "🚢",
  category: "t",
  emoji_order: "1649"
}, {
  name: "airplane",
  unicode: "✈",
  category: "t",
  emoji_order: "1650"
}, {
  name: "small_airplane",
  unicode: "🛩",
  category: "t",
  emoji_order: "1651"
}, {
  name: "airplane_departure",
  unicode: "🛫",
  category: "t",
  emoji_order: "1652"
}, {
  name: "airplane_arriving",
  unicode: "🛬",
  category: "t",
  emoji_order: "1653"
}, {
  name: "seat",
  unicode: "💺",
  category: "t",
  emoji_order: "1654"
}, {
  name: "helicopter",
  unicode: "🚁",
  category: "t",
  emoji_order: "1655"
}, {
  name: "suspension_railway",
  unicode: "🚟",
  category: "t",
  emoji_order: "1656"
}, {
  name: "mountain_cableway",
  unicode: "🚠",
  category: "t",
  emoji_order: "1657"
}, {
  name: "aerial_tramway",
  unicode: "🚡",
  category: "t",
  emoji_order: "1658"
}, {
  name: "rocket",
  unicode: "🚀",
  category: "t",
  emoji_order: "1659"
}, {
  name: "satellite",
  unicode: "🛰",
  category: "t",
  emoji_order: "1660"
}, {
  name: "bellhop_bell",
  unicode: "🛎",
  category: "o",
  emoji_order: "1661"
}, {
  name: "door",
  unicode: "🚪",
  category: "o",
  emoji_order: "1662"
}, {
  name: "sleeping_accommodation",
  unicode: "🛌",
  category: "o",
  emoji_order: "1663"
}, {
  name: "bed",
  unicode: "🛏",
  category: "o",
  emoji_order: "1669"
}, {
  name: "couch_and_lamp",
  unicode: "🛋",
  category: "o",
  emoji_order: "1670"
}, {
  name: "toilet",
  unicode: "🚽",
  category: "o",
  emoji_order: "1671"
}, {
  name: "shower",
  unicode: "🚿",
  category: "o",
  emoji_order: "1672"
}, {
  name: "bath",
  unicode: "🛀",
  category: "a",
  emoji_order: "1673"
}, {
  name: "bathtub",
  unicode: "🛁",
  category: "o",
  emoji_order: "1679"
}, {
  name: "hourglass",
  unicode: "⌛",
  category: "o",
  emoji_order: "1680"
}, {
  name: "hourglass_flowing_sand",
  unicode: "⏳",
  category: "o",
  emoji_order: "1681"
}, {
  name: "watch",
  unicode: "⌚",
  category: "o",
  emoji_order: "1682"
}, {
  name: "alarm_clock",
  unicode: "⏰",
  category: "o",
  emoji_order: "1683"
}, {
  name: "stopwatch",
  unicode: "⏱",
  category: "o",
  emoji_order: "1684"
}, {
  name: "timer_clock",
  unicode: "⏲",
  category: "o",
  emoji_order: "1685"
}, {
  name: "mantelpiece_clock",
  unicode: "🕰",
  category: "o",
  emoji_order: "1686"
}, {
  name: "clock12",
  unicode: "🕛",
  category: "s",
  emoji_order: "1687"
}, {
  name: "clock1230",
  unicode: "🕧",
  category: "s",
  emoji_order: "1688"
}, {
  name: "clock1",
  unicode: "🕐",
  category: "s",
  emoji_order: "1689"
}, {
  name: "clock130",
  unicode: "🕜",
  category: "s",
  emoji_order: "1690"
}, {
  name: "clock2",
  unicode: "🕑",
  category: "s",
  emoji_order: "1691"
}, {
  name: "clock230",
  unicode: "🕝",
  category: "s",
  emoji_order: "1692"
}, {
  name: "clock3",
  unicode: "🕒",
  category: "s",
  emoji_order: "1693"
}, {
  name: "clock330",
  unicode: "🕞",
  category: "s",
  emoji_order: "1694"
}, {
  name: "clock4",
  unicode: "🕓",
  category: "s",
  emoji_order: "1695"
}, {
  name: "clock430",
  unicode: "🕟",
  category: "s",
  emoji_order: "1696"
}, {
  name: "clock5",
  unicode: "🕔",
  category: "s",
  emoji_order: "1697"
}, {
  name: "clock530",
  unicode: "🕠",
  category: "s",
  emoji_order: "1698"
}, {
  name: "clock6",
  unicode: "🕕",
  category: "s",
  emoji_order: "1699"
}, {
  name: "clock630",
  unicode: "🕡",
  category: "s",
  emoji_order: "1700"
}, {
  name: "clock7",
  unicode: "🕖",
  category: "s",
  emoji_order: "1701"
}, {
  name: "clock730",
  unicode: "🕢",
  category: "s",
  emoji_order: "1702"
}, {
  name: "clock8",
  unicode: "🕗",
  category: "s",
  emoji_order: "1703"
}, {
  name: "clock830",
  unicode: "🕣",
  category: "s",
  emoji_order: "1704"
}, {
  name: "clock9",
  unicode: "🕘",
  category: "s",
  emoji_order: "1705"
}, {
  name: "clock930",
  unicode: "🕤",
  category: "s",
  emoji_order: "1706"
}, {
  name: "clock10",
  unicode: "🕙",
  category: "s",
  emoji_order: "1707"
}, {
  name: "clock1030",
  unicode: "🕥",
  category: "s",
  emoji_order: "1708"
}, {
  name: "clock11",
  unicode: "🕚",
  category: "s",
  emoji_order: "1709"
}, {
  name: "clock1130",
  unicode: "🕦",
  category: "s",
  emoji_order: "1710"
}, {
  name: "new_moon",
  unicode: "🌑",
  category: "n",
  emoji_order: "1711"
}, {
  name: "waxing_crescent_moon",
  unicode: "🌒",
  category: "n",
  emoji_order: "1712"
}, {
  name: "first_quarter_moon",
  unicode: "🌓",
  category: "n",
  emoji_order: "1713"
}, {
  name: "waxing_gibbous_moon",
  unicode: "🌔",
  category: "n",
  emoji_order: "1714"
}, {
  name: "full_moon",
  unicode: "🌕",
  category: "n",
  emoji_order: "1715"
}, {
  name: "waning_gibbous_moon",
  unicode: "🌖",
  category: "n",
  emoji_order: "1716"
}, {
  name: "last_quarter_moon",
  unicode: "🌗",
  category: "n",
  emoji_order: "1717"
}, {
  name: "waning_crescent_moon",
  unicode: "🌘",
  category: "n",
  emoji_order: "1718"
}, {
  name: "crescent_moon",
  unicode: "🌙",
  category: "n",
  emoji_order: "1719"
}, {
  name: "new_moon_with_face",
  unicode: "🌚",
  category: "n",
  emoji_order: "1720"
}, {
  name: "first_quarter_moon_with_face",
  unicode: "🌛",
  category: "n",
  emoji_order: "1721"
}, {
  name: "last_quarter_moon_with_face",
  unicode: "🌜",
  category: "n",
  emoji_order: "1722"
}, {
  name: "thermometer",
  unicode: "🌡",
  category: "o",
  emoji_order: "1723"
}, {
  name: "sunny",
  unicode: "☀",
  category: "n",
  emoji_order: "1724"
}, {
  name: "full_moon_with_face",
  unicode: "🌝",
  category: "n",
  emoji_order: "1725"
}, {
  name: "sun_with_face",
  unicode: "🌞",
  category: "n",
  emoji_order: "1726"
}, {
  name: "star",
  unicode: "⭐",
  category: "n",
  emoji_order: "1727"
}, {
  name: "star2",
  unicode: "🌟",
  category: "n",
  emoji_order: "1728"
}, {
  name: "stars",
  unicode: "🌠",
  category: "t",
  emoji_order: "1729"
}, {
  name: "cloud",
  unicode: "☁",
  category: "n",
  emoji_order: "1730"
}, {
  name: "partly_sunny",
  unicode: "⛅",
  category: "n",
  emoji_order: "1731"
}, {
  name: "thunder_cloud_and_rain",
  unicode: "⛈",
  category: "n",
  emoji_order: "1732"
}, {
  name: "white_sun_small_cloud",
  unicode: "🌤",
  category: "n",
  emoji_order: "1733"
}, {
  name: "white_sun_cloud",
  unicode: "🌥",
  category: "n",
  emoji_order: "1734"
}, {
  name: "white_sun_rain_cloud",
  unicode: "🌦",
  category: "n",
  emoji_order: "1735"
}, {
  name: "rain_cloud",
  unicode: "🌧",
  category: "n",
  emoji_order: "1736"
}, {
  name: "snow_cloud",
  unicode: "🌨",
  category: "n",
  emoji_order: "1737"
}, {
  name: "cloud_lightning",
  unicode: "🌩",
  category: "n",
  emoji_order: "1738"
}, {
  name: "cloud_tornado",
  unicode: "🌪",
  category: "n",
  emoji_order: "1739"
}, {
  name: "fog",
  unicode: "🌫",
  category: "n",
  emoji_order: "1740"
}, {
  name: "wind_blowing_face",
  unicode: "🌬",
  category: "n",
  emoji_order: "1741"
}, {
  name: "cyclone",
  unicode: "🌀",
  category: "s",
  emoji_order: "1742"
}, {
  name: "rainbow",
  unicode: "🌈",
  category: "t",
  emoji_order: "1743"
}, {
  name: "closed_umbrella",
  unicode: "🌂",
  category: "p",
  emoji_order: "1744"
}, {
  name: "umbrella",
  unicode: "☂",
  category: "n",
  emoji_order: "1745"
}, {
  name: "umbrella_with_rain_drops",
  unicode: "☔",
  category: "n",
  emoji_order: "1746"
}, {
  name: "beach_umbrella",
  unicode: "⛱",
  category: "o",
  emoji_order: "1747"
}, {
  name: "zap",
  unicode: "⚡",
  category: "n",
  emoji_order: "1748"
}, {
  name: "snowflake",
  unicode: "❄",
  category: "n",
  emoji_order: "1749"
}, {
  name: "snowman",
  unicode: "☃",
  category: "n",
  emoji_order: "1750"
}, {
  name: "snowman_without_snow",
  unicode: "⛄",
  category: "n",
  emoji_order: "1751"
}, {
  name: "comet",
  unicode: "☄",
  category: "n",
  emoji_order: "1752"
}, {
  name: "fire",
  unicode: "🔥",
  category: "n",
  emoji_order: "1753"
}, {
  name: "droplet",
  unicode: "💧",
  category: "n",
  emoji_order: "1754"
}, {
  name: "ocean",
  unicode: "🌊",
  category: "n",
  emoji_order: "1755"
}, {
  name: "jack_o_lantern",
  unicode: "🎃",
  category: "n",
  emoji_order: "1756"
}, {
  name: "christmas_tree",
  unicode: "🎄",
  category: "n",
  emoji_order: "1757"
}, {
  name: "fireworks",
  unicode: "🎆",
  category: "t",
  emoji_order: "1758"
}, {
  name: "sparkler",
  unicode: "🎇",
  category: "t",
  emoji_order: "1759"
}, {
  name: "sparkles",
  unicode: "✨",
  category: "n",
  emoji_order: "1760"
}, {
  name: "balloon",
  unicode: "🎈",
  category: "o",
  emoji_order: "1761"
}, {
  name: "tada",
  unicode: "🎉",
  category: "o",
  emoji_order: "1762"
}, {
  name: "confetti_ball",
  unicode: "🎊",
  category: "o",
  emoji_order: "1763"
}, {
  name: "tanabata_tree",
  unicode: "🎋",
  category: "n",
  emoji_order: "1764"
}, {
  name: "bamboo",
  unicode: "🎍",
  category: "n",
  emoji_order: "1765"
}, {
  name: "dolls",
  unicode: "🎎",
  category: "o",
  emoji_order: "1766"
}, {
  name: "f",
  unicode: "🎏",
  category: "o",
  emoji_order: "1767"
}, {
  name: "wind_chime",
  unicode: "🎐",
  category: "o",
  emoji_order: "1768"
}, {
  name: "rice_scene",
  unicode: "🎑",
  category: "t",
  emoji_order: "1769"
}, {
  name: "ribbon",
  unicode: "🎀",
  category: "o",
  emoji_order: "1770"
}, {
  name: "gift",
  unicode: "🎁",
  category: "o",
  emoji_order: "1771"
}, {
  name: "reminder_ribbon",
  unicode: "🎗",
  category: "a",
  emoji_order: "1772"
}, {
  name: "admission_tickets",
  unicode: "🎟",
  category: "a",
  emoji_order: "1773"
}, {
  name: "ticket",
  unicode: "🎫",
  category: "a",
  emoji_order: "1774"
}, {
  name: "medal",
  unicode: "🎖",
  category: "a",
  emoji_order: "1775"
}, {
  name: "trophy",
  unicode: "🏆",
  category: "a",
  emoji_order: "1776"
}, {
  name: "sports_medal",
  unicode: "🏅",
  category: "a",
  emoji_order: "1777"
}, {
  name: "first_place",
  unicode: "🥇",
  category: "a",
  emoji_order: "1778"
}, {
  name: "second_place",
  unicode: "🥈",
  category: "a",
  emoji_order: "1779"
}, {
  name: "third_place",
  unicode: "🥉",
  category: "a",
  emoji_order: "1780"
}, {
  name: "soccer",
  unicode: "⚽",
  category: "a",
  emoji_order: "1781"
}, {
  name: "baseball",
  unicode: "⚾",
  category: "a",
  emoji_order: "1782"
}, {
  name: "basketball",
  unicode: "🏀",
  category: "a",
  emoji_order: "1783"
}, {
  name: "volleyball",
  unicode: "🏐",
  category: "a",
  emoji_order: "1784"
}, {
  name: "football",
  unicode: "🏈",
  category: "a",
  emoji_order: "1785"
}, {
  name: "rugby_football",
  unicode: "🏉",
  category: "a",
  emoji_order: "1786"
}, {
  name: "tennis",
  unicode: "🎾",
  category: "a",
  emoji_order: "1787"
}, {
  name: "8ball",
  unicode: "🎱",
  category: "a",
  emoji_order: "1788"
}, {
  name: "bowling",
  unicode: "🎳",
  category: "a",
  emoji_order: "1789"
}, {
  name: "cricket_bat_and_ball",
  unicode: "🏏",
  category: "a",
  emoji_order: "1790"
}, {
  name: "field_hockey_stick_and_ball",
  unicode: "🏑",
  category: "a",
  emoji_order: "1791"
}, {
  name: "ice_hockey_stick_and_puck",
  unicode: "🏒",
  category: "a",
  emoji_order: "1792"
}, {
  name: "table_tennis_paddle_and_ball",
  unicode: "🏓",
  category: "a",
  emoji_order: "1793"
}, {
  name: "badminton_racquet_and_shuttlecock",
  unicode: "🏸",
  category: "a",
  emoji_order: "1794"
}, {
  name: "boxing_glove",
  unicode: "🥊",
  category: "a",
  emoji_order: "1795"
}, {
  name: "martial_arts_uniform",
  unicode: "🥋",
  category: "a",
  emoji_order: "1796"
}, {
  name: "goal",
  unicode: "🥅",
  category: "a",
  emoji_order: "1797"
}, {
  name: "dart",
  unicode: "🎯",
  category: "a",
  emoji_order: "1798"
}, {
  name: "golf",
  unicode: "⛳",
  category: "a",
  emoji_order: "1799"
}, {
  name: "ice_skate",
  unicode: "⛸",
  category: "a",
  emoji_order: "1800"
}, {
  name: "fishing_pole_and_fish",
  unicode: "🎣",
  category: "a",
  emoji_order: "1801"
}, {
  name: "running_shirt_with_sash",
  unicode: "🎽",
  category: "a",
  emoji_order: "1802"
}, {
  name: "ski",
  unicode: "🎿",
  category: "a",
  emoji_order: "1803"
}, {
  name: "video_game",
  unicode: "🎮",
  category: "a",
  emoji_order: "1804"
}, {
  name: "joystick",
  unicode: "🕹",
  category: "o",
  emoji_order: "1805"
}, {
  name: "game_die",
  unicode: "🎲",
  category: "a",
  emoji_order: "1806"
}, {
  name: "spades",
  unicode: "♠",
  category: "s",
  emoji_order: "1807"
}, {
  name: "hearts",
  unicode: "♥",
  category: "s",
  emoji_order: "1808"
}, {
  name: "diamonds",
  unicode: "♦",
  category: "s",
  emoji_order: "1809"
}, {
  name: "clubs",
  unicode: "♣",
  category: "s",
  emoji_order: "1810"
}, {
  name: "black_joker",
  unicode: "🃏",
  category: "s",
  emoji_order: "1811"
}, {
  name: "mahjong",
  unicode: "🀄",
  category: "s",
  emoji_order: "1812"
}, {
  name: "flower_playing_cards",
  unicode: "🎴",
  category: "s",
  emoji_order: "1813"
}, {
  name: "mute",
  unicode: "🔇",
  category: "s",
  emoji_order: "1814"
}, {
  name: "speaker",
  unicode: "🔈",
  category: "s",
  emoji_order: "1815"
}, {
  name: "sound",
  unicode: "🔉",
  category: "s",
  emoji_order: "1816"
}, {
  name: "loud_sound",
  unicode: "🔊",
  category: "s",
  emoji_order: "1817"
}, {
  name: "loudspeaker",
  unicode: "📢",
  category: "s",
  emoji_order: "1818"
}, {
  name: "mega",
  unicode: "📣",
  category: "s",
  emoji_order: "1819"
}, {
  name: "postal_horn",
  unicode: "📯",
  category: "o",
  emoji_order: "1820"
}, {
  name: "bell",
  unicode: "🔔",
  category: "s",
  emoji_order: "1821"
}, {
  name: "no_bell",
  unicode: "🔕",
  category: "s",
  emoji_order: "1822"
}, {
  name: "musical_score",
  unicode: "🎼",
  category: "a",
  emoji_order: "1823"
}, {
  name: "musical_note",
  unicode: "🎵",
  category: "s",
  emoji_order: "1824"
}, {
  name: "notes",
  unicode: "🎶",
  category: "s",
  emoji_order: "1825"
}, {
  name: "studio_microphone",
  unicode: "🎙",
  category: "o",
  emoji_order: "1826"
}, {
  name: "level_slider",
  unicode: "🎚",
  category: "o",
  emoji_order: "1827"
}, {
  name: "control_knobs",
  unicode: "🎛",
  category: "o",
  emoji_order: "1828"
}, {
  name: "microphone",
  unicode: "🎤",
  category: "a",
  emoji_order: "1829"
}, {
  name: "headphones",
  unicode: "🎧",
  category: "a",
  emoji_order: "1830"
}, {
  name: "radio",
  unicode: "📻",
  category: "o",
  emoji_order: "1831"
}, {
  name: "saxophone",
  unicode: "🎷",
  category: "a",
  emoji_order: "1832"
}, {
  name: "guitar",
  unicode: "🎸",
  category: "a",
  emoji_order: "1833"
}, {
  name: "musical_keyboard",
  unicode: "🎹",
  category: "a",
  emoji_order: "1834"
}, {
  name: "trumpet",
  unicode: "🎺",
  category: "a",
  emoji_order: "1835"
}, {
  name: "violin",
  unicode: "🎻",
  category: "a",
  emoji_order: "1836"
}, {
  name: "drum",
  unicode: "🥁",
  category: "a",
  emoji_order: "1837"
}, {
  name: "iphone",
  unicode: "📱",
  category: "o",
  emoji_order: "1838"
}, {
  name: "calling",
  unicode: "📲",
  category: "o",
  emoji_order: "1839"
}, {
  name: "telephone",
  unicode: "☎",
  category: "o",
  emoji_order: "1840"
}, {
  name: "telephone_receiver",
  unicode: "📞",
  category: "o",
  emoji_order: "1841"
}, {
  name: "pager",
  unicode: "📟",
  category: "o",
  emoji_order: "1842"
}, {
  name: "fax",
  unicode: "📠",
  category: "o",
  emoji_order: "1843"
}, {
  name: "battery",
  unicode: "🔋",
  category: "o",
  emoji_order: "1844"
}, {
  name: "electric_plug",
  unicode: "🔌",
  category: "o",
  emoji_order: "1845"
}, {
  name: "computer",
  unicode: "💻",
  category: "o",
  emoji_order: "1846"
}, {
  name: "desktop_computer",
  unicode: "🖥",
  category: "o",
  emoji_order: "1847"
}, {
  name: "printer",
  unicode: "🖨",
  category: "o",
  emoji_order: "1848"
}, {
  name: "keyboard",
  unicode: "⌨",
  category: "o",
  emoji_order: "1849"
}, {
  name: "three_button_mouse",
  unicode: "🖱",
  category: "o",
  emoji_order: "1850"
}, {
  name: "trackball",
  unicode: "🖲",
  category: "o",
  emoji_order: "1851"
}, {
  name: "minidisc",
  unicode: "💽",
  category: "o",
  emoji_order: "1852"
}, {
  name: "floppy_disk",
  unicode: "💾",
  category: "o",
  emoji_order: "1853"
}, {
  name: "cd",
  unicode: "💿",
  category: "o",
  emoji_order: "1854"
}, {
  name: "dvd",
  unicode: "📀",
  category: "o",
  emoji_order: "1855"
}, {
  name: "movie_camera",
  unicode: "🎥",
  category: "o",
  emoji_order: "1856"
}, {
  name: "film_frames",
  unicode: "🎞",
  category: "o",
  emoji_order: "1857"
}, {
  name: "film_projector",
  unicode: "📽",
  category: "o",
  emoji_order: "1858"
}, {
  name: "clapper",
  unicode: "🎬",
  category: "a",
  emoji_order: "1859"
}, {
  name: "tv",
  unicode: "📺",
  category: "o",
  emoji_order: "1860"
}, {
  name: "camera",
  unicode: "📷",
  category: "o",
  emoji_order: "1861"
}, {
  name: "camera_with_flash",
  unicode: "📸",
  category: "o",
  emoji_order: "1862"
}, {
  name: "video_camera",
  unicode: "📹",
  category: "o",
  emoji_order: "1863"
}, {
  name: "vhs",
  unicode: "📼",
  category: "o",
  emoji_order: "1864"
}, {
  name: "mag",
  unicode: "🔍",
  category: "o",
  emoji_order: "1865"
}, {
  name: "mag_right",
  unicode: "🔎",
  category: "o",
  emoji_order: "1866"
}, {
  name: "microscope",
  unicode: "🔬",
  category: "o",
  emoji_order: "1867"
}, {
  name: "telescope",
  unicode: "🔭",
  category: "o",
  emoji_order: "1868"
}, {
  name: "satellite_antenna",
  unicode: "📡",
  category: "o",
  emoji_order: "1869"
}, {
  name: "candle",
  unicode: "🕯",
  category: "o",
  emoji_order: "1870"
}, {
  name: "bulb",
  unicode: "💡",
  category: "o",
  emoji_order: "1871"
}, {
  name: "flashlight",
  unicode: "🔦",
  category: "o",
  emoji_order: "1872"
}, {
  name: "izakaya_lantern",
  unicode: "🏮",
  category: "o",
  emoji_order: "1873"
}, {
  name: "notebook_with_decorative_cover",
  unicode: "📔",
  category: "o",
  emoji_order: "1874"
}, {
  name: "closed_book",
  unicode: "📕",
  category: "o",
  emoji_order: "1875"
}, {
  name: "book",
  unicode: "📖",
  category: "o",
  emoji_order: "1876"
}, {
  name: "green_book",
  unicode: "📗",
  category: "o",
  emoji_order: "1877"
}, {
  name: "blue_book",
  unicode: "📘",
  category: "o",
  emoji_order: "1878"
}, {
  name: "orange_book",
  unicode: "📙",
  category: "o",
  emoji_order: "1879"
}, {
  name: "books",
  unicode: "📚",
  category: "o",
  emoji_order: "1880"
}, {
  name: "notebook",
  unicode: "📓",
  category: "o",
  emoji_order: "1881"
}, {
  name: "ledger",
  unicode: "📒",
  category: "o",
  emoji_order: "1882"
}, {
  name: "page_with_curl",
  unicode: "📃",
  category: "o",
  emoji_order: "1883"
}, {
  name: "scroll",
  unicode: "📜",
  category: "o",
  emoji_order: "1884"
}, {
  name: "page_facing_up",
  unicode: "📄",
  category: "o",
  emoji_order: "1885"
}, {
  name: "newspaper",
  unicode: "📰",
  category: "o",
  emoji_order: "1886"
}, {
  name: "rolled_up_newspaper",
  unicode: "🗞",
  category: "o",
  emoji_order: "1887"
}, {
  name: "bookmark_tabs",
  unicode: "📑",
  category: "o",
  emoji_order: "1888"
}, {
  name: "bookmark",
  unicode: "🔖",
  category: "o",
  emoji_order: "1889"
}, {
  name: "label",
  unicode: "🏷",
  category: "o",
  emoji_order: "1890"
}, {
  name: "moneybag",
  unicode: "💰",
  category: "o",
  emoji_order: "1891"
}, {
  name: "yen",
  unicode: "💴",
  category: "o",
  emoji_order: "1892"
}, {
  name: "dollar",
  unicode: "💵",
  category: "o",
  emoji_order: "1893"
}, {
  name: "euro",
  unicode: "💶",
  category: "o",
  emoji_order: "1894"
}, {
  name: "pound",
  unicode: "💷",
  category: "o",
  emoji_order: "1895"
}, {
  name: "money_with_wings",
  unicode: "💸",
  category: "o",
  emoji_order: "1896"
}, {
  name: "credit_card",
  unicode: "💳",
  category: "o",
  emoji_order: "1897"
}, {
  name: "chart",
  unicode: "💹",
  category: "s",
  emoji_order: "1898"
}, {
  name: "currency_exchange",
  unicode: "💱",
  category: "s",
  emoji_order: "1899"
}, {
  name: "heavy_dollar_sign",
  unicode: "💲",
  category: "s",
  emoji_order: "1900"
}, {
  name: "envelope",
  unicode: "✉",
  category: "o",
  emoji_order: "1901"
}, {
  name: "e-mail",
  unicode: "📧",
  category: "o",
  emoji_order: "1902"
}, {
  name: "incoming_envelope",
  unicode: "📨",
  category: "o",
  emoji_order: "1903"
}, {
  name: "envelope_with_arrow",
  unicode: "📩",
  category: "o",
  emoji_order: "1904"
}, {
  name: "outbox_tray",
  unicode: "📤",
  category: "o",
  emoji_order: "1905"
}, {
  name: "inbox_tray",
  unicode: "📥",
  category: "o",
  emoji_order: "1906"
}, {
  name: "package",
  unicode: "📦",
  category: "o",
  emoji_order: "1907"
}, {
  name: "mailbox",
  unicode: "📫",
  category: "o",
  emoji_order: "1908"
}, {
  name: "mailbox_closed",
  unicode: "📪",
  category: "o",
  emoji_order: "1909"
}, {
  name: "mailbox_with_mail",
  unicode: "📬",
  category: "o",
  emoji_order: "1910"
}, {
  name: "mailbox_with_no_mail",
  unicode: "📭",
  category: "o",
  emoji_order: "1911"
}, {
  name: "postbox",
  unicode: "📮",
  category: "o",
  emoji_order: "1912"
}, {
  name: "ballot_box_with_ballot",
  unicode: "🗳",
  category: "o",
  emoji_order: "1913"
}, {
  name: "pencil2",
  unicode: "✏",
  category: "o",
  emoji_order: "1914"
}, {
  name: "black_nib",
  unicode: "✒",
  category: "o",
  emoji_order: "1915"
}, {
  name: "lower_left_fountain_pen",
  unicode: "🖋",
  category: "o",
  emoji_order: "1916"
}, {
  name: "lower_left_ballpoint_pen",
  unicode: "🖊",
  category: "o",
  emoji_order: "1917"
}, {
  name: "lower_left_paintbrush",
  unicode: "🖌",
  category: "o",
  emoji_order: "1918"
}, {
  name: "lower_left_crayon",
  unicode: "🖍",
  category: "o",
  emoji_order: "1919"
}, {
  name: "memo",
  unicode: "📝",
  category: "o",
  emoji_order: "1920"
}, {
  name: "briefcase",
  unicode: "💼",
  category: "p",
  emoji_order: "1921"
}, {
  name: "file_folder",
  unicode: "📁",
  category: "o",
  emoji_order: "1922"
}, {
  name: "open_file_folder",
  unicode: "📂",
  category: "o",
  emoji_order: "1923"
}, {
  name: "card_index_dividers",
  unicode: "🗂",
  category: "o",
  emoji_order: "1924"
}, {
  name: "date",
  unicode: "📅",
  category: "o",
  emoji_order: "1925"
}, {
  name: "calendar",
  unicode: "📆",
  category: "o",
  emoji_order: "1926"
}, {
  name: "spiral_note_pad",
  unicode: "🗒",
  category: "o",
  emoji_order: "1927"
}, {
  name: "spiral_calendar_pad",
  unicode: "🗓",
  category: "o",
  emoji_order: "1928"
}, {
  name: "card_index",
  unicode: "📇",
  category: "o",
  emoji_order: "1929"
}, {
  name: "chart_with_upwards_trend",
  unicode: "📈",
  category: "o",
  emoji_order: "1930"
}, {
  name: "chart_with_downwards_trend",
  unicode: "📉",
  category: "o",
  emoji_order: "1931"
}, {
  name: "bar_chart",
  unicode: "📊",
  category: "o",
  emoji_order: "1932"
}, {
  name: "clipboard",
  unicode: "📋",
  category: "o",
  emoji_order: "1933"
}, {
  name: "pushpin",
  unicode: "📌",
  category: "o",
  emoji_order: "1934"
}, {
  name: "round_pushpin",
  unicode: "📍",
  category: "o",
  emoji_order: "1935"
}, {
  name: "paperclip",
  unicode: "📎",
  category: "o",
  emoji_order: "1936"
}, {
  name: "linked_paperclips",
  unicode: "🖇",
  category: "o",
  emoji_order: "1937"
}, {
  name: "straight_ruler",
  unicode: "📏",
  category: "o",
  emoji_order: "1938"
}, {
  name: "triangular_ruler",
  unicode: "📐",
  category: "o",
  emoji_order: "1939"
}, {
  name: "scissors",
  unicode: "✂",
  category: "o",
  emoji_order: "1940"
}, {
  name: "card_file_box",
  unicode: "🗃",
  category: "o",
  emoji_order: "1941"
}, {
  name: "file_cabinet",
  unicode: "🗄",
  category: "o",
  emoji_order: "1942"
}, {
  name: "wastebasket",
  unicode: "🗑",
  category: "o",
  emoji_order: "1943"
}, {
  name: "lock",
  unicode: "🔒",
  category: "o",
  emoji_order: "1944"
}, {
  name: "unlock",
  unicode: "🔓",
  category: "o",
  emoji_order: "1945"
}, {
  name: "lock_with_ink_pen",
  unicode: "🔏",
  category: "o",
  emoji_order: "1946"
}, {
  name: "closed_lock_with_key",
  unicode: "🔐",
  category: "o",
  emoji_order: "1947"
}, {
  name: "key",
  unicode: "🔑",
  category: "o",
  emoji_order: "1948"
}, {
  name: "old_key",
  unicode: "🗝",
  category: "o",
  emoji_order: "1949"
}, {
  name: "hammer",
  unicode: "🔨",
  category: "o",
  emoji_order: "1950"
}, {
  name: "pick",
  unicode: "⛏",
  category: "o",
  emoji_order: "1951"
}, {
  name: "hammer_and_pick",
  unicode: "⚒",
  category: "o",
  emoji_order: "1952"
}, {
  name: "hammer_and_wrench",
  unicode: "🛠",
  category: "o",
  emoji_order: "1953"
}, {
  name: "dagger_knife",
  unicode: "🗡",
  category: "o",
  emoji_order: "1954"
}, {
  name: "crossed_swords",
  unicode: "⚔",
  category: "o",
  emoji_order: "1955"
}, {
  name: "gun",
  unicode: "🔫",
  category: "o",
  emoji_order: "1956"
}, {
  name: "bow_and_arrow",
  unicode: "🏹",
  category: "a",
  emoji_order: "1957"
}, {
  name: "shield",
  unicode: "🛡",
  category: "o",
  emoji_order: "1958"
}, {
  name: "wrench",
  unicode: "🔧",
  category: "o",
  emoji_order: "1959"
}, {
  name: "nut_and_bolt",
  unicode: "🔩",
  category: "o",
  emoji_order: "1960"
}, {
  name: "gear",
  unicode: "⚙",
  category: "o",
  emoji_order: "1961"
}, {
  name: "compression",
  unicode: "🗜",
  category: "o",
  emoji_order: "1962"
}, {
  name: "alembic",
  unicode: "⚗",
  category: "o",
  emoji_order: "1963"
}, {
  name: "scales",
  unicode: "⚖",
  category: "o",
  emoji_order: "1964"
}, {
  name: "link",
  unicode: "🔗",
  category: "o",
  emoji_order: "1965"
}, {
  name: "chains",
  unicode: "⛓",
  category: "o",
  emoji_order: "1966"
}, {
  name: "syringe",
  unicode: "💉",
  category: "o",
  emoji_order: "1967"
}, {
  name: "pill",
  unicode: "💊",
  category: "o",
  emoji_order: "1968"
}, {
  name: "smoking",
  unicode: "🚬",
  category: "o",
  emoji_order: "1969"
}, {
  name: "coffin",
  unicode: "⚰",
  category: "o",
  emoji_order: "1970"
}, {
  name: "funeral_urn",
  unicode: "⚱",
  category: "o",
  emoji_order: "1971"
}, {
  name: "moyai",
  unicode: "🗿",
  category: "o",
  emoji_order: "1972"
}, {
  name: "oil_drum",
  unicode: "🛢",
  category: "o",
  emoji_order: "1973"
}, {
  name: "crystal_ball",
  unicode: "🔮",
  category: "o",
  emoji_order: "1974"
}, {
  name: "shopping_cart",
  unicode: "🛒",
  category: "o",
  emoji_order: "1975"
}, {
  name: "atm",
  unicode: "🏧",
  category: "s",
  emoji_order: "1976"
}, {
  name: "put_litter_in_its_place",
  unicode: "🚮",
  category: "s",
  emoji_order: "1977"
}, {
  name: "potable_water",
  unicode: "🚰",
  category: "s",
  emoji_order: "1978"
}, {
  name: "wheelchair",
  unicode: "♿",
  category: "s",
  emoji_order: "1979"
}, {
  name: "mens",
  unicode: "🚹",
  category: "s",
  emoji_order: "1980"
}, {
  name: "womens",
  unicode: "🚺",
  category: "s",
  emoji_order: "1981"
}, {
  name: "restroom",
  unicode: "🚻",
  category: "s",
  emoji_order: "1982"
}, {
  name: "baby_symbol",
  unicode: "🚼",
  category: "s",
  emoji_order: "1983"
}, {
  name: "wc",
  unicode: "🚾",
  category: "s",
  emoji_order: "1984"
}, {
  name: "passport_control",
  unicode: "🛂",
  category: "s",
  emoji_order: "1985"
}, {
  name: "customs",
  unicode: "🛃",
  category: "s",
  emoji_order: "1986"
}, {
  name: "baggage_claim",
  unicode: "🛄",
  category: "s",
  emoji_order: "1987"
}, {
  name: "left_luggage",
  unicode: "🛅",
  category: "s",
  emoji_order: "1988"
}, {
  name: "warning",
  unicode: "⚠",
  category: "s",
  emoji_order: "1989"
}, {
  name: "children_crossing",
  unicode: "🚸",
  category: "s",
  emoji_order: "1990"
}, {
  name: "no_entry",
  unicode: "⛔",
  category: "s",
  emoji_order: "1991"
}, {
  name: "no_entry_sign",
  unicode: "🚫",
  category: "s",
  emoji_order: "1992"
}, {
  name: "no_bicycles",
  unicode: "🚳",
  category: "s",
  emoji_order: "1993"
}, {
  name: "no_smoking",
  unicode: "🚭",
  category: "s",
  emoji_order: "1994"
}, {
  name: "do_not_litter",
  unicode: "🚯",
  category: "s",
  emoji_order: "1995"
}, {
  name: "non-potable_water",
  unicode: "🚱",
  category: "s",
  emoji_order: "1996"
}, {
  name: "no_pedestrians",
  unicode: "🚷",
  category: "s",
  emoji_order: "1997"
}, {
  name: "no_mobile_phones",
  unicode: "📵",
  category: "s",
  emoji_order: "1998"
}, {
  name: "underage",
  unicode: "🔞",
  category: "s",
  emoji_order: "1999"
}, {
  name: "radioactive",
  unicode: "☢",
  category: "s",
  emoji_order: "2000"
}, {
  name: "biohazard",
  unicode: "☣",
  category: "s",
  emoji_order: "2001"
}, {
  name: "arrow_up",
  unicode: "⬆",
  category: "s",
  emoji_order: "2002"
}, {
  name: "arrow_upper_right",
  unicode: "↗",
  category: "s",
  emoji_order: "2003"
}, {
  name: "arrow_right",
  unicode: "➡",
  category: "s",
  emoji_order: "2004"
}, {
  name: "arrow_lower_right",
  unicode: "↘",
  category: "s",
  emoji_order: "2005"
}, {
  name: "arrow_down",
  unicode: "⬇",
  category: "s",
  emoji_order: "2006"
}, {
  name: "arrow_lower_left",
  unicode: "↙",
  category: "s",
  emoji_order: "2007"
}, {
  name: "arrow_left",
  unicode: "⬅",
  category: "s",
  emoji_order: "2008"
}, {
  name: "arrow_upper_left",
  unicode: "↖",
  category: "s",
  emoji_order: "2009"
}, {
  name: "arrow_up_down",
  unicode: "↕",
  category: "s",
  emoji_order: "2010"
}, {
  name: "left_right_arrow",
  unicode: "↔",
  category: "s",
  emoji_order: "2011"
}, {
  name: "leftwards_arrow_with_hook",
  unicode: "↩",
  category: "s",
  emoji_order: "2012"
}, {
  name: "arrow_right_hook",
  unicode: "↪",
  category: "s",
  emoji_order: "2013"
}, {
  name: "arrow_heading_up",
  unicode: "⤴",
  category: "s",
  emoji_order: "2014"
}, {
  name: "arrow_heading_down",
  unicode: "⤵",
  category: "s",
  emoji_order: "2015"
}, {
  name: "arrows_clockwise",
  unicode: "🔃",
  category: "s",
  emoji_order: "2016"
}, {
  name: "arrows_counterclockwise",
  unicode: "🔄",
  category: "s",
  emoji_order: "2017"
}, {
  name: "back",
  unicode: "🔙",
  category: "s",
  emoji_order: "2018"
}, {
  name: "end",
  unicode: "🔚",
  category: "s",
  emoji_order: "2019"
}, {
  name: "on",
  unicode: "🔛",
  category: "s",
  emoji_order: "2020"
}, {
  name: "soon",
  unicode: "🔜",
  category: "s",
  emoji_order: "2021"
}, {
  name: "top",
  unicode: "🔝",
  category: "s",
  emoji_order: "2022"
}, {
  name: "place_of_worship",
  unicode: "🛐",
  category: "s",
  emoji_order: "2023"
}, {
  name: "atom_symbol",
  unicode: "⚛",
  category: "s",
  emoji_order: "2024"
}, {
  name: "om_symbol",
  unicode: "🕉",
  category: "s",
  emoji_order: "2025"
}, {
  name: "star_of_david",
  unicode: "✡",
  category: "s",
  emoji_order: "2026"
}, {
  name: "wheel_of_dharma",
  unicode: "☸",
  category: "s",
  emoji_order: "2027"
}, {
  name: "yin_yang",
  unicode: "☯",
  category: "s",
  emoji_order: "2028"
}, {
  name: "latin_cross",
  unicode: "✝",
  category: "s",
  emoji_order: "2029"
}, {
  name: "orthodox_cross",
  unicode: "☦",
  category: "s",
  emoji_order: "2030"
}, {
  name: "star_and_crescent",
  unicode: "☪",
  category: "s",
  emoji_order: "2031"
}, {
  name: "peace_symbol",
  unicode: "☮",
  category: "s",
  emoji_order: "2032"
}, {
  name: "menorah_with_nine_branches",
  unicode: "🕎",
  category: "s",
  emoji_order: "2033"
}, {
  name: "six_pointed_star",
  unicode: "🔯",
  category: "s",
  emoji_order: "2034"
}, {
  name: "aries",
  unicode: "♈",
  category: "s",
  emoji_order: "2035"
}, {
  name: "taurus",
  unicode: "♉",
  category: "s",
  emoji_order: "2036"
}, {
  name: "gemini",
  unicode: "♊",
  category: "s",
  emoji_order: "2037"
}, {
  name: "cancer",
  unicode: "♋",
  category: "s",
  emoji_order: "2038"
}, {
  name: "leo",
  unicode: "♌",
  category: "s",
  emoji_order: "2039"
}, {
  name: "virgo",
  unicode: "♍",
  category: "s",
  emoji_order: "2040"
}, {
  name: "libra",
  unicode: "♎",
  category: "s",
  emoji_order: "2041"
}, {
  name: "scorpius",
  unicode: "♏",
  category: "s",
  emoji_order: "2042"
}, {
  name: "sagittarius",
  unicode: "♐",
  category: "s",
  emoji_order: "2043"
}, {
  name: "capricorn",
  unicode: "♑",
  category: "s",
  emoji_order: "2044"
}, {
  name: "aquarius",
  unicode: "♒",
  category: "s",
  emoji_order: "2045"
}, {
  name: "pisces",
  unicode: "♓",
  category: "s",
  emoji_order: "2046"
}, {
  name: "ophiuchus",
  unicode: "⛎",
  category: "s",
  emoji_order: "2047"
}, {
  name: "twisted_rightwards_arrows",
  unicode: "🔀",
  category: "s",
  emoji_order: "2048"
}, {
  name: "repeat",
  unicode: "🔁",
  category: "s",
  emoji_order: "2049"
}, {
  name: "repeat_one",
  unicode: "🔂",
  category: "s",
  emoji_order: "2050"
}, {
  name: "arrow_forward",
  unicode: "▶",
  category: "s",
  emoji_order: "2051"
}, {
  name: "fast_forward",
  unicode: "⏩",
  category: "s",
  emoji_order: "2052"
}, {
  name: "black_right_pointing_double_triangle_with_vertical_bar",
  unicode: "⏭",
  category: "s",
  emoji_order: "2053"
}, {
  name: "black_right_pointing_triangle_with_double_vertical_bar",
  unicode: "⏯",
  category: "s",
  emoji_order: "2054"
}, {
  name: "arrow_backward",
  unicode: "◀",
  category: "s",
  emoji_order: "2055"
}, {
  name: "rewind",
  unicode: "⏪",
  category: "s",
  emoji_order: "2056"
}, {
  name: "black_left_pointing_double_triangle_with_vertical_bar",
  unicode: "⏮",
  category: "s",
  emoji_order: "2057"
}, {
  name: "arrow_up_small",
  unicode: "🔼",
  category: "s",
  emoji_order: "2058"
}, {
  name: "arrow_double_up",
  unicode: "⏫",
  category: "s",
  emoji_order: "2059"
}, {
  name: "arrow_down_small",
  unicode: "🔽",
  category: "s",
  emoji_order: "2060"
}, {
  name: "arrow_double_down",
  unicode: "⏬",
  category: "s",
  emoji_order: "2061"
}, {
  name: "double_vertical_bar",
  unicode: "⏸",
  category: "s",
  emoji_order: "2062"
}, {
  name: "black_square_for_stop",
  unicode: "⏹",
  category: "s",
  emoji_order: "2063"
}, {
  name: "black_circle_for_record",
  unicode: "⏺",
  category: "s",
  emoji_order: "2064"
}, {
  name: "eject",
  unicode: "⏏",
  category: "s",
  emoji_order: "2065"
}, {
  name: "cinema",
  unicode: "🎦",
  category: "s",
  emoji_order: "2066"
}, {
  name: "low_brightness",
  unicode: "🔅",
  category: "s",
  emoji_order: "2067"
}, {
  name: "high_brightness",
  unicode: "🔆",
  category: "s",
  emoji_order: "2068"
}, {
  name: "signal_strength",
  unicode: "📶",
  category: "s",
  emoji_order: "2069"
}, {
  name: "vibration_mode",
  unicode: "📳",
  category: "s",
  emoji_order: "2070"
}, {
  name: "mobile_phone_off",
  unicode: "📴",
  category: "s",
  emoji_order: "2071"
}, {
  name: "recycle",
  unicode: "♻",
  category: "s",
  emoji_order: "2072"
}, {
  name: "name_badge",
  unicode: "📛",
  category: "s",
  emoji_order: "2073"
}, {
  name: "fleur_de_lis",
  unicode: "⚜",
  category: "s",
  emoji_order: "2074"
}, {
  name: "beginner",
  unicode: "🔰",
  category: "s",
  emoji_order: "2075"
}, {
  name: "trident",
  unicode: "🔱",
  category: "s",
  emoji_order: "2076"
}, {
  name: "o",
  unicode: "⭕",
  category: "s",
  emoji_order: "2077"
}, {
  name: "white_check_mark",
  unicode: "✅",
  category: "s",
  emoji_order: "2078"
}, {
  name: "ballot_box_with_check",
  unicode: "☑",
  category: "s",
  emoji_order: "2079"
}, {
  name: "heavy_check_mark",
  unicode: "✔",
  category: "s",
  emoji_order: "2080"
}, {
  name: "heavy_multiplication_x",
  unicode: "✖",
  category: "s",
  emoji_order: "2081"
}, {
  name: "x",
  unicode: "❌",
  category: "s",
  emoji_order: "2082"
}, {
  name: "negative_squared_cross_mark",
  unicode: "❎",
  category: "s",
  emoji_order: "2083"
}, {
  name: "heavy_plus_sign",
  unicode: "➕",
  category: "s",
  emoji_order: "2084"
}, {
  name: "heavy_minus_sign",
  unicode: "➖",
  category: "s",
  emoji_order: "2088"
}, {
  name: "heavy_division_sign",
  unicode: "➗",
  category: "s",
  emoji_order: "2089"
}, {
  name: "curly_loop",
  unicode: "➰",
  category: "s",
  emoji_order: "2090"
}, {
  name: "loop",
  unicode: "➿",
  category: "s",
  emoji_order: "2091"
}, {
  name: "part_alternation_mark",
  unicode: "〽",
  category: "s",
  emoji_order: "2092"
}, {
  name: "eight_spoked_asterisk",
  unicode: "✳",
  category: "s",
  emoji_order: "2093"
}, {
  name: "eight_pointed_black_star",
  unicode: "✴",
  category: "s",
  emoji_order: "2094"
}, {
  name: "sparkle",
  unicode: "❇",
  category: "s",
  emoji_order: "2095"
}, {
  name: "bangbang",
  unicode: "‼",
  category: "s",
  emoji_order: "2096"
}, {
  name: "interrobang",
  unicode: "⁉",
  category: "s",
  emoji_order: "2097"
}, {
  name: "question",
  unicode: "❓",
  category: "s",
  emoji_order: "2098"
}, {
  name: "grey_question",
  unicode: "❔",
  category: "s",
  emoji_order: "2099"
}, {
  name: "grey_exclamation",
  unicode: "❕",
  category: "s",
  emoji_order: "2100"
}, {
  name: "exclamation",
  unicode: "❗",
  category: "s",
  emoji_order: "2101"
}, {
  name: "wavy_dash",
  unicode: "〰",
  category: "s",
  emoji_order: "2102"
}, {
  name: "copyright",
  unicode: "©",
  category: "s",
  emoji_order: "2103"
}, {
  name: "registered",
  unicode: "®",
  category: "s",
  emoji_order: "2104"
}, {
  name: "tm",
  unicode: "™",
  category: "s",
  emoji_order: "2105"
}, {
  name: "hash",
  unicode: "0023-fe0f-20e3",
  category: "s",
  emoji_order: "2106"
}, {
  name: "keycap_star",
  unicode: "002a-fe0f-20e3",
  category: "s",
  emoji_order: "2107"
}, {
  name: "zero",
  unicode: "0030-fe0f-20e3",
  category: "s",
  emoji_order: "2108"
}, {
  name: "one",
  unicode: "0031-fe0f-20e3",
  category: "s",
  emoji_order: "2109"
}, {
  name: "two",
  unicode: "0032-fe0f-20e3",
  category: "s",
  emoji_order: "2110"
}, {
  name: "three",
  unicode: "0033-fe0f-20e3",
  category: "s",
  emoji_order: "2111"
}, {
  name: "four",
  unicode: "0034-fe0f-20e3",
  category: "s",
  emoji_order: "2112"
}, {
  name: "five",
  unicode: "0035-fe0f-20e3",
  category: "s",
  emoji_order: "2113"
}, {
  name: "six",
  unicode: "0036-fe0f-20e3",
  category: "s",
  emoji_order: "2114"
}, {
  name: "seven",
  unicode: "0037-fe0f-20e3",
  category: "s",
  emoji_order: "2115"
}, {
  name: "eight",
  unicode: "0038-fe0f-20e3",
  category: "s",
  emoji_order: "2116"
}, {
  name: "nine",
  unicode: "0039-fe0f-20e3",
  category: "s",
  emoji_order: "2117"
}, {
  name: "keycap_ten",
  unicode: "🔟",
  category: "s",
  emoji_order: "2118"
}, {
  name: "capital_abcd",
  unicode: "🔠",
  category: "s",
  emoji_order: "2120"
}, {
  name: "abcd",
  unicode: "🔡",
  category: "s",
  emoji_order: "2121"
}, {
  name: "s",
  unicode: "🔣",
  category: "s",
  emoji_order: "2123"
}, {
  name: "abc",
  unicode: "🔤",
  category: "s",
  emoji_order: "2124"
}, {
  name: "a",
  unicode: "🅰",
  category: "s",
  emoji_order: "2125"
}, {
  name: "ab",
  unicode: "🆎",
  category: "s",
  emoji_order: "2126"
}, {
  name: "b",
  unicode: "🅱",
  category: "s",
  emoji_order: "2127"
}, {
  name: "cl",
  unicode: "🆑",
  category: "s",
  emoji_order: "2128"
}, {
  name: "cool",
  unicode: "🆒",
  category: "s",
  emoji_order: "2129"
}, {
  name: "free",
  unicode: "🆓",
  category: "s",
  emoji_order: "2130"
}, {
  name: "information_source",
  unicode: "ℹ",
  category: "s",
  emoji_order: "2131"
}, {
  name: "id",
  unicode: "🆔",
  category: "s",
  emoji_order: "2132"
}, {
  name: "m",
  unicode: "Ⓜ",
  category: "s",
  emoji_order: "2133"
}, {
  name: "new",
  unicode: "🆕",
  category: "s",
  emoji_order: "2134"
}, {
  name: "ng",
  unicode: "🆖",
  category: "s",
  emoji_order: "2135"
}, {
  name: "o2",
  unicode: "🅾",
  category: "s",
  emoji_order: "2136"
}, {
  name: "ok",
  unicode: "🆗",
  category: "s",
  emoji_order: "2137"
}, {
  name: "parking",
  unicode: "🅿",
  category: "s",
  emoji_order: "2138"
}, {
  name: "sos",
  unicode: "🆘",
  category: "s",
  emoji_order: "2139"
}, {
  name: "up",
  unicode: "🆙",
  category: "s",
  emoji_order: "2140"
}, {
  name: "vs",
  unicode: "🆚",
  category: "s",
  emoji_order: "2141"
}, {
  name: "koko",
  unicode: "🈁",
  category: "s",
  emoji_order: "2142"
}, {
  name: "sa",
  unicode: "🈂",
  category: "s",
  emoji_order: "2143"
}, {
  name: "u6708",
  unicode: "🈷",
  category: "s",
  emoji_order: "2144"
}, {
  name: "u6709",
  unicode: "🈶",
  category: "s",
  emoji_order: "2145"
}, {
  name: "u6307",
  unicode: "🈯",
  category: "s",
  emoji_order: "2146"
}, {
  name: "ideograph_advantage",
  unicode: "🉐",
  category: "s",
  emoji_order: "2147"
}, {
  name: "u5272",
  unicode: "🈹",
  category: "s",
  emoji_order: "2148"
}, {
  name: "u7121",
  unicode: "🈚",
  category: "s",
  emoji_order: "2149"
}, {
  name: "u7981",
  unicode: "🈲",
  category: "s",
  emoji_order: "2150"
}, {
  name: "accept",
  unicode: "🉑",
  category: "s",
  emoji_order: "2151"
}, {
  name: "u7533",
  unicode: "🈸",
  category: "s",
  emoji_order: "2152"
}, {
  name: "u5408",
  unicode: "🈴",
  category: "s",
  emoji_order: "2153"
}, {
  name: "u7a7a",
  unicode: "🈳",
  category: "s",
  emoji_order: "2154"
}, {
  name: "congratulations",
  unicode: "㊗",
  category: "s",
  emoji_order: "2155"
}, {
  name: "secret",
  unicode: "㊙",
  category: "s",
  emoji_order: "2156"
}, {
  name: "u55b6",
  unicode: "🈺",
  category: "s",
  emoji_order: "2157"
}, {
  name: "u6e80",
  unicode: "🈵",
  category: "s",
  emoji_order: "2158"
}, {
  name: "black_small_square",
  unicode: "▪",
  category: "s",
  emoji_order: "2159"
}, {
  name: "white_small_square",
  unicode: "▫",
  category: "s",
  emoji_order: "2160"
}, {
  name: "white_medium_square",
  unicode: "◻",
  category: "s",
  emoji_order: "2161"
}, {
  name: "black_medium_square",
  unicode: "◼",
  category: "s",
  emoji_order: "2162"
}, {
  name: "white_medium_small_square",
  unicode: "◽",
  category: "s",
  emoji_order: "2163"
}, {
  name: "black_medium_small_square",
  unicode: "◾",
  category: "s",
  emoji_order: "2164"
}, {
  name: "black_large_square",
  unicode: "⬛",
  category: "s",
  emoji_order: "2165"
}, {
  name: "white_large_square",
  unicode: "⬜",
  category: "s",
  emoji_order: "2166"
}, {
  name: "large_orange_diamond",
  unicode: "🔶",
  category: "s",
  emoji_order: "2167"
}, {
  name: "large_blue_diamond",
  unicode: "🔷",
  category: "s",
  emoji_order: "2168"
}, {
  name: "small_orange_diamond",
  unicode: "🔸",
  category: "s",
  emoji_order: "2169"
}, {
  name: "small_blue_diamond",
  unicode: "🔹",
  category: "s",
  emoji_order: "2170"
}, {
  name: "small_red_triangle",
  unicode: "🔺",
  category: "s",
  emoji_order: "2171"
}, {
  name: "small_red_triangle_down",
  unicode: "🔻",
  category: "s",
  emoji_order: "2172"
}, {
  name: "diamond_shape_with_a_dot_inside",
  unicode: "💠",
  category: "s",
  emoji_order: "2173"
}, {
  name: "radio_button",
  unicode: "🔘",
  category: "s",
  emoji_order: "2174"
}, {
  name: "black_square_button",
  unicode: "🔲",
  category: "s",
  emoji_order: "2175"
}, {
  name: "white_square_button",
  unicode: "🔳",
  category: "s",
  emoji_order: "2176"
}, {
  name: "white_circle",
  unicode: "⚪",
  category: "s",
  emoji_order: "2177"
}, {
  name: "black_circle",
  unicode: "⚫",
  category: "s",
  emoji_order: "2178"
}, {
  name: "red_circle",
  unicode: "🔴",
  category: "s",
  emoji_order: "2179"
}, {
  name: "large_blue_circle",
  unicode: "🔵",
  category: "s",
  emoji_order: "2180"
}, {
  name: "checkered_flag",
  unicode: "🏁",
  category: "t",
  emoji_order: "2181"
}, {
  name: "triangular_flag_on_post",
  unicode: "🚩",
  category: "o",
  emoji_order: "2182"
}, {
  name: "crossed_flags",
  unicode: "🎌",
  category: "o",
  emoji_order: "2183"
}, {
  name: "waving_black_flag",
  unicode: "🏴",
  category: "o",
  emoji_order: "2184"
}, {
  name: "waving_white_flag",
  unicode: "🏳",
  category: "o",
  emoji_order: "2185"
}, {
  name: "flag-ac",
  unicode: "1f1e6-1f1e8",
  category: "f",
  emoji_order: "2187"
}, {
  name: "flag-ad",
  unicode: "1f1e6-1f1e9",
  category: "f",
  emoji_order: "2188"
}, {
  name: "flag-ae",
  unicode: "1f1e6-1f1ea",
  category: "f",
  emoji_order: "2189"
}, {
  name: "flag-af",
  unicode: "1f1e6-1f1eb",
  category: "f",
  emoji_order: "2190"
}, {
  name: "flag-ag",
  unicode: "1f1e6-1f1ec",
  category: "f",
  emoji_order: "2191"
}, {
  name: "flag-ai",
  unicode: "1f1e6-1f1ee",
  category: "f",
  emoji_order: "2192"
}, {
  name: "flag-al",
  unicode: "1f1e6-1f1f1",
  category: "f",
  emoji_order: "2193"
}, {
  name: "flag-am",
  unicode: "1f1e6-1f1f2",
  category: "f",
  emoji_order: "2194"
}, {
  name: "flag-ao",
  unicode: "1f1e6-1f1f4",
  category: "f",
  emoji_order: "2195"
}, {
  name: "flag-aq",
  unicode: "1f1e6-1f1f6",
  category: "f",
  emoji_order: "2196"
}, {
  name: "flag-ar",
  unicode: "1f1e6-1f1f7",
  category: "f",
  emoji_order: "2197"
}, {
  name: "flag-as",
  unicode: "1f1e6-1f1f8",
  category: "f",
  emoji_order: "2198"
}, {
  name: "flag-at",
  unicode: "1f1e6-1f1f9",
  category: "f",
  emoji_order: "2199"
}, {
  name: "flag-au",
  unicode: "1f1e6-1f1fa",
  category: "f",
  emoji_order: "2200"
}, {
  name: "flag-aw",
  unicode: "1f1e6-1f1fc",
  category: "f",
  emoji_order: "2201"
}, {
  name: "flag-ax",
  unicode: "1f1e6-1f1fd",
  category: "f",
  emoji_order: "2202"
}, {
  name: "flag-az",
  unicode: "1f1e6-1f1ff",
  category: "f",
  emoji_order: "2203"
}, {
  name: "flag-ba",
  unicode: "1f1e7-1f1e6",
  category: "f",
  emoji_order: "2204"
}, {
  name: "flag-bb",
  unicode: "1f1e7-1f1e7",
  category: "f",
  emoji_order: "2205"
}, {
  name: "flag-bd",
  unicode: "1f1e7-1f1e9",
  category: "f",
  emoji_order: "2206"
}, {
  name: "flag-be",
  unicode: "1f1e7-1f1ea",
  category: "f",
  emoji_order: "2207"
}, {
  name: "flag-bf",
  unicode: "1f1e7-1f1eb",
  category: "f",
  emoji_order: "2208"
}, {
  name: "flag-bg",
  unicode: "1f1e7-1f1ec",
  category: "f",
  emoji_order: "2209"
}, {
  name: "flag-bh",
  unicode: "1f1e7-1f1ed",
  category: "f",
  emoji_order: "2210"
}, {
  name: "flag-bi",
  unicode: "1f1e7-1f1ee",
  category: "f",
  emoji_order: "2211"
}, {
  name: "flag-bj",
  unicode: "1f1e7-1f1ef",
  category: "f",
  emoji_order: "2212"
}, {
  name: "flag-bl",
  unicode: "1f1e7-1f1f1",
  category: "f",
  emoji_order: "2213"
}, {
  name: "flag-bm",
  unicode: "1f1e7-1f1f2",
  category: "f",
  emoji_order: "2214"
}, {
  name: "flag-bn",
  unicode: "1f1e7-1f1f3",
  category: "f",
  emoji_order: "2215"
}, {
  name: "flag-bo",
  unicode: "1f1e7-1f1f4",
  category: "f",
  emoji_order: "2216"
}, {
  name: "flag-bq",
  unicode: "1f1e7-1f1f6",
  category: "f",
  emoji_order: "2217"
}, {
  name: "flag-br",
  unicode: "1f1e7-1f1f7",
  category: "f",
  emoji_order: "2218"
}, {
  name: "flag-bs",
  unicode: "1f1e7-1f1f8",
  category: "f",
  emoji_order: "2219"
}, {
  name: "flag-bt",
  unicode: "1f1e7-1f1f9",
  category: "f",
  emoji_order: "2220"
}, {
  name: "flag-bv",
  unicode: "1f1e7-1f1fb",
  category: "f",
  emoji_order: "2221"
}, {
  name: "flag-bw",
  unicode: "1f1e7-1f1fc",
  category: "f",
  emoji_order: "2222"
}, {
  name: "flag-by",
  unicode: "1f1e7-1f1fe",
  category: "f",
  emoji_order: "2223"
}, {
  name: "flag-bz",
  unicode: "1f1e7-1f1ff",
  category: "f",
  emoji_order: "2224"
}, {
  name: "flag-ca",
  unicode: "1f1e8-1f1e6",
  category: "f",
  emoji_order: "2225"
}, {
  name: "flag-cc",
  unicode: "1f1e8-1f1e8",
  category: "f",
  emoji_order: "2226"
}, {
  name: "flag-cd",
  unicode: "1f1e8-1f1e9",
  category: "f",
  emoji_order: "2227"
}, {
  name: "flag-cf",
  unicode: "1f1e8-1f1eb",
  category: "f",
  emoji_order: "2228"
}, {
  name: "flag-cg",
  unicode: "1f1e8-1f1ec",
  category: "f",
  emoji_order: "2229"
}, {
  name: "flag-ch",
  unicode: "1f1e8-1f1ed",
  category: "f",
  emoji_order: "2230"
}, {
  name: "flag-ci",
  unicode: "1f1e8-1f1ee",
  category: "f",
  emoji_order: "2231"
}, {
  name: "flag-ck",
  unicode: "1f1e8-1f1f0",
  category: "f",
  emoji_order: "2232"
}, {
  name: "flag-cl",
  unicode: "1f1e8-1f1f1",
  category: "f",
  emoji_order: "2233"
}, {
  name: "flag-cm",
  unicode: "1f1e8-1f1f2",
  category: "f",
  emoji_order: "2234"
}, {
  name: "flag-cn",
  unicode: "1f1e8-1f1f3",
  category: "f",
  emoji_order: "2235"
}, {
  name: "flag-co",
  unicode: "1f1e8-1f1f4",
  category: "f",
  emoji_order: "2236"
}, {
  name: "flag-cp",
  unicode: "1f1e8-1f1f5",
  category: "f",
  emoji_order: "2237"
}, {
  name: "flag-cr",
  unicode: "1f1e8-1f1f7",
  category: "f",
  emoji_order: "2238"
}, {
  name: "flag-cu",
  unicode: "1f1e8-1f1fa",
  category: "f",
  emoji_order: "2239"
}, {
  name: "flag-cv",
  unicode: "1f1e8-1f1fb",
  category: "f",
  emoji_order: "2240"
}, {
  name: "flag-cw",
  unicode: "1f1e8-1f1fc",
  category: "f",
  emoji_order: "2241"
}, {
  name: "flag-cx",
  unicode: "1f1e8-1f1fd",
  category: "f",
  emoji_order: "2242"
}, {
  name: "flag-cy",
  unicode: "1f1e8-1f1fe",
  category: "f",
  emoji_order: "2243"
}, {
  name: "flag-cz",
  unicode: "1f1e8-1f1ff",
  category: "f",
  emoji_order: "2244"
}, {
  name: "flag-de",
  unicode: "1f1e9-1f1ea",
  category: "f",
  emoji_order: "2245"
}, {
  name: "flag-dg",
  unicode: "1f1e9-1f1ec",
  category: "f",
  emoji_order: "2246"
}, {
  name: "flag-dj",
  unicode: "1f1e9-1f1ef",
  category: "f",
  emoji_order: "2247"
}, {
  name: "flag-dk",
  unicode: "1f1e9-1f1f0",
  category: "f",
  emoji_order: "2248"
}, {
  name: "flag-dm",
  unicode: "1f1e9-1f1f2",
  category: "f",
  emoji_order: "2249"
}, {
  name: "flag-do",
  unicode: "1f1e9-1f1f4",
  category: "f",
  emoji_order: "2250"
}, {
  name: "flag-dz",
  unicode: "1f1e9-1f1ff",
  category: "f",
  emoji_order: "2251"
}, {
  name: "flag-ea",
  unicode: "1f1ea-1f1e6",
  category: "f",
  emoji_order: "2252"
}, {
  name: "flag-ec",
  unicode: "1f1ea-1f1e8",
  category: "f",
  emoji_order: "2253"
}, {
  name: "flag-ee",
  unicode: "1f1ea-1f1ea",
  category: "f",
  emoji_order: "2254"
}, {
  name: "flag-eg",
  unicode: "1f1ea-1f1ec",
  category: "f",
  emoji_order: "2255"
}, {
  name: "flag-eh",
  unicode: "1f1ea-1f1ed",
  category: "f",
  emoji_order: "2256"
}, {
  name: "flag-er",
  unicode: "1f1ea-1f1f7",
  category: "f",
  emoji_order: "2257"
}, {
  name: "flag-es",
  unicode: "1f1ea-1f1f8",
  category: "f",
  emoji_order: "2258"
}, {
  name: "flag-et",
  unicode: "1f1ea-1f1f9",
  category: "f",
  emoji_order: "2259"
}, {
  name: "flag-eu",
  unicode: "1f1ea-1f1fa",
  category: "f",
  emoji_order: "2260"
}, {
  name: "flag-fi",
  unicode: "1f1eb-1f1ee",
  category: "f",
  emoji_order: "2261"
}, {
  name: "flag-fj",
  unicode: "1f1eb-1f1ef",
  category: "f",
  emoji_order: "2262"
}, {
  name: "flag-fk",
  unicode: "1f1eb-1f1f0",
  category: "f",
  emoji_order: "2263"
}, {
  name: "flag-fm",
  unicode: "1f1eb-1f1f2",
  category: "f",
  emoji_order: "2264"
}, {
  name: "flag-fo",
  unicode: "1f1eb-1f1f4",
  category: "f",
  emoji_order: "2265"
}, {
  name: "flag-fr",
  unicode: "1f1eb-1f1f7",
  category: "f",
  emoji_order: "2266"
}, {
  name: "flag-ga",
  unicode: "1f1ec-1f1e6",
  category: "f",
  emoji_order: "2267"
}, {
  name: "flag-gb",
  unicode: "1f1ec-1f1e7",
  category: "f",
  emoji_order: "2268"
}, {
  name: "flag-gd",
  unicode: "1f1ec-1f1e9",
  category: "f",
  emoji_order: "2269"
}, {
  name: "flag-ge",
  unicode: "1f1ec-1f1ea",
  category: "f",
  emoji_order: "2270"
}, {
  name: "flag-gf",
  unicode: "1f1ec-1f1eb",
  category: "f",
  emoji_order: "2271"
}, {
  name: "flag-gg",
  unicode: "1f1ec-1f1ec",
  category: "f",
  emoji_order: "2272"
}, {
  name: "flag-gh",
  unicode: "1f1ec-1f1ed",
  category: "f",
  emoji_order: "2273"
}, {
  name: "flag-gi",
  unicode: "1f1ec-1f1ee",
  category: "f",
  emoji_order: "2274"
}, {
  name: "flag-gl",
  unicode: "1f1ec-1f1f1",
  category: "f",
  emoji_order: "2275"
}, {
  name: "flag-gm",
  unicode: "1f1ec-1f1f2",
  category: "f",
  emoji_order: "2276"
}, {
  name: "flag-gn",
  unicode: "1f1ec-1f1f3",
  category: "f",
  emoji_order: "2277"
}, {
  name: "flag-gp",
  unicode: "1f1ec-1f1f5",
  category: "f",
  emoji_order: "2278"
}, {
  name: "flag-gq",
  unicode: "1f1ec-1f1f6",
  category: "f",
  emoji_order: "2279"
}, {
  name: "flag-gr",
  unicode: "1f1ec-1f1f7",
  category: "f",
  emoji_order: "2280"
}, {
  name: "flag-gs",
  unicode: "1f1ec-1f1f8",
  category: "f",
  emoji_order: "2281"
}, {
  name: "flag-gt",
  unicode: "1f1ec-1f1f9",
  category: "f",
  emoji_order: "2282"
}, {
  name: "flag-gu",
  unicode: "1f1ec-1f1fa",
  category: "f",
  emoji_order: "2283"
}, {
  name: "flag-gw",
  unicode: "1f1ec-1f1fc",
  category: "f",
  emoji_order: "2284"
}, {
  name: "flag-gy",
  unicode: "1f1ec-1f1fe",
  category: "f",
  emoji_order: "2285"
}, {
  name: "flag-hk",
  unicode: "1f1ed-1f1f0",
  category: "f",
  emoji_order: "2286"
}, {
  name: "flag-hm",
  unicode: "1f1ed-1f1f2",
  category: "f",
  emoji_order: "2287"
}, {
  name: "flag-hn",
  unicode: "1f1ed-1f1f3",
  category: "f",
  emoji_order: "2288"
}, {
  name: "flag-hr",
  unicode: "1f1ed-1f1f7",
  category: "f",
  emoji_order: "2289"
}, {
  name: "flag-ht",
  unicode: "1f1ed-1f1f9",
  category: "f",
  emoji_order: "2290"
}, {
  name: "flag-hu",
  unicode: "1f1ed-1f1fa",
  category: "f",
  emoji_order: "2291"
}, {
  name: "flag-ic",
  unicode: "1f1ee-1f1e8",
  category: "f",
  emoji_order: "2292"
}, {
  name: "flag-id",
  unicode: "1f1ee-1f1e9",
  category: "f",
  emoji_order: "2293"
}, {
  name: "flag-ie",
  unicode: "1f1ee-1f1ea",
  category: "f",
  emoji_order: "2294"
}, {
  name: "flag-il",
  unicode: "1f1ee-1f1f1",
  category: "f",
  emoji_order: "2295"
}, {
  name: "flag-im",
  unicode: "1f1ee-1f1f2",
  category: "f",
  emoji_order: "2296"
}, {
  name: "flag-in",
  unicode: "1f1ee-1f1f3",
  category: "f",
  emoji_order: "2297"
}, {
  name: "flag-io",
  unicode: "1f1ee-1f1f4",
  category: "f",
  emoji_order: "2298"
}, {
  name: "flag-iq",
  unicode: "1f1ee-1f1f6",
  category: "f",
  emoji_order: "2299"
}, {
  name: "flag-ir",
  unicode: "1f1ee-1f1f7",
  category: "f",
  emoji_order: "2300"
}, {
  name: "flag-is",
  unicode: "1f1ee-1f1f8",
  category: "f",
  emoji_order: "2301"
}, {
  name: "flag-it",
  unicode: "1f1ee-1f1f9",
  category: "f",
  emoji_order: "2302"
}, {
  name: "flag-je",
  unicode: "1f1ef-1f1ea",
  category: "f",
  emoji_order: "2303"
}, {
  name: "flag-jm",
  unicode: "1f1ef-1f1f2",
  category: "f",
  emoji_order: "2304"
}, {
  name: "flag-jo",
  unicode: "1f1ef-1f1f4",
  category: "f",
  emoji_order: "2305"
}, {
  name: "flag-jp",
  unicode: "1f1ef-1f1f5",
  category: "f",
  emoji_order: "2306"
}, {
  name: "flag-ke",
  unicode: "1f1f0-1f1ea",
  category: "f",
  emoji_order: "2307"
}, {
  name: "flag-kg",
  unicode: "1f1f0-1f1ec",
  category: "f",
  emoji_order: "2308"
}, {
  name: "flag-kh",
  unicode: "1f1f0-1f1ed",
  category: "f",
  emoji_order: "2309"
}, {
  name: "flag-ki",
  unicode: "1f1f0-1f1ee",
  category: "f",
  emoji_order: "2310"
}, {
  name: "flag-km",
  unicode: "1f1f0-1f1f2",
  category: "f",
  emoji_order: "2311"
}, {
  name: "flag-kn",
  unicode: "1f1f0-1f1f3",
  category: "f",
  emoji_order: "2312"
}, {
  name: "flag-kp",
  unicode: "1f1f0-1f1f5",
  category: "f",
  emoji_order: "2313"
}, {
  name: "flag-kr",
  unicode: "1f1f0-1f1f7",
  category: "f",
  emoji_order: "2314"
}, {
  name: "flag-kw",
  unicode: "1f1f0-1f1fc",
  category: "f",
  emoji_order: "2315"
}, {
  name: "flag-ky",
  unicode: "1f1f0-1f1fe",
  category: "f",
  emoji_order: "2316"
}, {
  name: "flag-kz",
  unicode: "1f1f0-1f1ff",
  category: "f",
  emoji_order: "2317"
}, {
  name: "flag-la",
  unicode: "1f1f1-1f1e6",
  category: "f",
  emoji_order: "2318"
}, {
  name: "flag-lb",
  unicode: "1f1f1-1f1e7",
  category: "f",
  emoji_order: "2319"
}, {
  name: "flag-lc",
  unicode: "1f1f1-1f1e8",
  category: "f",
  emoji_order: "2320"
}, {
  name: "flag-li",
  unicode: "1f1f1-1f1ee",
  category: "f",
  emoji_order: "2321"
}, {
  name: "flag-lk",
  unicode: "1f1f1-1f1f0",
  category: "f",
  emoji_order: "2322"
}, {
  name: "flag-lr",
  unicode: "1f1f1-1f1f7",
  category: "f",
  emoji_order: "2323"
}, {
  name: "flag-ls",
  unicode: "1f1f1-1f1f8",
  category: "f",
  emoji_order: "2324"
}, {
  name: "flag-lt",
  unicode: "1f1f1-1f1f9",
  category: "f",
  emoji_order: "2325"
}, {
  name: "flag-lu",
  unicode: "1f1f1-1f1fa",
  category: "f",
  emoji_order: "2326"
}, {
  name: "flag-lv",
  unicode: "1f1f1-1f1fb",
  category: "f",
  emoji_order: "2327"
}, {
  name: "flag-ly",
  unicode: "1f1f1-1f1fe",
  category: "f",
  emoji_order: "2328"
}, {
  name: "flag-ma",
  unicode: "1f1f2-1f1e6",
  category: "f",
  emoji_order: "2329"
}, {
  name: "flag-mc",
  unicode: "1f1f2-1f1e8",
  category: "f",
  emoji_order: "2330"
}, {
  name: "flag-md",
  unicode: "1f1f2-1f1e9",
  category: "f",
  emoji_order: "2331"
}, {
  name: "flag-me",
  unicode: "1f1f2-1f1ea",
  category: "f",
  emoji_order: "2332"
}, {
  name: "flag-mf",
  unicode: "1f1f2-1f1eb",
  category: "f",
  emoji_order: "2333"
}, {
  name: "flag-mg",
  unicode: "1f1f2-1f1ec",
  category: "f",
  emoji_order: "2334"
}, {
  name: "flag-mh",
  unicode: "1f1f2-1f1ed",
  category: "f",
  emoji_order: "2335"
}, {
  name: "flag-mk",
  unicode: "1f1f2-1f1f0",
  category: "f",
  emoji_order: "2336"
}, {
  name: "flag-ml",
  unicode: "1f1f2-1f1f1",
  category: "f",
  emoji_order: "2337"
}, {
  name: "flag-mm",
  unicode: "1f1f2-1f1f2",
  category: "f",
  emoji_order: "2338"
}, {
  name: "flag-mn",
  unicode: "1f1f2-1f1f3",
  category: "f",
  emoji_order: "2339"
}, {
  name: "flag-mo",
  unicode: "1f1f2-1f1f4",
  category: "f",
  emoji_order: "2340"
}, {
  name: "flag-mp",
  unicode: "1f1f2-1f1f5",
  category: "f",
  emoji_order: "2341"
}, {
  name: "flag-mq",
  unicode: "1f1f2-1f1f6",
  category: "f",
  emoji_order: "2342"
}, {
  name: "flag-mr",
  unicode: "1f1f2-1f1f7",
  category: "f",
  emoji_order: "2343"
}, {
  name: "flag-ms",
  unicode: "1f1f2-1f1f8",
  category: "f",
  emoji_order: "2344"
}, {
  name: "flag-mt",
  unicode: "1f1f2-1f1f9",
  category: "f",
  emoji_order: "2345"
}, {
  name: "flag-mu",
  unicode: "1f1f2-1f1fa",
  category: "f",
  emoji_order: "2346"
}, {
  name: "flag-mv",
  unicode: "1f1f2-1f1fb",
  category: "f",
  emoji_order: "2347"
}, {
  name: "flag-mw",
  unicode: "1f1f2-1f1fc",
  category: "f",
  emoji_order: "2348"
}, {
  name: "flag-mx",
  unicode: "1f1f2-1f1fd",
  category: "f",
  emoji_order: "2349"
}, {
  name: "flag-my",
  unicode: "1f1f2-1f1fe",
  category: "f",
  emoji_order: "2350"
}, {
  name: "flag-mz",
  unicode: "1f1f2-1f1ff",
  category: "f",
  emoji_order: "2351"
}, {
  name: "flag-na",
  unicode: "1f1f3-1f1e6",
  category: "f",
  emoji_order: "2352"
}, {
  name: "flag-nc",
  unicode: "1f1f3-1f1e8",
  category: "f",
  emoji_order: "2353"
}, {
  name: "flag-ne",
  unicode: "1f1f3-1f1ea",
  category: "f",
  emoji_order: "2354"
}, {
  name: "flag-nf",
  unicode: "1f1f3-1f1eb",
  category: "f",
  emoji_order: "2355"
}, {
  name: "flag-ng",
  unicode: "1f1f3-1f1ec",
  category: "f",
  emoji_order: "2356"
}, {
  name: "flag-ni",
  unicode: "1f1f3-1f1ee",
  category: "f",
  emoji_order: "2357"
}, {
  name: "flag-nl",
  unicode: "1f1f3-1f1f1",
  category: "f",
  emoji_order: "2358"
}, {
  name: "flag-no",
  unicode: "1f1f3-1f1f4",
  category: "f",
  emoji_order: "2359"
}, {
  name: "flag-np",
  unicode: "1f1f3-1f1f5",
  category: "f",
  emoji_order: "2360"
}, {
  name: "flag-nr",
  unicode: "1f1f3-1f1f7",
  category: "f",
  emoji_order: "2361"
}, {
  name: "flag-nu",
  unicode: "1f1f3-1f1fa",
  category: "f",
  emoji_order: "2362"
}, {
  name: "flag-nz",
  unicode: "1f1f3-1f1ff",
  category: "f",
  emoji_order: "2363"
}, {
  name: "flag-om",
  unicode: "1f1f4-1f1f2",
  category: "f",
  emoji_order: "2364"
}, {
  name: "flag-pa",
  unicode: "1f1f5-1f1e6",
  category: "f",
  emoji_order: "2365"
}, {
  name: "flag-pe",
  unicode: "1f1f5-1f1ea",
  category: "f",
  emoji_order: "2366"
}, {
  name: "flag-pf",
  unicode: "1f1f5-1f1eb",
  category: "f",
  emoji_order: "2367"
}, {
  name: "flag-pg",
  unicode: "1f1f5-1f1ec",
  category: "f",
  emoji_order: "2368"
}, {
  name: "flag-ph",
  unicode: "1f1f5-1f1ed",
  category: "f",
  emoji_order: "2369"
}, {
  name: "flag-pk",
  unicode: "1f1f5-1f1f0",
  category: "f",
  emoji_order: "2370"
}, {
  name: "flag-pl",
  unicode: "1f1f5-1f1f1",
  category: "f",
  emoji_order: "2371"
}, {
  name: "flag-pm",
  unicode: "1f1f5-1f1f2",
  category: "f",
  emoji_order: "2372"
}, {
  name: "flag-pn",
  unicode: "1f1f5-1f1f3",
  category: "f",
  emoji_order: "2373"
}, {
  name: "flag-pr",
  unicode: "1f1f5-1f1f7",
  category: "f",
  emoji_order: "2374"
}, {
  name: "flag-ps",
  unicode: "1f1f5-1f1f8",
  category: "f",
  emoji_order: "2375"
}, {
  name: "flag-pt",
  unicode: "1f1f5-1f1f9",
  category: "f",
  emoji_order: "2376"
}, {
  name: "flag-pw",
  unicode: "1f1f5-1f1fc",
  category: "f",
  emoji_order: "2377"
}, {
  name: "flag-py",
  unicode: "1f1f5-1f1fe",
  category: "f",
  emoji_order: "2378"
}, {
  name: "flag-qa",
  unicode: "1f1f6-1f1e6",
  category: "f",
  emoji_order: "2379"
}, {
  name: "flag-re",
  unicode: "1f1f7-1f1ea",
  category: "f",
  emoji_order: "2380"
}, {
  name: "flag-ro",
  unicode: "1f1f7-1f1f4",
  category: "f",
  emoji_order: "2381"
}, {
  name: "flag-rs",
  unicode: "1f1f7-1f1f8",
  category: "f",
  emoji_order: "2382"
}, {
  name: "flag-ru",
  unicode: "1f1f7-1f1fa",
  category: "f",
  emoji_order: "2383"
}, {
  name: "flag-rw",
  unicode: "1f1f7-1f1fc",
  category: "f",
  emoji_order: "2384"
}, {
  name: "flag-sa",
  unicode: "1f1f8-1f1e6",
  category: "f",
  emoji_order: "2385"
}, {
  name: "flag-sb",
  unicode: "1f1f8-1f1e7",
  category: "f",
  emoji_order: "2386"
}, {
  name: "flag-sc",
  unicode: "1f1f8-1f1e8",
  category: "f",
  emoji_order: "2387"
}, {
  name: "flag-sd",
  unicode: "1f1f8-1f1e9",
  category: "f",
  emoji_order: "2388"
}, {
  name: "flag-se",
  unicode: "1f1f8-1f1ea",
  category: "f",
  emoji_order: "2389"
}, {
  name: "flag-sg",
  unicode: "1f1f8-1f1ec",
  category: "f",
  emoji_order: "2390"
}, {
  name: "flag-sh",
  unicode: "1f1f8-1f1ed",
  category: "f",
  emoji_order: "2391"
}, {
  name: "flag-si",
  unicode: "1f1f8-1f1ee",
  category: "f",
  emoji_order: "2392"
}, {
  name: "flag-sj",
  unicode: "1f1f8-1f1ef",
  category: "f",
  emoji_order: "2393"
}, {
  name: "flag-sk",
  unicode: "1f1f8-1f1f0",
  category: "f",
  emoji_order: "2394"
}, {
  name: "flag-sl",
  unicode: "1f1f8-1f1f1",
  category: "f",
  emoji_order: "2395"
}, {
  name: "flag-sm",
  unicode: "1f1f8-1f1f2",
  category: "f",
  emoji_order: "2396"
}, {
  name: "flag-sn",
  unicode: "1f1f8-1f1f3",
  category: "f",
  emoji_order: "2397"
}, {
  name: "flag-so",
  unicode: "1f1f8-1f1f4",
  category: "f",
  emoji_order: "2398"
}, {
  name: "flag-sr",
  unicode: "1f1f8-1f1f7",
  category: "f",
  emoji_order: "2399"
}, {
  name: "flag-ss",
  unicode: "1f1f8-1f1f8",
  category: "f",
  emoji_order: "2400"
}, {
  name: "flag-st",
  unicode: "1f1f8-1f1f9",
  category: "f",
  emoji_order: "2401"
}, {
  name: "flag-sv",
  unicode: "1f1f8-1f1fb",
  category: "f",
  emoji_order: "2402"
}, {
  name: "flag-sx",
  unicode: "1f1f8-1f1fd",
  category: "f",
  emoji_order: "2403"
}, {
  name: "flag-sy",
  unicode: "1f1f8-1f1fe",
  category: "f",
  emoji_order: "2404"
}, {
  name: "flag-sz",
  unicode: "1f1f8-1f1ff",
  category: "f",
  emoji_order: "2405"
}, {
  name: "flag-ta",
  unicode: "1f1f9-1f1e6",
  category: "f",
  emoji_order: "2406"
}, {
  name: "flag-tc",
  unicode: "1f1f9-1f1e8",
  category: "f",
  emoji_order: "2407"
}, {
  name: "flag-td",
  unicode: "1f1f9-1f1e9",
  category: "f",
  emoji_order: "2408"
}, {
  name: "flag-tf",
  unicode: "1f1f9-1f1eb",
  category: "f",
  emoji_order: "2409"
}, {
  name: "flag-tg",
  unicode: "1f1f9-1f1ec",
  category: "f",
  emoji_order: "2410"
}, {
  name: "flag-th",
  unicode: "1f1f9-1f1ed",
  category: "f",
  emoji_order: "2411"
}, {
  name: "flag-tj",
  unicode: "1f1f9-1f1ef",
  category: "f",
  emoji_order: "2412"
}, {
  name: "flag-tk",
  unicode: "1f1f9-1f1f0",
  category: "f",
  emoji_order: "2413"
}, {
  name: "flag-tl",
  unicode: "1f1f9-1f1f1",
  category: "f",
  emoji_order: "2414"
}, {
  name: "flag-tm",
  unicode: "1f1f9-1f1f2",
  category: "f",
  emoji_order: "2415"
}, {
  name: "flag-tn",
  unicode: "1f1f9-1f1f3",
  category: "f",
  emoji_order: "2416"
}, {
  name: "flag-to",
  unicode: "1f1f9-1f1f4",
  category: "f",
  emoji_order: "2417"
}, {
  name: "flag-tr",
  unicode: "1f1f9-1f1f7",
  category: "f",
  emoji_order: "2418"
}, {
  name: "flag-tt",
  unicode: "1f1f9-1f1f9",
  category: "f",
  emoji_order: "2419"
}, {
  name: "flag-tv",
  unicode: "1f1f9-1f1fb",
  category: "f",
  emoji_order: "2420"
}, {
  name: "flag-tw",
  unicode: "1f1f9-1f1fc",
  category: "f",
  emoji_order: "2421"
}, {
  name: "flag-tz",
  unicode: "1f1f9-1f1ff",
  category: "f",
  emoji_order: "2422"
}, {
  name: "flag-ua",
  unicode: "1f1fa-1f1e6",
  category: "f",
  emoji_order: "2423"
}, {
  name: "flag-ug",
  unicode: "1f1fa-1f1ec",
  category: "f",
  emoji_order: "2424"
}, {
  name: "flag-um",
  unicode: "1f1fa-1f1f2",
  category: "f",
  emoji_order: "2425"
}, {
  name: "flag-us",
  unicode: "1f1fa-1f1f8",
  category: "f",
  emoji_order: "2427"
}, {
  name: "flag-uy",
  unicode: "1f1fa-1f1fe",
  category: "f",
  emoji_order: "2428"
}, {
  name: "flag-uz",
  unicode: "1f1fa-1f1ff",
  category: "f",
  emoji_order: "2429"
}, {
  name: "flag-va",
  unicode: "1f1fb-1f1e6",
  category: "f",
  emoji_order: "2430"
}, {
  name: "flag-vc",
  unicode: "1f1fb-1f1e8",
  category: "f",
  emoji_order: "2431"
}, {
  name: "flag-ve",
  unicode: "1f1fb-1f1ea",
  category: "f",
  emoji_order: "2432"
}, {
  name: "flag-vg",
  unicode: "1f1fb-1f1ec",
  category: "f",
  emoji_order: "2433"
}, {
  name: "flag-vi",
  unicode: "1f1fb-1f1ee",
  category: "f",
  emoji_order: "2434"
}, {
  name: "flag-vn",
  unicode: "1f1fb-1f1f3",
  category: "f",
  emoji_order: "2435"
}, {
  name: "flag-vu",
  unicode: "1f1fb-1f1fa",
  category: "f",
  emoji_order: "2436"
}, {
  name: "flag-wf",
  unicode: "1f1fc-1f1eb",
  category: "f",
  emoji_order: "2437"
}, {
  name: "flag-ws",
  unicode: "1f1fc-1f1f8",
  category: "f",
  emoji_order: "2438"
}, {
  name: "flag-xk",
  unicode: "1f1fd-1f1f0",
  category: "f",
  emoji_order: "2439"
}, {
  name: "flag-ye",
  unicode: "1f1fe-1f1ea",
  category: "f",
  emoji_order: "2440"
}, {
  name: "flag-yt",
  unicode: "1f1fe-1f1f9",
  category: "f",
  emoji_order: "2441"
}, {
  name: "flag-za",
  unicode: "1f1ff-1f1e6",
  category: "f",
  emoji_order: "2442"
}, {
  name: "flag-zm",
  unicode: "1f1ff-1f1f2",
  category: "f",
  emoji_order: "2443"
}, {
  name: "flag-zw",
  unicode: "1f1ff-1f1fc",
  category: "f",
  emoji_order: "2444"
}, {
  name: "speech",
  unicode: "😀",
  category: "p",
  emoji_order: "1"
}];
var a = {};
n.forEach(function (e) {
  e.shortname = ":{emojiListObject.name}:", a[e.name] = e;
});
const i = a,
  t = (c = {
    default: () => e.default
  }, d = {}, o.d(d, c), d);
var c, d;
function m(e, o) {
  (null == o || o > e.length) && (o = e.length);
  for (var r = 0, n = new Array(o); r < o; r++) n[r] = e[r];
  return n;
}
function u(e) {
  return u = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, u(e);
}
function g(e, o, r) {
  return o = s(o), function (e, o) {
    if (o && ("object" === u(o) || "function" == typeof o)) return o;
    if (void 0 !== o) throw new TypeError("Derived constructors may only return object or undefined");
    return function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e);
  }(e, f() ? Reflect.construct(o, r || [], s(e).constructor) : o.apply(e, r));
}
function f() {
  try {
    var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
  } catch (e) {}
  return (f = function () {
    return !!e;
  })();
}
function _() {
  return _ = "undefined" != typeof Reflect && Reflect.get ? Reflect.get.bind() : function (e, o, r) {
    var n = function (e, o) {
      for (; !Object.prototype.hasOwnProperty.call(e, o) && null !== (e = s(e)););
      return e;
    }(e, o);
    if (n) {
      var a = Object.getOwnPropertyDescriptor(n, o);
      return a.get ? a.get.call(arguments.length < 3 ? e : r) : a.value;
    }
  }, _.apply(this, arguments);
}
function s(e) {
  return s = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
    return e.__proto__ || Object.getPrototypeOf(e);
  }, s(e);
}
function y(e, o) {
  return y = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, o) {
    return e.__proto__ = o, e;
  }, y(e, o);
}
function l(e, o, r) {
  return (o = j(o)) in e ? Object.defineProperty(e, o, {
    value: r,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[o] = r, e;
}
function j(e) {
  var o = function (e, o) {
    if ("object" != u(e) || !e) return e;
    var r = e[Symbol.toPrimitive];
    if (void 0 !== r) {
      var n = r.call(e, "string");
      if ("object" != u(n)) return n;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return String(e);
  }(e);
  return "symbol" == u(o) ? o : o + "";
}
var h = t.default.import("blots/embed"),
  p = function (e) {
    function o() {
      return function (e, o) {
        if (!(e instanceof o)) throw new TypeError("Cannot call a class as a function");
      }(this, o), g(this, o, arguments);
    }
    return function (e, o) {
      if ("function" != typeof o && null !== o) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(o && o.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), o && y(e, o);
    }(o, h), r = o, (n = [{
      key: "create",
      value: function (e) {
        var r = _(s(o), "create", this).call(this);
        if ("object" === u(e)) o.buildSpan(e, r);else if ("string" == typeof e) {
          var n = i[e];
          n && o.buildSpan(n, r);
        }
        return r;
      }
    }, {
      key: "value",
      value: function (e) {
        return e.dataset.name;
      }
    }, {
      key: "buildSpan",
      value: function (e, r) {
        r.setAttribute("data-name", e.name);
        var n,
          a = document.createElement("span");
        a.classList.add(this.emojiClass), a.classList.add(this.emojiPrefix + e.name), a.innerText = String.fromCodePoint.apply(String, function (e) {
          if (Array.isArray(e)) return m(e);
        }(n = o.parseUnicode(e.unicode)) || function (e) {
          if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
        }(n) || function (e, o) {
          if (e) {
            if ("string" == typeof e) return m(e, o);
            var r = Object.prototype.toString.call(e).slice(8, -1);
            return "Object" === r && e.constructor && (r = e.constructor.name), "Map" === r || "Set" === r ? Array.from(e) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? m(e, o) : void 0;
          }
        }(n) || function () {
          throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
        }()), r.appendChild(a);
      }
    }, {
      key: "parseUnicode",
      value: function (e) {
        return e.split("-").map(function (e) {
          return parseInt(e, 16);
        });
      }
    }]) && function (e, o) {
      for (var r = 0; r < o.length; r++) {
        var n = o[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, j(n.key), n);
      }
    }(r, n), Object.defineProperty(r, "prototype", {
      writable: !1
    }), r;
    var r, n;
  }();
l(p, "blotName", "emoji"), l(p, "className", "ql-emojiblot"), l(p, "tagName", "span"), l(p, "emojiClass", "ap"), l(p, "emojiPrefix", "ap-");
const b = p;
function v(e) {
  return Array.isArray ? Array.isArray(e) : "[object Array]" === L(e);
}
function w(e) {
  return "string" == typeof e;
}
function k(e) {
  return "number" == typeof e;
}
function x(e) {
  return "object" == typeof e;
}
function S(e) {
  return null != e;
}
function E(e) {
  return !e.trim().length;
}
function L(e) {
  return null == e ? void 0 === e ? "[object Undefined]" : "[object Null]" : Object.prototype.toString.call(e);
}
const M = e => `Missing ${e} property in key`,
  C = e => `Property 'weight' in key '${e}' must be a positive integer`,
  q = Object.prototype.hasOwnProperty;
class O {
  constructor(e) {
    this._keys = [], this._keyMap = {};
    let o = 0;
    e.forEach(e => {
      let r = I(e);
      this._keys.push(r), this._keyMap[r.id] = r, o += r.weight;
    }), this._keys.forEach(e => {
      e.weight /= o;
    });
  }
  get(e) {
    return this._keyMap[e];
  }
  keys() {
    return this._keys;
  }
  toJSON() {
    return JSON.stringify(this._keys);
  }
}
function I(e) {
  let o = null,
    r = null,
    n = null,
    a = 1,
    i = null;
  if (w(e) || v(e)) n = e, o = P(e), r = A(e);else {
    if (!q.call(e, "name")) throw new Error(M("name"));
    const t = e.name;
    if (n = t, q.call(e, "weight") && (a = e.weight, a <= 0)) throw new Error(C(t));
    o = P(t), r = A(t), i = e.getFn;
  }
  return {
    path: o,
    id: r,
    weight: a,
    src: n,
    getFn: i
  };
}
function P(e) {
  return v(e) ? e : e.split(".");
}
function A(e) {
  return v(e) ? e.join(".") : e;
}
var T = {
  isCaseSensitive: !1,
  includeScore: !1,
  keys: [],
  shouldSort: !0,
  sortFn: (e, o) => e.score === o.score ? e.idx < o.idx ? -1 : 1 : e.score < o.score ? -1 : 1,
  includeMatches: !1,
  findAllMatches: !1,
  minMatchCharLength: 1,
  location: 0,
  threshold: .6,
  distance: 100,
  useExtendedSearch: !1,
  getFn: function (e, o) {
    let r = [],
      n = !1;
    const a = (e, o, i) => {
      if (S(e)) if (o[i]) {
        const t = e[o[i]];
        if (!S(t)) return;
        if (i === o.length - 1 && (w(t) || k(t) || function (e) {
          return !0 === e || !1 === e || function (e) {
            return x(e) && null !== e;
          }(e) && "[object Boolean]" == L(e);
        }(t))) r.push(function (e) {
          return null == e ? "" : function (e) {
            if ("string" == typeof e) return e;
            let o = e + "";
            return "0" == o && 1 / e == -1 / 0 ? "-0" : o;
          }(e);
        }(t));else if (v(t)) {
          n = !0;
          for (let e = 0, r = t.length; e < r; e += 1) a(t[e], o, i + 1);
        } else o.length && a(t, o, i + 1);
      } else r.push(e);
    };
    return a(e, w(o) ? o.split(".") : o, 0), n ? r : r[0];
  },
  ignoreLocation: !1,
  ignoreFieldNorm: !1,
  fieldNormWeight: 1
};
const R = /[^ ]+/g;
class N {
  constructor({
    getFn: e = T.getFn,
    fieldNormWeight: o = T.fieldNormWeight
  } = {}) {
    this.norm = function (e = 1, o = 3) {
      const r = new Map(),
        n = Math.pow(10, o);
      return {
        get(o) {
          const a = o.match(R).length;
          if (r.has(a)) return r.get(a);
          const i = 1 / Math.pow(a, .5 * e),
            t = parseFloat(Math.round(i * n) / n);
          return r.set(a, t), t;
        },
        clear() {
          r.clear();
        }
      };
    }(o, 3), this.getFn = e, this.isCreated = !1, this.setIndexRecords();
  }
  setSources(e = []) {
    this.docs = e;
  }
  setIndexRecords(e = []) {
    this.records = e;
  }
  setKeys(e = []) {
    this.keys = e, this._keysMap = {}, e.forEach((e, o) => {
      this._keysMap[e.id] = o;
    });
  }
  create() {
    !this.isCreated && this.docs.length && (this.isCreated = !0, w(this.docs[0]) ? this.docs.forEach((e, o) => {
      this._addString(e, o);
    }) : this.docs.forEach((e, o) => {
      this._addObject(e, o);
    }), this.norm.clear());
  }
  add(e) {
    const o = this.size();
    w(e) ? this._addString(e, o) : this._addObject(e, o);
  }
  removeAt(e) {
    this.records.splice(e, 1);
    for (let o = e, r = this.size(); o < r; o += 1) this.records[o].i -= 1;
  }
  getValueForItemAtKeyId(e, o) {
    return e[this._keysMap[o]];
  }
  size() {
    return this.records.length;
  }
  _addString(e, o) {
    if (!S(e) || E(e)) return;
    let r = {
      v: e,
      i: o,
      n: this.norm.get(e)
    };
    this.records.push(r);
  }
  _addObject(e, o) {
    let r = {
      i: o,
      $: {}
    };
    this.keys.forEach((o, n) => {
      let a = o.getFn ? o.getFn(e) : this.getFn(e, o.path);
      if (S(a)) if (v(a)) {
        let e = [];
        const o = [{
          nestedArrIndex: -1,
          value: a
        }];
        for (; o.length;) {
          const {
            nestedArrIndex: r,
            value: n
          } = o.pop();
          if (S(n)) if (w(n) && !E(n)) {
            let o = {
              v: n,
              i: r,
              n: this.norm.get(n)
            };
            e.push(o);
          } else v(n) && n.forEach((e, r) => {
            o.push({
              nestedArrIndex: r,
              value: e
            });
          });
        }
        r.$[n] = e;
      } else if (w(a) && !E(a)) {
        let e = {
          v: a,
          n: this.norm.get(a)
        };
        r.$[n] = e;
      }
    }), this.records.push(r);
  }
  toJSON() {
    return {
      keys: this.keys,
      records: this.records
    };
  }
}
function $(e, o, {
  getFn: r = T.getFn,
  fieldNormWeight: n = T.fieldNormWeight
} = {}) {
  const a = new N({
    getFn: r,
    fieldNormWeight: n
  });
  return a.setKeys(e.map(I)), a.setSources(o), a.create(), a;
}
function z(e, {
  errors: o = 0,
  currentLocation: r = 0,
  expectedLocation: n = 0,
  distance: a = T.distance,
  ignoreLocation: i = T.ignoreLocation
} = {}) {
  const t = o / e.length;
  if (i) return t;
  const c = Math.abs(n - r);
  return a ? t + c / a : c ? 1 : t;
}
const B = 32;
function F(e) {
  let o = {};
  for (let r = 0, n = e.length; r < n; r += 1) {
    const a = e.charAt(r);
    o[a] = (o[a] || 0) | 1 << n - r - 1;
  }
  return o;
}
class W {
  constructor(e, {
    location: o = T.location,
    threshold: r = T.threshold,
    distance: n = T.distance,
    includeMatches: a = T.includeMatches,
    findAllMatches: i = T.findAllMatches,
    minMatchCharLength: t = T.minMatchCharLength,
    isCaseSensitive: c = T.isCaseSensitive,
    ignoreLocation: d = T.ignoreLocation
  } = {}) {
    if (this.options = {
      location: o,
      threshold: r,
      distance: n,
      includeMatches: a,
      findAllMatches: i,
      minMatchCharLength: t,
      isCaseSensitive: c,
      ignoreLocation: d
    }, this.pattern = c ? e : e.toLowerCase(), this.chunks = [], !this.pattern.length) return;
    const m = (e, o) => {
        this.chunks.push({
          pattern: e,
          alphabet: F(e),
          startIndex: o
        });
      },
      u = this.pattern.length;
    if (u > B) {
      let e = 0;
      const o = u % B,
        r = u - o;
      for (; e < r;) m(this.pattern.substr(e, B), e), e += B;
      if (o) {
        const e = u - B;
        m(this.pattern.substr(e), e);
      }
    } else m(this.pattern, 0);
  }
  searchIn(e) {
    const {
      isCaseSensitive: o,
      includeMatches: r
    } = this.options;
    if (o || (e = e.toLowerCase()), this.pattern === e) {
      let o = {
        isMatch: !0,
        score: 0
      };
      return r && (o.indices = [[0, e.length - 1]]), o;
    }
    const {
      location: n,
      distance: a,
      threshold: i,
      findAllMatches: t,
      minMatchCharLength: c,
      ignoreLocation: d
    } = this.options;
    let m = [],
      u = 0,
      g = !1;
    this.chunks.forEach(({
      pattern: o,
      alphabet: f,
      startIndex: _
    }) => {
      const {
        isMatch: s,
        score: y,
        indices: l
      } = function (e, o, r, {
        location: n = T.location,
        distance: a = T.distance,
        threshold: i = T.threshold,
        findAllMatches: t = T.findAllMatches,
        minMatchCharLength: c = T.minMatchCharLength,
        includeMatches: d = T.includeMatches,
        ignoreLocation: m = T.ignoreLocation
      } = {}) {
        if (o.length > B) throw new Error("Pattern length exceeds max of 32.");
        const u = o.length,
          g = e.length,
          f = Math.max(0, Math.min(n, g));
        let _ = i,
          s = f;
        const y = c > 1 || d,
          l = y ? Array(g) : [];
        let j;
        for (; (j = e.indexOf(o, s)) > -1;) {
          let e = z(o, {
            currentLocation: j,
            expectedLocation: f,
            distance: a,
            ignoreLocation: m
          });
          if (_ = Math.min(e, _), s = j + u, y) {
            let e = 0;
            for (; e < u;) l[j + e] = 1, e += 1;
          }
        }
        s = -1;
        let h = [],
          p = 1,
          b = u + g;
        const v = 1 << u - 1;
        for (let n = 0; n < u; n += 1) {
          let i = 0,
            c = b;
          for (; i < c;) z(o, {
            errors: n,
            currentLocation: f + c,
            expectedLocation: f,
            distance: a,
            ignoreLocation: m
          }) <= _ ? i = c : b = c, c = Math.floor((b - i) / 2 + i);
          b = c;
          let d = Math.max(1, f - c + 1),
            j = t ? g : Math.min(f + c, g) + u,
            w = Array(j + 2);
          w[j + 1] = (1 << n) - 1;
          for (let i = j; i >= d; i -= 1) {
            let t = i - 1,
              c = r[e.charAt(t)];
            if (y && (l[t] = +!!c), w[i] = (w[i + 1] << 1 | 1) & c, n && (w[i] |= (h[i + 1] | h[i]) << 1 | 1 | h[i + 1]), w[i] & v && (p = z(o, {
              errors: n,
              currentLocation: t,
              expectedLocation: f,
              distance: a,
              ignoreLocation: m
            }), p <= _)) {
              if (_ = p, s = t, s <= f) break;
              d = Math.max(1, 2 * f - s);
            }
          }
          if (z(o, {
            errors: n + 1,
            currentLocation: f,
            expectedLocation: f,
            distance: a,
            ignoreLocation: m
          }) > _) break;
          h = w;
        }
        const w = {
          isMatch: s >= 0,
          score: Math.max(.001, p)
        };
        if (y) {
          const e = function (e = [], o = T.minMatchCharLength) {
            let r = [],
              n = -1,
              a = -1,
              i = 0;
            for (let t = e.length; i < t; i += 1) {
              let t = e[i];
              t && -1 === n ? n = i : t || -1 === n || (a = i - 1, a - n + 1 >= o && r.push([n, a]), n = -1);
            }
            return e[i - 1] && i - n >= o && r.push([n, i - 1]), r;
          }(l, c);
          e.length ? d && (w.indices = e) : w.isMatch = !1;
        }
        return w;
      }(e, o, f, {
        location: n + _,
        distance: a,
        threshold: i,
        findAllMatches: t,
        minMatchCharLength: c,
        includeMatches: r,
        ignoreLocation: d
      });
      s && (g = !0), u += y, s && l && (m = [...m, ...l]);
    });
    let f = {
      isMatch: g,
      score: g ? u / this.chunks.length : 1
    };
    return g && r && (f.indices = m), f;
  }
}
class H {
  constructor(e) {
    this.pattern = e;
  }
  static isMultiMatch(e) {
    return U(e, this.multiRegex);
  }
  static isSingleMatch(e) {
    return U(e, this.singleRegex);
  }
  search() {}
}
function U(e, o) {
  const r = e.match(o);
  return r ? r[1] : null;
}
class D extends H {
  constructor(e, {
    location: o = T.location,
    threshold: r = T.threshold,
    distance: n = T.distance,
    includeMatches: a = T.includeMatches,
    findAllMatches: i = T.findAllMatches,
    minMatchCharLength: t = T.minMatchCharLength,
    isCaseSensitive: c = T.isCaseSensitive,
    ignoreLocation: d = T.ignoreLocation
  } = {}) {
    super(e), this._bitapSearch = new W(e, {
      location: o,
      threshold: r,
      distance: n,
      includeMatches: a,
      findAllMatches: i,
      minMatchCharLength: t,
      isCaseSensitive: c,
      ignoreLocation: d
    });
  }
  static get type() {
    return "fuzzy";
  }
  static get multiRegex() {
    return /^"(.*)"$/;
  }
  static get singleRegex() {
    return /^(.*)$/;
  }
  search(e) {
    return this._bitapSearch.searchIn(e);
  }
}
class K extends H {
  constructor(e) {
    super(e);
  }
  static get type() {
    return "include";
  }
  static get multiRegex() {
    return /^'"(.*)"$/;
  }
  static get singleRegex() {
    return /^'(.*)$/;
  }
  search(e) {
    let o,
      r = 0;
    const n = [],
      a = this.pattern.length;
    for (; (o = e.indexOf(this.pattern, r)) > -1;) r = o + a, n.push([o, r - 1]);
    const i = !!n.length;
    return {
      isMatch: i,
      score: i ? 0 : 1,
      indices: n
    };
  }
}
const J = [class extends H {
    constructor(e) {
      super(e);
    }
    static get type() {
      return "exact";
    }
    static get multiRegex() {
      return /^="(.*)"$/;
    }
    static get singleRegex() {
      return /^=(.*)$/;
    }
    search(e) {
      const o = e === this.pattern;
      return {
        isMatch: o,
        score: o ? 0 : 1,
        indices: [0, this.pattern.length - 1]
      };
    }
  }, K, class extends H {
    constructor(e) {
      super(e);
    }
    static get type() {
      return "prefix-exact";
    }
    static get multiRegex() {
      return /^\^"(.*)"$/;
    }
    static get singleRegex() {
      return /^\^(.*)$/;
    }
    search(e) {
      const o = e.startsWith(this.pattern);
      return {
        isMatch: o,
        score: o ? 0 : 1,
        indices: [0, this.pattern.length - 1]
      };
    }
  }, class extends H {
    constructor(e) {
      super(e);
    }
    static get type() {
      return "inverse-prefix-exact";
    }
    static get multiRegex() {
      return /^!\^"(.*)"$/;
    }
    static get singleRegex() {
      return /^!\^(.*)$/;
    }
    search(e) {
      const o = !e.startsWith(this.pattern);
      return {
        isMatch: o,
        score: o ? 0 : 1,
        indices: [0, e.length - 1]
      };
    }
  }, class extends H {
    constructor(e) {
      super(e);
    }
    static get type() {
      return "inverse-suffix-exact";
    }
    static get multiRegex() {
      return /^!"(.*)"\$$/;
    }
    static get singleRegex() {
      return /^!(.*)\$$/;
    }
    search(e) {
      const o = !e.endsWith(this.pattern);
      return {
        isMatch: o,
        score: o ? 0 : 1,
        indices: [0, e.length - 1]
      };
    }
  }, class extends H {
    constructor(e) {
      super(e);
    }
    static get type() {
      return "suffix-exact";
    }
    static get multiRegex() {
      return /^"(.*)"\$$/;
    }
    static get singleRegex() {
      return /^(.*)\$$/;
    }
    search(e) {
      const o = e.endsWith(this.pattern);
      return {
        isMatch: o,
        score: o ? 0 : 1,
        indices: [e.length - this.pattern.length, e.length - 1]
      };
    }
  }, class extends H {
    constructor(e) {
      super(e);
    }
    static get type() {
      return "inverse-exact";
    }
    static get multiRegex() {
      return /^!"(.*)"$/;
    }
    static get singleRegex() {
      return /^!(.*)$/;
    }
    search(e) {
      const o = -1 === e.indexOf(this.pattern);
      return {
        isMatch: o,
        score: o ? 0 : 1,
        indices: [0, e.length - 1]
      };
    }
  }, D],
  Q = J.length,
  V = / +(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/,
  Z = new Set([D.type, K.type]),
  G = [];
function X(e, o) {
  for (let r = 0, n = G.length; r < n; r += 1) {
    let n = G[r];
    if (n.condition(e, o)) return new n(e, o);
  }
  return new W(e, o);
}
const Y = "$and",
  ee = "$path",
  oe = e => !(!e[Y] && !e.$or),
  re = e => ({
    [Y]: Object.keys(e).map(o => ({
      [o]: e[o]
    }))
  });
function ne(e, o, {
  auto: r = !0
} = {}) {
  const n = e => {
    let a = Object.keys(e);
    const i = (e => !!e[ee])(e);
    if (!i && a.length > 1 && !oe(e)) return n(re(e));
    if ((e => !v(e) && x(e) && !oe(e))(e)) {
      const n = i ? e[ee] : a[0],
        t = i ? e.$val : e[n];
      if (!w(t)) throw new Error((e => `Invalid value for key ${e}`)(n));
      const c = {
        keyId: A(n),
        pattern: t
      };
      return r && (c.searcher = X(t, o)), c;
    }
    let t = {
      children: [],
      operator: a[0]
    };
    return a.forEach(o => {
      const r = e[o];
      v(r) && r.forEach(e => {
        t.children.push(n(e));
      });
    }), t;
  };
  return oe(e) || (e = re(e)), n(e);
}
function ae(e, o) {
  const r = e.matches;
  o.matches = [], S(r) && r.forEach(e => {
    if (!S(e.indices) || !e.indices.length) return;
    const {
      indices: r,
      value: n
    } = e;
    let a = {
      indices: r,
      value: n
    };
    e.key && (a.key = e.key.src), e.idx > -1 && (a.refIndex = e.idx), o.matches.push(a);
  });
}
function ie(e, o) {
  o.score = e.score;
}
class te {
  constructor(e, o = {}, r) {
    this.options = {
      ...T,
      ...o
    }, this.options.useExtendedSearch, this._keyStore = new O(this.options.keys), this.setCollection(e, r);
  }
  setCollection(e, o) {
    if (this._docs = e, o && !(o instanceof N)) throw new Error("Incorrect 'index' type");
    this._myIndex = o || $(this.options.keys, this._docs, {
      getFn: this.options.getFn,
      fieldNormWeight: this.options.fieldNormWeight
    });
  }
  add(e) {
    S(e) && (this._docs.push(e), this._myIndex.add(e));
  }
  remove(e = () => !1) {
    const o = [];
    for (let r = 0, n = this._docs.length; r < n; r += 1) {
      const a = this._docs[r];
      e(a, r) && (this.removeAt(r), r -= 1, n -= 1, o.push(a));
    }
    return o;
  }
  removeAt(e) {
    this._docs.splice(e, 1), this._myIndex.removeAt(e);
  }
  getIndex() {
    return this._myIndex;
  }
  search(e, {
    limit: o = -1
  } = {}) {
    const {
      includeMatches: r,
      includeScore: n,
      shouldSort: a,
      sortFn: i,
      ignoreFieldNorm: t
    } = this.options;
    let c = w(e) ? w(this._docs[0]) ? this._searchStringList(e) : this._searchObjectList(e) : this._searchLogical(e);
    return function (e, {
      ignoreFieldNorm: o = T.ignoreFieldNorm
    }) {
      e.forEach(e => {
        let r = 1;
        e.matches.forEach(({
          key: e,
          norm: n,
          score: a
        }) => {
          const i = e ? e.weight : null;
          r *= Math.pow(0 === a && i ? Number.EPSILON : a, (i || 1) * (o ? 1 : n));
        }), e.score = r;
      });
    }(c, {
      ignoreFieldNorm: t
    }), a && c.sort(i), k(o) && o > -1 && (c = c.slice(0, o)), function (e, o, {
      includeMatches: r = T.includeMatches,
      includeScore: n = T.includeScore
    } = {}) {
      const a = [];
      return r && a.push(ae), n && a.push(ie), e.map(e => {
        const {
            idx: r
          } = e,
          n = {
            item: o[r],
            refIndex: r
          };
        return a.length && a.forEach(o => {
          o(e, n);
        }), n;
      });
    }(c, this._docs, {
      includeMatches: r,
      includeScore: n
    });
  }
  _searchStringList(e) {
    const o = X(e, this.options),
      {
        records: r
      } = this._myIndex,
      n = [];
    return r.forEach(({
      v: e,
      i: r,
      n: a
    }) => {
      if (!S(e)) return;
      const {
        isMatch: i,
        score: t,
        indices: c
      } = o.searchIn(e);
      i && n.push({
        item: e,
        idx: r,
        matches: [{
          score: t,
          value: e,
          norm: a,
          indices: c
        }]
      });
    }), n;
  }
  _searchLogical(e) {
    const o = ne(e, this.options),
      r = (e, o, n) => {
        if (!e.children) {
          const {
              keyId: r,
              searcher: a
            } = e,
            i = this._findMatches({
              key: this._keyStore.get(r),
              value: this._myIndex.getValueForItemAtKeyId(o, r),
              searcher: a
            });
          return i && i.length ? [{
            idx: n,
            item: o,
            matches: i
          }] : [];
        }
        const a = [];
        for (let i = 0, t = e.children.length; i < t; i += 1) {
          const t = e.children[i],
            c = r(t, o, n);
          if (c.length) a.push(...c);else if (e.operator === Y) return [];
        }
        return a;
      },
      n = this._myIndex.records,
      a = {},
      i = [];
    return n.forEach(({
      $: e,
      i: n
    }) => {
      if (S(e)) {
        let t = r(o, e, n);
        t.length && (a[n] || (a[n] = {
          idx: n,
          item: e,
          matches: []
        }, i.push(a[n])), t.forEach(({
          matches: e
        }) => {
          a[n].matches.push(...e);
        }));
      }
    }), i;
  }
  _searchObjectList(e) {
    const o = X(e, this.options),
      {
        keys: r,
        records: n
      } = this._myIndex,
      a = [];
    return n.forEach(({
      $: e,
      i: n
    }) => {
      if (!S(e)) return;
      let i = [];
      r.forEach((r, n) => {
        i.push(...this._findMatches({
          key: r,
          value: e[n],
          searcher: o
        }));
      }), i.length && a.push({
        idx: n,
        item: e,
        matches: i
      });
    }), a;
  }
  _findMatches({
    key: e,
    value: o,
    searcher: r
  }) {
    if (!S(o)) return [];
    let n = [];
    if (v(o)) o.forEach(({
      v: o,
      i: a,
      n: i
    }) => {
      if (!S(o)) return;
      const {
        isMatch: t,
        score: c,
        indices: d
      } = r.searchIn(o);
      t && n.push({
        score: c,
        key: e,
        value: o,
        idx: a,
        norm: i,
        indices: d
      });
    });else {
      const {
          v: a,
          n: i
        } = o,
        {
          isMatch: t,
          score: c,
          indices: d
        } = r.searchIn(a);
      t && n.push({
        score: c,
        key: e,
        value: a,
        norm: i,
        indices: d
      });
    }
    return n;
  }
}
function ce(e) {
  return ce = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, ce(e);
}
function de(e) {
  var o = function (e, o) {
    if ("object" != ce(e) || !e) return e;
    var r = e[Symbol.toPrimitive];
    if (void 0 !== r) {
      var n = r.call(e, "string");
      if ("object" != ce(n)) return n;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return String(e);
  }(e);
  return "symbol" == ce(o) ? o : o + "";
}
function me(e, o, r) {
  return o = ge(o), function (e, o) {
    if (o && ("object" === ce(o) || "function" == typeof o)) return o;
    if (void 0 !== o) throw new TypeError("Derived constructors may only return object or undefined");
    return function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e);
  }(e, ue() ? Reflect.construct(o, r || [], ge(e).constructor) : o.apply(e, r));
}
function ue() {
  try {
    var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
  } catch (e) {}
  return (ue = function () {
    return !!e;
  })();
}
function ge(e) {
  return ge = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
    return e.__proto__ || Object.getPrototypeOf(e);
  }, ge(e);
}
function fe(e, o) {
  return fe = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, o) {
    return e.__proto__ = o, e;
  }, fe(e, o);
}
te.version = "7.0.0", te.createIndex = $, te.parseIndex = function (e, {
  getFn: o = T.getFn,
  fieldNormWeight: r = T.fieldNormWeight
} = {}) {
  const {
      keys: n,
      records: a
    } = e,
    i = new N({
      getFn: o,
      fieldNormWeight: r
    });
  return i.setKeys(n), i.setIndexRecords(a), i;
}, te.config = T, te.parseQuery = ne, function (...e) {
  G.push(...e);
}(class {
  constructor(e, {
    isCaseSensitive: o = T.isCaseSensitive,
    includeMatches: r = T.includeMatches,
    minMatchCharLength: n = T.minMatchCharLength,
    ignoreLocation: a = T.ignoreLocation,
    findAllMatches: i = T.findAllMatches,
    location: t = T.location,
    threshold: c = T.threshold,
    distance: d = T.distance
  } = {}) {
    this.query = null, this.options = {
      isCaseSensitive: o,
      includeMatches: r,
      minMatchCharLength: n,
      findAllMatches: i,
      ignoreLocation: a,
      location: t,
      threshold: c,
      distance: d
    }, this.pattern = o ? e : e.toLowerCase(), this.query = function (e, o = {}) {
      return e.split("|").map(e => {
        let r = e.trim().split(V).filter(e => e && !!e.trim()),
          n = [];
        for (let e = 0, a = r.length; e < a; e += 1) {
          const a = r[e];
          let i = !1,
            t = -1;
          for (; !i && ++t < Q;) {
            const e = J[t];
            let r = e.isMultiMatch(a);
            r && (n.push(new e(r, o)), i = !0);
          }
          if (!i) for (t = -1; ++t < Q;) {
            const e = J[t];
            let r = e.isSingleMatch(a);
            if (r) {
              n.push(new e(r, o));
              break;
            }
          }
        }
        return n;
      });
    }(this.pattern, this.options);
  }
  static condition(e, o) {
    return o.useExtendedSearch;
  }
  searchIn(e) {
    const o = this.query;
    if (!o) return {
      isMatch: !1,
      score: 1
    };
    const {
      includeMatches: r,
      isCaseSensitive: n
    } = this.options;
    e = n ? e : e.toLowerCase();
    let a = 0,
      i = [],
      t = 0;
    for (let n = 0, c = o.length; n < c; n += 1) {
      const c = o[n];
      i.length = 0, a = 0;
      for (let o = 0, n = c.length; o < n; o += 1) {
        const n = c[o],
          {
            isMatch: d,
            indices: m,
            score: u
          } = n.search(e);
        if (!d) {
          t = 0, a = 0, i.length = 0;
          break;
        }
        if (a += 1, t += u, r) {
          const e = n.constructor.type;
          Z.has(e) ? i = [...i, ...m] : i.push(m);
        }
      }
      if (a) {
        let e = {
          isMatch: !0,
          score: t / a
        };
        return r && (e.indices = i), e;
      }
    }
    return {
      isMatch: !1,
      score: 1
    };
  }
});
var _e = t.default.import("core/module"),
  se = function (e) {
    function o(e, r) {
      var n;
      return function (e, o) {
        if (!(e instanceof o)) throw new TypeError("Cannot call a class as a function");
      }(this, o), (n = me(this, o, [e, r])).emojiList = r.emojiList, n.fuse = new te(r.emojiList, r.fuse), n.quill = e, n.onClose = r.onClose, n.onOpen = r.onOpen, n.container = document.createElement("ul"), n.container.classList.add("emoji_completions"), n.quill.container.appendChild(n.container), n.container.style.position = "absolute", n.container.style.display = "none", n.onSelectionChange = n.maybeUnfocus.bind(n), n.onTextChange = n.update.bind(n), n.open = !1, n.atIndex = null, n.focusedButton = null, n.isWhiteSpace = function (e) {
        var o = !1;
        return /\s/.test(e) && (o = !0), o;
      }, e.keyboard.addBinding({
        key: 186,
        shiftKey: !0
      }, n.triggerPicker.bind(n)), e.keyboard.addBinding({
        key: 59,
        shiftKey: !0
      }, n.triggerPicker.bind(n)), e.keyboard.addBinding({
        key: 39,
        collapsed: !0
      }, n.handleArrow.bind(n)), e.keyboard.addBinding({
        key: 40,
        collapsed: !0
      }, n.handleArrow.bind(n)), n;
    }
    return function (e, o) {
      if ("function" != typeof o && null !== o) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(o && o.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), o && fe(e, o);
    }(o, _e), r = o, n = [{
      key: "triggerPicker",
      value: function (e, o) {
        if (this.open) return !0;
        e.length > 0 && this.quill.deleteText(e.index, e.length, t.default.sources.USER), this.quill.insertText(e.index, ":", "emoji-shortname", t.default.sources.USER);
        var r = this.quill.getBounds(e.index);
        this.quill.setSelection(e.index + 1, t.default.sources.SILENT), this.atIndex = e.index, r.left + 250 > this.quill.container.offsetWidth ? this.container.style.left = r.left - 250 + "px" : this.container.style.left = r.left + "px", this.container.style.top = r.top + r.height + "px", this.open = !0, this.quill.on("text-change", this.onTextChange), this.quill.once("selection-change", this.onSelectionChange), this.onOpen && this.onOpen();
      }
    }, {
      key: "handleArrow",
      value: function () {
        if (!this.open) return !0;
        this.buttons[0].classList.remove("emoji-active"), this.buttons[0].focus(), this.buttons.length > 1 && this.buttons[1].focus();
      }
    }, {
      key: "update",
      value: function () {
        var e = this.quill.getSelection().index;
        if (this.atIndex >= e) return this.close(null);
        this.query = this.quill.getText(this.atIndex + 1, e - this.atIndex - 1);
        try {
          if (event && this.isWhiteSpace(this.query)) return void this.close(null);
        } catch (e) {
          console.warn(e);
        }
        this.query = this.query.trim();
        var o = this.fuse.search(this.query);
        o.sort(function (e, o) {
          return e.emoji_order - o.emoji_order;
        }), this.query.length < this.options.fuse.minMatchCharLength || 0 === o.length ? this.container.style.display = "none" : (o.length > 15 && (o = o.slice(0, 15)), this.renderCompletions(o));
      }
    }, {
      key: "maybeUnfocus",
      value: function () {
        this.container.querySelector("*:focus") || this.close(null);
      }
    }, {
      key: "renderCompletions",
      value: function (e) {
        var o = this;
        try {
          if (event) {
            if ("Enter" === event.key || 13 === event.keyCode) return this.close(e[0], 1), void (this.container.style.display = "none");
            if ("Tab" === event.key || 9 === event.keyCode) return this.quill.disable(), this.buttons[0].classList.remove("emoji-active"), void this.buttons[1].focus();
          }
        } catch (e) {
          console.warn(e);
        }
        for (; this.container.firstChild;) this.container.removeChild(this.container.firstChild);
        var r = Array(e.length);
        if (this.buttons = r, e.forEach(function (e, n) {
          var a = ye("li", {}, ye("button", {
            type: "button"
          }, ye("span", {
            className: "button-emoji ap",
            innerHTML: e.unicode
          }), ye("span", {
            className: "unmatched"
          }, e.shortname)));
          o.container.appendChild(a), r[n] = a.firstChild, r[n].addEventListener("keydown", function (e, n) {
            return function (a) {
              if ("ArrowRight" === a.key || 39 === a.keyCode) a.preventDefault(), r[Math.min(r.length - 1, e + 1)].focus();else if ("Tab" === a.key || 9 === a.keyCode) {
                if (a.preventDefault(), e + 1 === r.length) return void r[0].focus();
                r[Math.min(r.length - 1, e + 1)].focus();
              } else "ArrowLeft" === a.key || 37 === a.keyCode ? (a.preventDefault(), r[Math.max(0, e - 1)].focus()) : "ArrowDown" === a.key || 40 === a.keyCode ? (a.preventDefault(), r[Math.min(r.length - 1, e + 1)].focus()) : "ArrowUp" === a.key || 38 === a.keyCode ? (a.preventDefault(), r[Math.max(0, e - 1)].focus()) : "Enter" !== a.key && 13 !== a.keyCode && " " !== a.key && 32 !== a.keyCode && "Tab" !== a.key && 9 !== a.keyCode || (a.preventDefault(), o.quill.enable(), o.close(n));
            };
          }(n, e)), r[n].addEventListener("mousedown", function () {
            return o.close(e);
          }), r[n].addEventListener("focus", function () {
            return o.focusedButton = n;
          }), r[n].addEventListener("unfocus", function () {
            return o.focusedButton = null;
          });
        }), this.container.style.display = "block", this.quill.container.classList.contains("top-emoji")) {
          var n,
            a = this.container.querySelectorAll("li");
          for (n = 0; n < a.length; n++) a[n].style.display = "block";
          var i = window.innerHeight;
          this.quill.container.getBoundingClientRect().top > i / 2 && this.container.offsetHeight > 0 && (this.container.style.top = "-" + this.container.offsetHeight + "px");
        }
        r[0].classList.add("emoji-active");
      }
    }, {
      key: "close",
      value: function (e) {
        var o = this,
          r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
        for (this.quill.enable(), this.container.style.display = "none"; this.container.firstChild;) this.container.removeChild(this.container.firstChild);
        this.quill.off("selection-change", this.onSelectionChange), this.quill.off("text-change", this.onTextChange), e && (this.quill.deleteText(this.atIndex, this.query.length + 1 + r, t.default.sources.USER), this.quill.insertText(this.atIndex, e.unicode, t.default.sources.USER), setTimeout(function () {
          return o.quill.setSelection(o.atIndex + 1);
        }, 0)), this.quill.focus(), this.open = !1, this.onClose && this.onClose(e);
      }
    }], n && function (e, o) {
      for (var r = 0; r < o.length; r++) {
        var n = o[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, de(n.key), n);
      }
    }(r.prototype, n), Object.defineProperty(r, "prototype", {
      writable: !1
    }), r;
    var r, n;
  }();
function ye(e, o) {
  var r = document.createElement(e);
  Object.keys(o).forEach(function (e) {
    return r[e] = o[e];
  });
  for (var n = arguments.length, a = new Array(n > 2 ? n - 2 : 0), i = 2; i < n; i++) a[i - 2] = arguments[i];
  return a.forEach(function (e) {
    "string" == typeof e && (e = document.createTextNode(e)), r.appendChild(e);
  }), r;
}
se.DEFAULTS = {
  emojiList: n,
  fuse: {
    shouldSort: !0,
    threshold: .1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["shortname"]
  }
};
const le = se;
function je(e) {
  return je = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, je(e);
}
function he(e) {
  var o = function (e, o) {
    if ("object" != je(e) || !e) return e;
    var r = e[Symbol.toPrimitive];
    if (void 0 !== r) {
      var n = r.call(e, "string");
      if ("object" != je(n)) return n;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return String(e);
  }(e);
  return "symbol" == je(o) ? o : o + "";
}
function pe(e, o, r) {
  return o = ve(o), function (e, o) {
    if (o && ("object" === je(o) || "function" == typeof o)) return o;
    if (void 0 !== o) throw new TypeError("Derived constructors may only return object or undefined");
    return function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e);
  }(e, be() ? Reflect.construct(o, r || [], ve(e).constructor) : o.apply(e, r));
}
function be() {
  try {
    var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
  } catch (e) {}
  return (be = function () {
    return !!e;
  })();
}
function ve(e) {
  return ve = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
    return e.__proto__ || Object.getPrototypeOf(e);
  }, ve(e);
}
function we(e, o) {
  return we = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, o) {
    return e.__proto__ = o, e;
  }, we(e, o);
}
var ke = t.default.import("core/module"),
  xe = function (e) {
    function o(e, r) {
      var n;
      !function (e, o) {
        if (!(e instanceof o)) throw new TypeError("Cannot call a class as a function");
      }(this, o), (n = pe(this, o, [e, r])).quill = e, n.toolbar = e.getModule("toolbar"), void 0 !== n.toolbar && n.toolbar.addHandler("emoji", n.checkPaletteExists);
      var a = document.getElementsByClassName("ql-emoji");
      return a && [].slice.call(a).forEach(function (e) {
        e.innerHTML = r.buttonIcon;
      }), n;
    }
    return function (e, o) {
      if ("function" != typeof o && null !== o) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(o && o.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), o && we(e, o);
    }(o, ke), r = o, (n = [{
      key: "checkPaletteExists",
      value: function () {
        var e, o;
        e = this.quill, (o = document.getElementById("emoji-palette")) ? o.remove() : function (e) {
          var o = 250,
            r = document.createElement("div"),
            n = e.getSelection(),
            a = e.getBounds(n.index),
            i = e.container.getBoundingClientRect(),
            t = (a.left + a.right) / 2,
            c = (a.top + a.bottom) / 2,
            d = i.left + t + o <= document.documentElement.clientWidth ? t : i.left - o,
            m = i.top + c + o + 10 <= document.documentElement.clientHeight ? c + 10 : i.top + c - o - 10 >= 0 ? c - o - 10 : document.documentElement.clientHeight - o - i.top;
          e.container.appendChild(r), r.id = "emoji-palette", r.style.left = "".concat(d, "px"), r.style.top = "".concat(m, "px");
          var u = document.createElement("div");
          u.id = "tab-toolbar", r.appendChild(u);
          var g = document.createElement("div");
          g.id = "tab-panel", r.appendChild(g);
          var f = document.createElement("ul");
          if (u.appendChild(f), null === document.getElementById("emoji-close-div")) {
            var _ = document.createElement("div");
            _.id = "emoji-close-div", _.addEventListener("click", Se, !1), document.getElementsByTagName("body")[0].appendChild(_);
          } else document.getElementById("emoji-close-div").style.display = "block";
          [{
            type: "p",
            name: "people",
            content: '<div class="i-people"></div>'
          }, {
            type: "n",
            name: "nature",
            content: '<div class="i-nature"></div>'
          }, {
            type: "d",
            name: "food",
            content: '<div class="i-food"></div>'
          }, {
            type: "s",
            name: "symbols",
            content: '<div class="i-symbols"></div>'
          }, {
            type: "a",
            name: "activity",
            content: '<div class="i-activity"></div>'
          }, {
            type: "t",
            name: "travel",
            content: '<div class="i-travel"></div>'
          }, {
            type: "o",
            name: "objects",
            content: '<div class="i-objects"></div>'
          }, {
            type: "f",
            name: "flags",
            content: '<div class="i-flags"></div>'
          }].map(function (o) {
            var r = document.createElement("li");
            r.classList.add("emoji-tab"), r.classList.add("filter-" + o.name), r.innerHTML = o.content, r.dataset.filter = o.type, f.appendChild(r);
            var n = document.querySelector(".filter-" + o.name);
            n.addEventListener("click", function () {
              var o = document.querySelector(".active");
              o && o.classList.remove("active"), n.classList.toggle("active"), function (e, o, r) {
                for (; o.firstChild;) o.removeChild(o.firstChild);
                Ee(e.dataset.filter, o, r);
              }(n, g, e);
            });
          }), function (e, o) {
            Ee("p", e, o), document.querySelector(".filter-people").classList.add("active");
          }(g, e);
        }(e), this.quill.on("text-change", function (e, o, r) {
          "user" === r && Se();
        });
      }
    }]) && function (e, o) {
      for (var r = 0; r < o.length; r++) {
        var n = o[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, he(n.key), n);
      }
    }(r.prototype, n), Object.defineProperty(r, "prototype", {
      writable: !1
    }), r;
    var r, n;
  }();
function Se() {
  var e = document.getElementById("emoji-palette");
  document.getElementById("emoji-close-div").style.display = "none", e && e.remove();
}
function Ee(e, o, r) {
  var a = new te(n, {
    shouldSort: !0,
    matchAllTokens: !0,
    threshold: .3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ["category"]
  }).search(e);
  a.sort(function (e, o) {
    return e.emoji_order - o.emoji_order;
  }), r.focus(), a.map(function (e) {
    var n = document.createElement("span"),
      a = document.createTextNode(e.shortname);
    n.appendChild(a), n.classList.add("bem"), n.classList.add("bem-" + e.name), n.classList.add("ap");
    var i = "" + e.unicode;
    n.innerHTML = i + " ", o.appendChild(n);
    var c = document.querySelector(".bem-" + e.name);
    c && c.addEventListener("click", function () {
      var o = r.getSelection(!0);
      !function (e, o) {
        var r = document.createElement(e);
        Object.keys(o).forEach(function (e) {
          return r[e] = o[e];
        });
        for (var n = arguments.length, a = new Array(n > 2 ? n - 2 : 0), i = 2; i < n; i++) a[i - 2] = arguments[i];
        a.forEach(function (e) {
          "string" == typeof e && (e = document.createTextNode(e)), r.appendChild(e);
        });
      }("span", {
        className: "ico",
        innerHTML: e.unicode + " "
      }), r.insertText(o.index, e.unicode, t.default.sources.USER), setTimeout(function () {
        return r.setSelection(o.index + 1);
      }, 0), Se();
    });
  });
}
xe.DEFAULTS = {
  buttonIcon: '<svg viewbox="0 0 18 18"><circle class="ql-fill" cx="7" cy="7" r="1"></circle><circle class="ql-fill" cx="11" cy="7" r="1"></circle><path class="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle class="ql-stroke" cx="9" cy="9" r="6"></circle></svg>'
};
const Le = xe;
function Me(e) {
  return Me = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, Me(e);
}
function Ce(e) {
  var o = function (e, o) {
    if ("object" != Me(e) || !e) return e;
    var r = e[Symbol.toPrimitive];
    if (void 0 !== r) {
      var n = r.call(e, "string");
      if ("object" != Me(n)) return n;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return String(e);
  }(e);
  return "symbol" == Me(o) ? o : o + "";
}
function qe(e, o, r) {
  return o = Ie(o), function (e, o) {
    if (o && ("object" === Me(o) || "function" == typeof o)) return o;
    if (void 0 !== o) throw new TypeError("Derived constructors may only return object or undefined");
    return function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e);
  }(e, Oe() ? Reflect.construct(o, r || [], Ie(e).constructor) : o.apply(e, r));
}
function Oe() {
  try {
    var e = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
  } catch (e) {}
  return (Oe = function () {
    return !!e;
  })();
}
function Ie(e) {
  return Ie = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
    return e.__proto__ || Object.getPrototypeOf(e);
  }, Ie(e);
}
function Pe(e, o) {
  return Pe = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, o) {
    return e.__proto__ = o, e;
  }, Pe(e, o);
}
var Ae = t.default.import("core/module"),
  Te = function (e) {
    function o(e, r) {
      var n;
      return function (e, o) {
        if (!(e instanceof o)) throw new TypeError("Cannot call a class as a function");
      }(this, o), (n = qe(this, o, [e, r])).quill = e, n.container = document.createElement("button"), n.container.classList.add("textarea-emoji-control"), n.container.classList.add("ql-list"), n.container.innerHTML = r.buttonIcon, n.quill.container.parentElement.querySelector(".ql-toolbar > .ql-formats:last-child").appendChild(n.container), n.container.addEventListener("click", n.checkEmojiBoxExist.bind(n), !1), n;
    }
    return function (e, o) {
      if ("function" != typeof o && null !== o) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(o && o.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), o && Pe(e, o);
    }(o, Ae), r = o, (n = [{
      key: "checkEmojiBoxExist",
      value: function () {
        var e = document.getElementById("textarea-emoji");
        if (e) e.remove();else {
          var o = document.createElement("div");
          o.id = "textarea-emoji", this.quill.container.parentElement.querySelector(".ql-toolbar").appendChild(o);
          var r = document.createElement("div");
          r.id = "tab-toolbar", o.appendChild(r);
          var n = document.createElement("ul");
          r.appendChild(n);
          var a = document.createElement("div");
          a.id = "tab-panel", o.appendChild(a);
          var i = this.quill;
          [{
            type: "p",
            name: "people",
            content: '<div class="i-people"></div>'
          }, {
            type: "n",
            name: "nature",
            content: '<div class="i-nature"></div>'
          }, {
            type: "d",
            name: "food",
            content: '<div class="i-food"></div>'
          }, {
            type: "s",
            name: "symbols",
            content: '<div class="i-symbols"></div>'
          }, {
            type: "a",
            name: "activity",
            content: '<div class="i-activity"></div>'
          }, {
            type: "t",
            name: "travel",
            content: '<div class="i-travel"></div>'
          }, {
            type: "o",
            name: "objects",
            content: '<div class="i-objects"></div>'
          }, {
            type: "f",
            name: "flags",
            content: '<div class="i-flags"></div>'
          }].map(function (e) {
            var o = document.createElement("li");
            o.classList.add("emoji-tab"), o.classList.add("filter-" + e.name);
            var r = e.content;
            o.innerHTML = r, o.dataset.filter = e.type, n.appendChild(o);
            var t = document.querySelector(".filter-" + e.name);
            t.addEventListener("click", function () {
              var e = document.getElementById("textarea-emoji"),
                o = e && e.querySelector(".active");
              for (o && o.classList.remove("active"), t.classList.toggle("active"); a.firstChild;) a.removeChild(a.firstChild);
              Re(t.dataset.filter, a, i);
            });
          });
          var t = window.innerHeight;
          this.quill.container.getBoundingClientRect().top > t / 2 && (o.style.top = "-250px"), function (e, o) {
            Re("p", e, o), document.querySelector(".filter-people").classList.add("active");
          }(a, this.quill);
        }
      }
    }]) && function (e, o) {
      for (var r = 0; r < o.length; r++) {
        var n = o[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, Ce(n.key), n);
      }
    }(r.prototype, n), Object.defineProperty(r, "prototype", {
      writable: !1
    }), r;
    var r, n;
  }();
function Re(e, o, r) {
  var a = new te(n, {
    shouldSort: !0,
    matchAllTokens: !0,
    threshold: .3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ["category"]
  }).search(e);
  a.sort(function (e, o) {
    return e.emoji_order - o.emoji_order;
  }), r.focus(), a.map(function (e) {
    var n = e.item,
      a = document.createElement("span"),
      i = document.createTextNode(n.shortname);
    a.appendChild(i), a.classList.add("bem"), a.classList.add("bem-" + n.name), a.classList.add("ap"), a.innerText = n.unicode, o.appendChild(a);
    var c = document.querySelector(".bem-" + n.name);
    c && c.addEventListener("click", function () {
      var e = r.getSelection(!0);
      r.insertText(e.index, n.unicode, t.default.sources.USER), e.index += 1, setTimeout(function () {
        return r.setSelection(e.index + 1);
      }, 0);
    });
  });
}
Te.DEFAULTS = {
  buttonIcon: '<svg viewbox="0 0 18 18"><circle class="ql-fill" cx="7" cy="7" r="1"></circle><circle class="ql-fill" cx="11" cy="7" r="1"></circle><path class="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle class="ql-stroke" cx="9" cy="9" r="6"></circle></svg>'
};
const Ne = Te;
var $e = r.If,
  ze = r.OC,
  Be = r.LK,
  Fe = r.y$;
export { $e as EmojiBlot, ze as ShortNameEmoji, Be as TextAreaEmoji, Fe as ToolbarEmoji };
