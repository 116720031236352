import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment, UrlTree } from '@angular/router';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import localforage from 'localforage';
import { Observable, merge, map, take, concatMap, defer, of } from 'rxjs';


export const isLoggedInGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {

  const accountsService = inject(AccountsService);
  const utilService = inject(UtilityService);
  let bannedRoutes = ["home", "homeAlt", "accountOverviewPage", "connectionHub", "manageVideos","videoDataEditorPage","uploadPage","friendsZeroPage","billingZeroPage","guide","verifyEmailZeroPage"].map(route => ENV.nav.urls[route]);


  let currentPath = segments.length > 0 ? '/' + segments.map(segment => segment.path).join('/') : '/';

  let queryProps = new URLSearchParams(utilService.getWindow().location.search);
  let loginToken = queryProps.get("loginToken")
  let originalPath = utilService.getWindow().location.pathname
  let loginTokenIsPresent = ![null,undefined].includes(loginToken)

  // TODO  I hate coding because angular cant find the segmenet I have to add this
  bannedRoutes.push("/verify-email")
  return merge(
    accountsService.onUserLoggedInEvent.pipe(map(() => true)),
    accountsService.onUserLoggedOutEvent.pipe(map(() => false)),
    accountsService.onNoUserEvent.pipe(map(() => false))
  ).pipe(
    take(1),
    concatMap((isLoggedIn)=>{
      return defer( ()=> {
        if(loginTokenIsPresent){
          return accountsService.loginViaCustomToken(loginToken)
        }
        else{
          return of(null)
        }
      })
      .pipe(
        map(()=>isLoggedIn)
      )

    }),
    map(isLoggedIn => {

      if (bannedRoutes.includes(currentPath) && !isLoggedIn && !loginTokenIsPresent) {
        return utilService.router.createUrlTree([ENV.nav.urls.landing]);
      }
      return isLoggedIn;
    }),
    concatMap(isLoggedIn=>{
      return defer(async ()=>{
        if(loginTokenIsPresent){

          // return utilService.router.createUrlTree([currentPath]);
          // Navigate to the new URL Angular is being stupid
          await localforage.setItem(ENV.localForage.LoginFromFirebaseCustomToken,true)
          utilService.getWindow().location.href = utilService.getWindow().location.origin+originalPath
        }
        return isLoggedIn;
      })
    })
  );
};
