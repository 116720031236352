import { Route } from "@angular/router";

export let addPageTitlesToRoute = (routes:Route["children"])=>{

  routes.forEach((route)=>{

    if(!route.title || !route.data.title){
      let path = route.path === "" ? "default": route.path
      route.title = "nav.pageInfo."+path
    }
    if(Array.isArray(route.children)){
      addPageTitlesToRoute(route.children)
    }
  })
  return routes
}

export let removeLeadingSlash = (routes: Route['children']) => {
  routes.forEach(route => {
    // Check and remove the leading slash from the route's path
    if (route.path?.startsWith('/')) {
      let routeInfo = route.path.split("/")
      route.path = routeInfo.at(-1);
    }
    // Recursively handle child routes
    if (Array.isArray(route.children)) {
      removeLeadingSlash(route.children);
    }
  });
  return routes;
};

