// https://github.com/msgpack/msgpack/blob/master/spec.md#timestamp-extension-type
import { DecodeError } from "./DecodeError.mjs";
import { getInt64, setInt64 } from "./utils/int.mjs";
export var EXT_TIMESTAMP = -1;
var TIMESTAMP32_MAX_SEC = 0x100000000 - 1; // 32-bit unsigned int
var TIMESTAMP64_MAX_SEC = 0x400000000 - 1; // 34-bit unsigned int
export function encodeTimeSpecToTimestamp(_a) {
  var sec = _a.sec,
    nsec = _a.nsec;
  if (sec >= 0 && nsec >= 0 && sec <= TIMESTAMP64_MAX_SEC) {
    // Here sec >= 0 && nsec >= 0
    if (nsec === 0 && sec <= TIMESTAMP32_MAX_SEC) {
      // timestamp 32 = { sec32 (unsigned) }
      var rv = new Uint8Array(4);
      var view = new DataView(rv.buffer);
      view.setUint32(0, sec);
      return rv;
    } else {
      // timestamp 64 = { nsec30 (unsigned), sec34 (unsigned) }
      var secHigh = sec / 0x100000000;
      var secLow = sec & 0xffffffff;
      var rv = new Uint8Array(8);
      var view = new DataView(rv.buffer);
      // nsec30 | secHigh2
      view.setUint32(0, nsec << 2 | secHigh & 0x3);
      // secLow32
      view.setUint32(4, secLow);
      return rv;
    }
  } else {
    // timestamp 96 = { nsec32 (unsigned), sec64 (signed) }
    var rv = new Uint8Array(12);
    var view = new DataView(rv.buffer);
    view.setUint32(0, nsec);
    setInt64(view, 4, sec);
    return rv;
  }
}
export function encodeDateToTimeSpec(date) {
  var msec = date.getTime();
  var sec = Math.floor(msec / 1e3);
  var nsec = (msec - sec * 1e3) * 1e6;
  // Normalizes { sec, nsec } to ensure nsec is unsigned.
  var nsecInSec = Math.floor(nsec / 1e9);
  return {
    sec: sec + nsecInSec,
    nsec: nsec - nsecInSec * 1e9
  };
}
export function encodeTimestampExtension(object) {
  if (object instanceof Date) {
    var timeSpec = encodeDateToTimeSpec(object);
    return encodeTimeSpecToTimestamp(timeSpec);
  } else {
    return null;
  }
}
export function decodeTimestampToTimeSpec(data) {
  var view = new DataView(data.buffer, data.byteOffset, data.byteLength);
  // data may be 32, 64, or 96 bits
  switch (data.byteLength) {
    case 4:
      {
        // timestamp 32 = { sec32 }
        var sec = view.getUint32(0);
        var nsec = 0;
        return {
          sec: sec,
          nsec: nsec
        };
      }
    case 8:
      {
        // timestamp 64 = { nsec30, sec34 }
        var nsec30AndSecHigh2 = view.getUint32(0);
        var secLow32 = view.getUint32(4);
        var sec = (nsec30AndSecHigh2 & 0x3) * 0x100000000 + secLow32;
        var nsec = nsec30AndSecHigh2 >>> 2;
        return {
          sec: sec,
          nsec: nsec
        };
      }
    case 12:
      {
        // timestamp 96 = { nsec32 (unsigned), sec64 (signed) }
        var sec = getInt64(view, 4);
        var nsec = view.getUint32(0);
        return {
          sec: sec,
          nsec: nsec
        };
      }
    default:
      throw new DecodeError("Unrecognized data size for timestamp (expected 4, 8, or 12): ".concat(data.length));
  }
}
export function decodeTimestampExtension(data) {
  var timeSpec = decodeTimestampToTimeSpec(data);
  return new Date(timeSpec.sec * 1e3 + timeSpec.nsec / 1e6);
}
export var timestampExtension = {
  type: EXT_TIMESTAMP,
  encode: encodeTimestampExtension,
  decode: decodeTimestampExtension
};
