<a class="skip-link" href="#mainContent">Skip to main content</a>
<router-outlet ></router-outlet>
<wml-notify-one id="NotifyBanner" [class]="classPrefix('MainNotify0')"></wml-notify-one>
<div
*ngIf="baseService.toggleOverlayLoadingSubj$ | async"
 [class]="classPrefix('Pod1')">
  <overlay-loading [class]="classPrefix('MainLoading0')" ></overlay-loading>
</div>


<wml-mobile-nav-zero
[class]="classPrefix('MainMobileNav0')"
[props]="navService.mobileNavZero"></wml-mobile-nav-zero>
<wml-mobile-nav-zero
[class]="classPrefix('MainMobileNav1')"
[props]="navService.mobileNavOne"></wml-mobile-nav-zero>
<wml-panel-zero [props]="jobsService.jobPanel"></wml-panel-zero>
<div *ngIf="baseService.popupProps.togglePopupSubj | async" [class]="classPrefix('Pod0')">
  <wml-popup-zero [props]="baseService.popupProps"></wml-popup-zero>
</div>
