import { inject } from '@angular/core';
import { CanMatchFn, UrlTree } from '@angular/router';
import { BaseService } from '@core/base/base.service';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { Observable, map, merge } from 'rxjs';

export const hasPlatformAndStorageProviderGuard: CanMatchFn = (route, segments): Observable<boolean | UrlTree> => {
  const accountsService = inject(AccountsService);
  const baseService = inject(BaseService);
  const utilService = inject(UtilityService);

  return merge(
    accountsService.onUserLoggedInEvent.pipe(map(() => true)),
    accountsService.onUserLoggedOutEvent.pipe(map(() => false)),
    accountsService.onNoUserEvent.pipe(map(() => false))
  ).pipe(
    map(isLoggedIn => {
      if(!(accountsService.hasVideoAndStorageProviderTokens())){
        baseService.createWMLNote("VideoManagerPage.WMLNotifyOne.connectToAPlatform",WMLNotifyOneBarType.Info)
        return utilService.router.createUrlTree([ENV.nav.urls.connectionHub]);
      }
      return isLoggedIn;
    })
  );
};
