// ExtensionCodec to handle MessagePack extensions
import { ExtData } from "./ExtData.mjs";
import { timestampExtension } from "./timestamp.mjs";
var ExtensionCodec = /** @class */function () {
  function ExtensionCodec() {
    // built-in extensions
    this.builtInEncoders = [];
    this.builtInDecoders = [];
    // custom extensions
    this.encoders = [];
    this.decoders = [];
    this.register(timestampExtension);
  }
  ExtensionCodec.prototype.register = function (_a) {
    var type = _a.type,
      encode = _a.encode,
      decode = _a.decode;
    if (type >= 0) {
      // custom extensions
      this.encoders[type] = encode;
      this.decoders[type] = decode;
    } else {
      // built-in extensions
      var index = 1 + type;
      this.builtInEncoders[index] = encode;
      this.builtInDecoders[index] = decode;
    }
  };
  ExtensionCodec.prototype.tryToEncode = function (object, context) {
    // built-in extensions
    for (var i = 0; i < this.builtInEncoders.length; i++) {
      var encodeExt = this.builtInEncoders[i];
      if (encodeExt != null) {
        var data = encodeExt(object, context);
        if (data != null) {
          var type = -1 - i;
          return new ExtData(type, data);
        }
      }
    }
    // custom extensions
    for (var i = 0; i < this.encoders.length; i++) {
      var encodeExt = this.encoders[i];
      if (encodeExt != null) {
        var data = encodeExt(object, context);
        if (data != null) {
          var type = i;
          return new ExtData(type, data);
        }
      }
    }
    if (object instanceof ExtData) {
      // to keep ExtData as is
      return object;
    }
    return null;
  };
  ExtensionCodec.prototype.decode = function (data, type, context) {
    var decodeExt = type < 0 ? this.builtInDecoders[-1 - type] : this.decoders[type];
    if (decodeExt) {
      return decodeExt(data, type, context);
    } else {
      // decode() does not fail, returns ExtData instead.
      return new ExtData(type, data);
    }
  };
  ExtensionCodec.defaultCodec = new ExtensionCodec();
  return ExtensionCodec;
}();
export { ExtensionCodec };
