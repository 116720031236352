import { inject } from '@angular/core';
import { CanMatchFn, UrlTree } from '@angular/router';
import { BaseService } from '@core/base/base.service';
import { ENV } from '@env/environment';
import { getVideoDataEditorData } from '@shared/components/video-tile-zero/functions';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { Observable, map } from 'rxjs';

export const videosToEditWereChosenGuard: CanMatchFn = (route, segments): Observable<boolean | UrlTree> => {
  const baseService = inject(BaseService);
  return getVideoDataEditorData()
  .pipe(
    map((data)=>{
      let canVisit = data.items.length >0
      if(!canVisit){
        baseService.createWMLNote("VideosToEditWereChosenGuard.WMLNotifyOne.needToSelectVideos",WMLNotifyOneBarType.Info)
        return baseService.utilService.router.createUrlTree([ENV.nav.urls.manageVideos]);
      }

      return true
    })
  )



};
